/* eslint-disable */
import React from "react";
import { Button } from "@mui/material";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  height: "180px",
  overflow: "auto",
  bgcolor: "#1F1F1F",
  borderRadius: "4px",
  border: "1px solid #313131",
  boxShadow: 50,
  outline: "none",
};

export default function ConfirmationModal({
  open,
  handleClose,
  handleConfirm,
}) {
  const close = (event, click) => {
    if (click != "backdropClick") {
      handleClose();
    }
  };

  return (
    <Modal
      open={open.status}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style2}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.2px solid #444444",
            padding: "5px 20px",
            backgroundColor: "#565b60",
          }}
        >
          <h4
            style={{
              fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
              marginTop: "0px",
              fontWeight: 600,
              fontSize: "1rem",
            }}
          >
            Alert
          </h4>
          <CloseIcon onClick={close} size={15} />
        </div>
        <p
          style={{
            textAlign: "center",
            margin: "30px 0",
            fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
          }}
        >
          Are you sure you want to {open.method}?
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClose}
            variant={"outlined"}
            style={{ fontFamily: "Montserrat, Arial, Helvetica, sans-serif" }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirm}
            variant={"contained"}
            style={{ fontFamily: "Montserrat, Arial, Helvetica, sans-serif" }}
          >
            Yes
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
