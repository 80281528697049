/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Select, { components } from 'react-select';
import { message } from 'antd';
import { Modal, Box, Button } from '@mui/material';
import { Form } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { TbMathFunction } from 'react-icons/tb';
import * as Yup from 'yup'; // Ensure Yup is installed
import getMathOperator from '../../../API/CentralizedCapsuleApis/getMathOperator.js';
import addNewSizeConfiguration from '../../../API/CentralizedCapsuleApis/addNewSizeConfiguration.js';
import getLockType from '../../../API/CentralizedCapsuleApis/getLockType.js';
import getWeightType from '../../../API/CentralizedCapsuleApis/getWeightType.js';
import getParameters from '../../../API/CentralizedCapsuleApis/getParameters.js';
import checkSizeConfiguration from '../../../API/CentralizedCapsuleApis/checkSizeConfiguration.js';

import { batchSelectBox, updatesStyle, customSelect } from './style';
import styles from './styles.module.css';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <TbMathFunction size={20} />
  </components.DropdownIndicator>
);

const AddModal = ({
  AddModalStatus,
  ModalHeader,
  CapsuleSize,
  MCTypeKey,
  setAddModalStatus,
}) => {
  const [size, setSize] = useState('');
  const [OperatorList, setOperatorList] = useState([]);
  const [LockTypeList, setLockType] = useState([]);
  const [WeightTypeList, setWeightType] = useState([]);
  const [LockTypeFilter, setLockTypeFilter] = useState(null);
  const [WeightTypeFilter, setWeightFilter] = useState(null);
  const [InitialValue, setInitialValue] = useState(null);
  const [IsError, setIsError] = useState(true);
  const [IsSizeExists, setIsSizeExists] = useState(false);
  const [sizeExistText, setSizeExistText] = useState('');

  const callGetMathOperator = useCallback(async () => {
    const res = await getMathOperator();
    setOperatorList(res);
  }, []);

  const callGetWeightType = useCallback(async () => {
    let res = await getWeightType(MCTypeKey);
    if (res && res.length > 0) {
      res = (res || []).map((item) => ({
        label: item?.WeightType,
        value: item?.WTKey,
      }));
    }
    setWeightType(res);
  }, [MCTypeKey]);
  const callGetLockType = useCallback(async () => {
    let res = await getLockType(MCTypeKey);
    if (res && res.length > 0) {
      res = (res || []).map((item) => ({
        label: item?.LockType,
        value: item?.LTKey,
      }));
    }
    setLockType(res);
  }, [MCTypeKey]);

  const callGetParameters = useCallback(async () => {
    const res = await getParameters(MCTypeKey);
    if (res && res.length > 0) {
      const data = res;
      if (data.length != 0) {
        const temp = {
          Size: null,
          BodyECNo: null,
          CapECNo: null,
          WeightTypeKey: null,
          LockTypeKey: null,
          JSONDATA: null,
        };
        const jsonData = [];
        data?.map((item) => {
          jsonData.push({
            parameter_name: item?.ParamName,
            parameter_id: item?.ParamKey,
            part_type: item?.PartType,
            target_value: null,
            spec_operator: null,
            spec_value: null,
            control_chart_operator: null,
            control_chart_value: null,
          });
        });
        temp.JSONDATA = jsonData;
        setInitialValue(temp);
      } else {
        // toast.error('Please Conact to Admin');
        message.error('Please Conact to Admin');
      }
      console.log(res?.data?.data, 'paramters');
    } else if (res?.data && res?.data?.status === 0) {
      // toast.error(res?.data?.data?.Message);
      message.error(res?.data?.data?.Message);
    } else {
      message.error('Something went wrong please try after sometime.');
      // toast.error('Something went wrong please try after sometime.');
    }
  }, [MCTypeKey]);
  const callAddNewSizeConfiuration = async (value) => {
    // type, MCTypeKey
    value = { ...value, typeKey: MCTypeKey };
    const res = await addNewSizeConfiguration(value);
    if (res?.data?.data?.Status === 'Error') {
      message.error(res?.data?.data?.Message);
    } else {
      handleCloseModal();
      message.success(res?.data?.data?.Message);
    }
  };
  // Handle Lock Type Selection
  const handleLockTypeChange = async (values, LTKey) => {
    const res = await checkSizeConfiguration({
      Size: size,
      MCTypeKey,
      WeightTypeKey: WeightTypeFilter?.value,
      LockTypeKey: LockTypeFilter?.value,
    });
    if (res?.data?.status == 1) {
      if (res?.data?.data?.Status == 'Error') {
        setIsError(true);
        message.error(res?.data?.data?.Message);
        setIsSizeExists(true);
        setSizeExistText(res?.data?.data?.Message);
      } else {
        setIsSizeExists(false);
        setSizeExistText('');
      }
    } else {
      setIsError(true);
      setIsSizeExists(false);
      setSizeExistText('');
      message.error(res?.data?.data?.Message || "Something went wrong");
    }
  };// Handle Weight Type Change
  const handleWeightTypeChange = (selectedOption, setFieldValue) => {
    const { WTKey } = selectedOption;
    setFieldValue('WeightTypeKey', WTKey);
    setFieldValue('LockTypeKey', ''); // Reset LockTypeKey
    setIsError(true); // Disable submit button until lock type is selected
  };

  // Handle Size Change
  const handleSizeChange = (e, setFieldValue) => {
    setFieldValue('Size', e.target.value);
    setSize(e.target.value);
    setWeightFilter(null);
    setLockTypeFilter(null);
    setFieldValue('WeightTypeKey', '');
    setFieldValue('LockTypeKey', '');
    setIsError(true);
  };
  useEffect(() => {
    if (LockTypeFilter?.value && WeightTypeFilter?.value) {
      handleLockTypeChange();
    }
  }, [LockTypeFilter?.value, WeightTypeFilter?.value]);
  const validate = (values) => {
    const errors = {};
    let hasErrors = false;
    // Validate Size
    if (!values.Size) {
      errors.Size = 'Size is required';
      hasErrors = true;
    }

    // Validate BodyECNo
    if (!values.BodyECNo) {
      errors.BodyECNo = 'Body EC Number is required';
      hasErrors = true;
    }

    // Validate CapECNo
    if (!values.CapECNo) {
      errors.CapECNo = 'Cap EC Number is required';
      hasErrors = true;
    }

    // Validate WeightTypeKey
    if (!values.WeightTypeKey) {
      errors.WeightTypeKey = 'Weight Type is required';
      hasErrors = true;
    }

    // Validate LockTypeKey
    if (!values.LockTypeKey) {
      errors.LockTypeKey = 'Lock Type is required';
      hasErrors = true;
    }

    // Validate JSONDATA
    values.JSONDATA.forEach((item, index) => {
      const jsonErrors = {};
      // console.log(item,'item')
      if (!item.target_value) {
        jsonErrors.target_value = 'Target value is required';
        hasErrors = true;
      }
      if (!item.spec_operator) {
        jsonErrors.spec_operator = 'Specification operator is required';
        hasErrors = true;
      }
      if (!item.spec_value) {
        jsonErrors.spec_value = 'Specification value is required';
        hasErrors = true;
      }
      if (!item.control_chart_operator) {
        jsonErrors.control_chart_operator = 'Control chart operator is required';
        hasErrors = true;
      }
      if (!item.control_chart_value) {
        jsonErrors.control_chart_value = 'Control chart value is required';
        hasErrors = true;
      }
      if (!item.avg_specification_operator) {
        jsonErrors.avg_specification_operator = 'Average specification operator is required';
        hasErrors = true;
      }
      if (!item.avg_specification_value) {
        jsonErrors.avg_specification_value = 'Average specification limit value is required';
        hasErrors = true;
      }
      if (Object.keys(jsonErrors).length) {
        errors.JSONDATA = errors.JSONDATA || [];
        errors.JSONDATA[index] = jsonErrors;
      }
    });

    if (hasErrors) {
      errors.common = 'Please correct the errors below before submitting.';
      setIsError(hasErrors);
    } else { setIsError(hasErrors); }

    return errors;
  };
  // Close handler function
  const handleCloseModal = () => {
    setAddModalStatus(false); // This will set the modal's visibility to false
  };

  const handleSubmit = (values) => {
    callAddNewSizeConfiuration(values);
  };

  useEffect(() => {
    const loadMethods = async () =>{ 
      if (AddModalStatus) {
        await callGetParameters();
        await callGetMathOperator();
        await callGetWeightType();
        await callGetLockType();
      }

    }
    loadMethods();
  }, [AddModalStatus, callGetParameters, callGetMathOperator, callGetWeightType, callGetLockType]);
  console.log('setIsSizeExists', InitialValue, IsSizeExists);
  return (
    <Modal
      open={AddModalStatus}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={updatesStyle}>
        <div
          style={{
            fontFamily: 'Montserrat, Helvetica, sans-serif',
            fontSize: '14px',
            fontWeight: 600,
            padding: '18px',
            borderBottom: '1px solid #383838',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ margin: 0 }}>{ModalHeader}</p>

          <CloseIcon
            className="modal-cross-icon"
            sx ={{
              position: "absolute",
              right: "15px",
              fontSize: "28px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          />
        </div>
        <Formik
          initialValues={InitialValue}
          onSubmit={handleSubmit}
          validate={validate}
          enableReinitialize

        >
          {({ values, handleChange, setFieldValue,
            isValid,
            dirty }) => {
            return (
              <FormikForm style={{ marginLeft: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      marginRight: "10px",
                      position: "sticky",
                      zIndex: "99999",
                      alignItems: "end",
                    }}
                  >
                    <div
                      style={{ width: Text ? "65%" : "76.5%", display: "flex", justifyContent: "space-between" }}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Form.Group controlId="CapsuleSize">
                          <label className={styles.label}>Size </label>
                          <Form.Control
                            type="text"
                            name="Size"
                            disabled={CapsuleSize?.capsuleecno && true}
                            placeholder="Enter Size"
                            value={values?.Size || ""}
                            onChange={(e) => {
                              handleSizeChange(e, setFieldValue);
                              handleChange(e);
                            }}
                            autoComplete="off"
                            style={{
                              backgroundColor: "#404040",
                              width: "200px",
                              height: "40px",
                              border: "none",
                              marginRight: "10px",
                              color: "#fff",
                              fontFamily: "Montserrat, Helvetica, sans-serif",
                              fontSize: "12px",
                            }}
                          />
                        </Form.Group>
                        <Form.Group controlId="CapsuleSize">
                          <label className={styles.label}>Weight Type </label>
                          <div>
                            <Select
                              options={WeightTypeList}
                              value={WeightTypeFilter}
                              onChange={({ value }) => {
                                setWeightFilter(WeightTypeList.find(
                                  (option) =>
                                    option.value ===
                                    value
                                ))
                                setFieldValue('WeightTypeKey', value)
                              }}
                              placeholder="Select..."
                              name="WeightType"
                              className="basic-single-select"
                              classNamePrefix="select"
                              styles={batchSelectBox}
                              isClearable={false}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group controlId="CapsuleSize" style={{ marginLeft: "10px", marginRight: "10px" }}>
                          <label style={{ marginLeft: "10px" }} className={styles.label}>Lock Type </label>
                          <div>
                            <Select
                              options={LockTypeList}
                              value={LockTypeFilter}
                              onChange={({ value }) => {
                                setLockTypeFilter(LockTypeList.find(
                                  (option) =>
                                    option.value ===
                                    value
                                ))
                                setFieldValue('LockTypeKey', value)
                              }}
                              placeholder="Select..."
                              name="LockType"
                              className="basic-single-select"
                              classNamePrefix="select"
                              styles={batchSelectBox}
                              isClearable={false}
                              style={{ marginRight: "10px", marginLeft: "10px" }}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", position: "relative",gap: '30px' }}>
                        <label style={{ position: 'absolute', bottom: "100%", left: "30%" }}>EC Number</label>
                        <Form.Group controlId="CapsuleSAPSize">
                          <label className={styles.label}>Cap</label>
                          <Form.Control
                            type="text"
                            name="CapECNo"
                            placeholder="Cap"
                            value={values?.CapECNo || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              backgroundColor: "#404040",
                              width: "100px",
                              marginRight: "10px",
                              height: "40px",
                              border: "none",
                              color: "#fff",
                              fontFamily: "Montserrat, Helvetica, sans-serif",
                              fontSize: "12px",
                            }}
                          />
                        </Form.Group>
                        <div style={{ position: "absolute", top: "28%", left: "45%", fontSize: "3rem" }}>/</div>
                        <Form.Group controlId="CapsuleSAPSize">
                          <label className={styles.label}>Body</label>
                          <Form.Control
                            type="text"
                            name="BodyECNo"
                            placeholder="Body"
                            value={values?.BodyECNo || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              backgroundColor: "#404040",
                              width: "100px",
                              marginRight: "10px",
                              height: "40px",
                              border: "none",
                              color: "#fff",
                              fontFamily: "Montserrat, Helvetica, sans-serif",
                              fontSize: "12px",
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                 <div className={styles.errmsg}>{sizeExistText ? sizeExistText : ""}</div>
                  <div className={styles.tableBox}>
                    <table className={styles.table}>
                      <thead className={styles.thead}>
                        <tr className={styles.tr}>
                          <th className={styles.th}>Parameters (Parts)</th>
                          <th className={styles.th}>Target</th>
                          <th className={`${styles.th} ${styles.thbr}`} width={"120px"}>
                            Operator{" "}
                          </th>
                          <th className={styles.th}>Specification Limit</th>
                          <th className={styles.th} width={"120px"}>
                            Operator
                          </th>
                          <th className={styles.th}>Control Limit</th>
                          <th className={styles.th} width={"120px"}>
                            Operator
                          </th>
                          <th className={styles.th}>Average Specification Limit</th>
                        </tr>
                      </thead>

                      <tbody className={styles.tbody}>
                        {InitialValue?.JSONDATA.map((item, index) => (
                          values?.JSONDATA?.[index] ? (
                          <tr className={styles.tr} key={index}>

                            <td className={styles.ParamName}>
                              <div>
                                {item?.parameter_name} ({item.part_type}){" "}
                              </div>
                            </td>

                            <td className={styles.td}>
                              <div>
                                <Form.Control
                                  type="text"
                                  disabled={IsSizeExists}
                                  name={"JSONDATA["+index+"].target_value"}
                                  placeholder="Enter target value"
                                  value={values?.JSONDATA?.[index]?.target_value || ''}
                                  onChange={handleChange}
                                  onKeyDown={(e) => {
                                    if (
                                      !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }

                                  }}
                                  autoComplete="off"
                                  style={{
                                    backgroundColor: "#252525",
                                    width: "calc(100% - 10px)",
                                    display: "flex",
                                    marginLeft: "5px",
                                    height: "40px",
                                    border: "none",
                                    color: "#fff",
                                    fontFamily:
                                      "Montserrat, Helvetica, sans-serif",
                                    fontSize: "12px",
                                  }}
                                />
                              </div>
                            </td>
                            <td className={styles.td}>
                              <Select
                                isSearchable={false}
                                name={"spec_operator_"+item?.parameter_id}
                                components={{ DropdownIndicator }}
                                styles={customSelect}
                                options={OperatorList}
                                getOptionValue={(x) => x.id}
                                getOptionLabel={(x) => x.operator_name}
                                value={OperatorList.find(
                                  (option) =>
                                    option.id ===
                                    values?.[
                                    "spec_operator"+item?.parameter_id
                                    ]
                                )}
                                onChange={(selectedOption) =>
                                  setFieldValue("JSONDATA["+index+"].spec_operator", selectedOption.id)
                                }
                                isDisabled={IsSizeExists}
                              />
                            </td>
                            <td className={styles.td}>
                              <Form.Control
                                type="text"
                                name={"JSONDATA["+index+"].spec_value"}
                                placeholder="Enter specification value"
                                value={values?.JSONDATA[index].spec_value || ''}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                autoComplete="off"
                                style={{
                                  backgroundColor: "#252525",
                                  width: "calc(100% - 10px)",
                                  display: "flex",
                                  marginLeft: "5px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  fontFamily: "Montserrat, Helvetica, sans-serif",
                                  fontSize: "12px",
                                }}
                                disabled={IsSizeExists}
                              />
                            </td>
                            <td className={styles.td}>
                              <div className={styles.input}>
                                <Select
                                  isSearchable={false}
                                  name={"control_chart_operator_"+item?.parameter_id}
                                  components={{ DropdownIndicator }}
                                  styles={customSelect}
                                  options={OperatorList}
                                  getOptionValue={(x) => x.id}
                                  getOptionLabel={(x) => x.operator_name}
                                  value={OperatorList.find(
                                    (option) =>
                                      option.id ===
                                      values?.[
                                      "control_chart_operator_"+item?.parameter_id
                                      ]
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue("JSONDATA["+index+"].control_chart_operator", selectedOption.id)
                                  }
                                  isDisabled={IsSizeExists}
                                />
                              </div>
                            </td>
                            <td className={styles.td}>
                              <Form.Control
                                type="text"
                                name={"JSONDATA["+index+"].control_chart_value"}
                                placeholder="Enter control chart value"
                                value={values?.JSONDATA[index].control_chart_value || ''}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }

                                }}
                                autoComplete="off"
                                style={{
                                  backgroundColor: "#252525",
                                  width: "calc(100% - 10px)",
                                  display: "flex",
                                  marginLeft: "5px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  fontFamily: "Montserrat, Helvetica, sans-serif",
                                  fontSize: "12px",
                                }}
                                disabled={IsSizeExists}
                              />
                            </td>
                            <td className={styles.td}>
                              <div className={styles.input}>
                                <Select
                                  isSearchable={false}
                                  name={"avg_specification_operator_"+item?.parameter_id}
                                  components={{ DropdownIndicator }}
                                  styles={customSelect}
                                  options={OperatorList}
                                  getOptionValue={(x) => x.id}
                                  getOptionLabel={(x) => x.operator_name}
                                  value={OperatorList.find(
                                    (option) =>
                                      option.id ===
                                      values?.[
                                      "avg_specification_operator_"+item?.parameter_id
                                      ]
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue("JSONDATA["+index+"].avg_specification_operator", selectedOption.id)
                                  }
                                  isDisabled={IsSizeExists}
                                />
                              </div>
                            </td>
                            <td className={styles.td}>
                              <Form.Control
                                type="text"
                                name={"JSONDATA["+index+"].avg_specification_value"}
                                placeholder="Enter average specification value"
                                value={values?.JSONDATA[index].avg_specification_value || ''}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }

                                }}
                                autoComplete="off"
                                style={{
                                  backgroundColor: "#252525",
                                  width: "calc(100% - 10px)",
                                  display: "flex",
                                  marginLeft: "5px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  fontFamily: "Montserrat, Helvetica, sans-serif",
                                  fontSize: "12px",
                                }}
                                disabled={IsSizeExists}
                              />
                            </td>
                          </tr>
                          )
                          : null
                        ))
                        }
                      </tbody>
                    </table>

                    {/* {(! || BatchChange) && ( */}
                    <Button
                      type="sumbit"
                      className={styles.btn}
                      variant="contained"
                      style={{
                        borderRadius: 4,
                        fontSize: "12px",
                        background: (IsError || IsSizeExists) ? "#4c4c4c" : null,
                        cursor: (IsError) ? "not-allowed" : "pointer",
                        fontWeight: 600,
                        width: "100%",
                        margin:0
                      }}
                      disabled={IsError || IsSizeExists}
                    // onClick={handleSubmit}
                    >
                      {IsError ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ marginTop: 8, marginRight: 10 }}>
                            <HiOutlineInformationCircle
                              size={20}
                              color="#1976D2"
                            />
                          </span>
                          <span style={{ lineHeight: 3 }}>
                            All fields should be filled{" "}
                          </span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>

                  </div>
              </FormikForm>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

AddModal.propTypes = {
  AddModalStatus: PropTypes.bool,
  ModalHeader: PropTypes.string,
  setAddModalStatus: PropTypes.func,
  fetchSize: PropTypes.func,
  set: PropTypes.func,
  CapsuleSize: PropTypes.object,
  MCTypeKey: PropTypes.string,
  From: PropTypes.string,
  setOperation: PropTypes.func,
};

export default AddModal;
