/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { CategoryScale } from "chart.js";

import getRejectionBifurcation from "../../../API/getRejectionBifurcation.js";
import NoData from "../../Reusable/NoData.jsx";

import "./Defect.css";
import { CircularProgress } from "@material-ui/core";

ChartJS.register(ArcElement, Tooltip, CategoryScale);

const DefectChart = ({ datas }) => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const colors = [
    "#5A6979",
    "#D2B1ED",
    "#C26E68",
    "#FDE6A0",
    "#8BCD9D",
    "#84B0F8",
  ];

  const setupTable = (e) => {
    let stack = [];
    for (let i = 0; i < e.length; i++) {
      let name = e[i].DefectSubCategory;
      let value = e[i].Contribution;
      let color = colors[i];
      let res = { name: name, value: value, color: color };
      stack.push(res);
    }
    setTableData(stack);
  };

  const ChartData = {
    labels: data?.map((e) => {
      return e.DefectSubCategory;
    }),
    datasets: [
      {
        label: "My First Dataset",
        data: data?.map((e) => {
          // console.log(typeof(+e.Contribution))
          return parseFloat(e.Contribution);
        }),
        backgroundColor: colors,
        hoverOffset: 1,
      },
    ],
  };

  const fetchDefectData = async (e) => {
    const data = await getRejectionBifurcation(e);

    setData(data?.data?.data);
    setupTable(data?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (datas.flag != "new") {
      setLoading(true);
      fetchDefectData(datas);
    }
  }, [datas]);

  const options = {
    cutout: 35,
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (text) {
            // console.log("tee",text)
            let label = text.formattedValue + " %";

            return text.label + ":" + label;
          },
        },
        backgroundColor: "black",
      },
    },
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
        </div>
      ) : (
        <>
          {(data && data.length) ? (
          // { data.length ? ( 
            <>
              <div className="defect-doughnut-chart">
                <Doughnut data={ChartData} options={options} />
              </div>
              <div className="defect-doughnut-table">
                <table>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          margin: "5px 0px",
                          fontSize: "12px",
                          fontWeight: "600px",
                        }}
                      >
                        Colors
                      </th>
                      <th
                        style={{
                          margin: "5px 0px",
                          fontSize: "12px",
                          fontWeight: "600px",
                        }}
                      >
                        Defects
                      </th>
                      <th
                        style={{
                          margin: "5px 0px",
                          fontSize: "12px",
                          fontWeight: "600px",
                        }}
                      >
                        Contributions
                      </th>
                    </tr>
                    {(tableData || [])?.map((el, i) => (
                      <tr key={i}>
                        <td>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: el.color,
                            }}
                          ></div>
                        </td>
                        <td style={{ fontSize: "12px" }}>{el.name}</td>

                        <td style={{ fontSize: "12px" }}>{el.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  );
};

export default DefectChart;
