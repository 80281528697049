import axiosInstance from '../../utils/api';

const getSizeConfig = async (ProdType, MCtypekey) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axiosInstance
    .get(`/api/od/capsules/size-configurations/${MCtypekey}`, config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        // The client was given an error response (5xx, 4xx)
        console.log(err);
      } else if (err.request) {
        // The client never received a response, and the request was never left
        console.log(err);
      } else {
        // Anything else
        console.log('Error', err.message);
      }
    });
};

export default getSizeConfig;
