/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Defect from "../Defect/Defect";
import moment from "moment";

import { DateRangePicker } from "rsuite";

import DownTimePareto from "../DownTimePareto/DownTimePareto.jsx";
import Conditons_Alarm from "../conditions&Alarm/Conditons&Alarm.jsx";
import MaintenanceHistory from "../MaintenanceHistory/MaintenanceHistory.jsx";
import DippingOverview from "../DippingOverview/DippingOverview.jsx";
import MaintenanceLog from "../MaintenanceLog/MaintenanceLog.jsx";
import getAllMachineList from "../../../API/getAllMachineList.js";
import getAllLineList from "../../../API/getAllLineList.js";
import getPlantList from "../../../API/getPlantList.js";
import getMaintenanceHistory from "../../../API/getMaintenanceHistory.js";
import customSelect from "../../../common/CustomSelect";
import { useSelector, useDispatch } from "react-redux";
import { setMachineStatusFilter } from "../../../redux/slices/filterDMSlice";

import "./MachineStatus.css";
import "rsuite/dist/rsuite.min.css";

const { allowedRange, beforeToday, allowedMaxDays, combine } = DateRangePicker;

function MachineStatus() {
  const dispatch = useDispatch();
  const MachineStatusFilter = useSelector(
    (state) => state.DMFilter.MachineStatusFilter
  );

  const [place, setPlace] = useState("Select Date Range");
  const [IsMaintenance, setIsMaintenance] = useState(false);
  const [logTitle, setLogTitle] = useState("");

  const [plant, setPlant] = useState([]);
  const [machine, setMachine] = useState([]);
  const [shifts, setShifts] = useState([]);

  const [count, setCount] = useState(0);
  const [maintenanceHistory, setmaintenanceHistory] = useState([]);
  const [firstTime, setFirstTime] = useState(false);

  const [selectData, setSelectData] = useState({
    MachineKey: "",
    StartDate: moment().subtract(13, "days").format("YYYY-MM-DD"),
    EndDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    Shift: "",
    flag: true,
  });

  const toggleMaintenance = () => {
    setIsMaintenance(!IsMaintenance);
    setSelectData({
      MachineKey: MachineStatusFilter?.inputData.machine.MachineKey,
      StartDate: moment(MachineStatusFilter?.dateValue[0]).format("YYYY-MM-DD"),
      EndDate: moment(MachineStatusFilter?.dateValue[1]).format("YYYY-MM-DD"),
      Shift: MachineStatusFilter?.inputData.shift.ShiftName,
      flag: false,
    });
  };

  const getPlantListData = async () => {
    const response = await getPlantList();
    setPlant(response?.data.data);
    getMachineList(
      response?.data?.data?.some(
        (e) => e.PlantKey == MachineStatusFilter?.inputData?.plant?.PlantKey
      )
        ? MachineStatusFilter?.inputData?.plant
        : response?.data?.data[0]
    );
    SaveData(
      MachineStatusFilter?.inputData?.plant ?? response?.data?.data[0],
      machine?.some(
        (e) =>
          e.MachineKey == MachineStatusFilter?.inputData?.machine?.MachineKey
      )
        ? MachineStatusFilter?.inputData?.machine
        : null,
      shifts?.some(
        (e) => e.ShiftName == MachineStatusFilter?.inputData?.shift?.ShiftName
      )
        ? MachineStatusFilter?.inputData?.shift
        : null,
      MachineStatusFilter?.dateValue ?? [
        moment().subtract(13, "days")["_d"],
        moment().subtract(0, "days")["_d"],
      ],
      "PLant"
    );
  };

  const getMachineList = async (e) => {
    const response = await getAllMachineList(e.PlantKey);
    setMachine(response.data?.data);
    getLineList(
      e,
      response?.data?.data?.some(
        (e) =>
          e.MachineKey == MachineStatusFilter?.inputData?.machine?.MachineKey
      )
        ? MachineStatusFilter?.inputData?.machine
        : response?.data?.data[0]
    );

    SaveData(
      e,
      response?.data?.data?.some(
        (e) =>
          e?.MachineKey == MachineStatusFilter?.inputData?.machine?.MachineKey
      )
        ? MachineStatusFilter?.inputData?.machine
        : response?.data?.data[0],
      shifts?.some(
        (e) => e.ShiftName == MachineStatusFilter?.inputData?.shift?.ShiftName
      )
        ? MachineStatusFilter?.inputData?.shift
        : null,
      MachineStatusFilter?.dateValue ?? [
        moment().subtract(13, "days")["_d"],
        moment().subtract(0, "days")["_d"],
      ],
      "MAchine"
    );
  };

  const getLineList = async (p, m) => {
    const response = await getAllLineList();
    setShifts(response?.data?.data);
    SaveData(
      p,
      m,
      response?.data?.data?.some(
        (e) => e?.ShiftName == MachineStatusFilter?.inputData?.shift?.ShiftName
      )
        ? MachineStatusFilter?.inputData?.shift
        : response?.data?.data[0],
      MachineStatusFilter?.dateValue ?? [
        moment().subtract(13, "days")["_d"],
        moment().subtract(0, "days")["_d"],
      ],
      "shift"
    );

    setCount(count + 1);
    setmaintenanceHistory([]);
    setFirstTime(true);
  };

  const SaveData = async (plant, machine, shift, date, e) => {
    console.log("MData", plant, machine, shift, date, e);
    dispatch(
      setMachineStatusFilter({
        inputData: {
          plant,
          machine,
          shift,
        },
        dateValue: date,
      })
    );
  };

  const callGetMaintenanceHistory = async () => {
    const response = await getMaintenanceHistory(
      selectData?.MachineKey,
      selectData
    );
    setmaintenanceHistory(response?.data?.data);
  };

  useEffect(() => {
    if (selectData.flag == false) {
      callGetMaintenanceHistory();
    }
  }, [selectData]);

  useEffect(() => {
    getPlantListData();
  }, []);

  useEffect(() => {
    console.log("Mdata-redux", MachineStatusFilter);
  }, [MachineStatusFilter]);
  useEffect(() => {
    if (firstTime) {
      setSelectData({
        MachineKey: MachineStatusFilter?.inputData.machine.MachineKey,
        StartDate: moment(MachineStatusFilter?.dateValue[0]).format(
          "YYYY-MM-DD"
        ),
        EndDate: moment(MachineStatusFilter?.dateValue[1]).format("YYYY-MM-DD"),
        Shift: MachineStatusFilter?.inputData.shift.ShiftName,
        flag: false,
      });
    }
  }, [firstTime]);

  return (
    <div className="digital-container">
      <div className="top-box">
        <div className="box-1">
          <div className="select-div">
            <Select
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Plant"
              options={plant}
              getOptionValue={(x) => x.PlantKey}
              getOptionLabel={(x) => x.PlantName}
              onChange={(e) => {
                SaveData(
                  e,
                  null,
                  null,
                  MachineStatusFilter?.dateValue ?? [
                    moment().subtract(13, "days")["_d"],
                    moment().subtract(0, "days")["_d"],
                  ],
                  "Plant Box"
                );
                getMachineList(e);
              }}
              value={MachineStatusFilter?.inputData?.plant}
            />
          </div>
          <div className="select-div" style={{ marginLeft: "10px" }}>
            <Select
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Machine"
              options={machine}
              getOptionValue={(x) => x.MachineKey}
              getOptionLabel={(x) => x.MachineSerialNo}
              onChange={(e) => {
                SaveData(
                  MachineStatusFilter?.inputData?.plant,
                  e,
                  MachineStatusFilter?.inputData?.shift,
                  MachineStatusFilter?.dateValue ?? [
                    moment().subtract(13, "days")["_d"],
                    moment().subtract(0, "days")["_d"],
                  ],
                  "Machine box"
                );
              }}
              value={MachineStatusFilter?.inputData?.machine}
            />
          </div>

          <div className="select-div" style={{ marginLeft: "10px" }}>
            <DateRangePicker
              placeholder={place}
              format="dd-MM-yyyy"
              onChange={(item) => {
                SaveData(
                  MachineStatusFilter?.inputData?.plant,
                  MachineStatusFilter?.inputData?.machine,
                  MachineStatusFilter?.inputData?.shift,
                  item,
                  "date Box"
                );
              }}
              onClean={() => setPlace("Select Date Range")}
              value={MachineStatusFilter?.dateValue}
              disabledDate={combine(
                allowedRange(beforeToday(), moment()),
                allowedMaxDays(15)
              )}
              editable={false}
              onSelect={() => setPlace("Please Select End Date")}
            />
          </div>
          <div className="select-div" style={{ marginLeft: "10px" }}>
            <Select
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Shift"
              options={shifts}
              getOptionValue={(x) => x.ShiftName}
              getOptionLabel={(x) => x.ShiftName}
              onChange={(e) => {
                SaveData(
                  MachineStatusFilter?.inputData?.plant,
                  MachineStatusFilter?.inputData?.machine,
                  e,
                  MachineStatusFilter?.dateValue ?? [
                    moment().subtract(13, "days")["_d"],
                    moment().subtract(0, "days")["_d"],
                  ],
                  "shift Box"
                );
              }}
              value={MachineStatusFilter?.inputData?.shift}
            />
          </div>
          <div>
            <button
              className="btn-go"
              onClick={() => {
                setSelectData({
                  MachineKey: MachineStatusFilter?.inputData.machine.MachineKey,
                  StartDate: moment(MachineStatusFilter?.dateValue[0]).format(
                    "YYYY-MM-DD"
                  ),
                  EndDate: moment(MachineStatusFilter?.dateValue[1]).format(
                    "YYYY-MM-DD"
                  ),
                  Shift: MachineStatusFilter?.inputData.shift.ShiftName,
                  flag: false,
                });
              }}
              // disabled={MachineStatusFilter?.dateValue?.length==2}
            >
              {console.log("length", MachineStatusFilter?.dateValue?.length)}
              Go
            </button>
          </div>
        </div>
        <div className="box-2">
          <button
            className="digital-btn"
            onClick={() => {
              setIsMaintenance(true);
            }}
          >
            Maintenance Log
          </button>
        </div>
      </div>
      <div className="mid-box">
        <div className="left-con">
          <div className="left-top-box">
            {selectData?.MachineKey != "" && selectData.Shift != "" && (
              <DownTimePareto datas={selectData} />
            )}
          </div>
          <div className="left-down-box">
            {selectData?.MachineKey != "" && selectData.Shift != "" && (
              <MaintenanceHistory
                maintenanceHistory={maintenanceHistory}
                inputData={MachineStatusFilter?.inputData}
              />
            )}
          </div>
        </div>
        <div className="right-con">
          <div className="right-top-box">
            {selectData.MachineKey != "" && selectData.Shift != "" && (
              <Conditons_Alarm
                datas={selectData}
                toggleMaintenance={toggleMaintenance}
                setLogTitle={setLogTitle}
              />
            )}
          </div>
          <div className="right-middle-box">
            {selectData?.MachineKey != "" && selectData?.Shift != "" && (
              <DippingOverview data={selectData} />
            )}
          </div>
          <div className="right-down-box">
            {selectData?.MachineKey != "" && selectData?.Shift != "" && (
              <Defect data={selectData} />
            )}
          </div>
        </div>
      </div>
      {MachineStatusFilter?.inputData?.plant?.PlantKey && (
        <MaintenanceLog
          IsMaintenance={IsMaintenance}
          toggleMaintenance={toggleMaintenance}
          inputData={MachineStatusFilter?.inputData}
          // setInputData={setInputData}
          logTitle={logTitle}
          setLogTitle={setLogTitle}
        />
      )}
    </div>
  );
}

export default MachineStatus;
