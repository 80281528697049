import axiosInstance from '../../utils/api';

const editSizeConfiguration = async (value) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const sendValue = JSON.stringify(
    value, null, 2,
  );
  return axiosInstance.post('/api/od/capsules/size-configurations/edit', sendValue, config)
    .then((response) => response)
    .catch((error) => error);
};
export default editSizeConfiguration;
