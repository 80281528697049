/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";

import MachineStatus from "./MachineStatus/MachineStatus.jsx";
import ParameterPerformance from "./ParameterPerformance/ParameterPerformance.jsx";
import Vibration from "./Vibration/Vibration.jsx";

TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

const DigitalMaintenance = () => {
  const [value, setValue] = useState(0);
  const [RunTime, setRunTime] = useState(null);
  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };
  // const callGetRuntTime = async () => {
  //   const res = await getRunTime();
  //   setRunTime(res);
  // }
  // useEffect(() => {
  //   callGetRuntTime();
  //   const interval = setInterval(() => {
  //     callGetRuntTime()
  //   }, 60 * 1000 * 5)

  //   return clearInterval(interval);
  // }, [])
  return (
    <div className="home-container">
      <div className="tab-div">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ borderBottom: "1px solid #2A2A2A" }}
          >
            <Tab
              label="Machine Status"
              sx={{
                color: "#E0E0E0",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "13px",
                fontFamily: "Montserrat, Arial",
                padding: 0,
                marginRight: "40px",
              }}
            />
            <Tab
              label="Parameter Performance"
              sx={{
                color: "#E0E0E0",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "13px",
                fontFamily: "Montserrat, Arial",
                padding: 0,
                marginRight: "40px",
              }}
            />
            <Tab
              label="Vibration Analysis"
              sx={{
                color: "#E0E0E0",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "13px",
                fontFamily: "Montserrat, Arial",
                padding: 0,
              }}
            />
          </Tabs>
        </Box>
      </div>
      <TabPanel value={value} index={0}>
        <MachineStatus />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ParameterPerformance />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Vibration />
      </TabPanel>
    </div>
  );
};

export default DigitalMaintenance;
