/* eslint  linebreak-style:0 */
/* eslint dot-notation:0 */
/* eslint operator-linebreak:0 */
/* eslint indent:0 */
/* eslint eol-last:0 */
/* eslint no-trailing-spaces:0 */
/* eslint no-multiple-empty-lines:0 */
/* eslint operator-linebreak:0 */
/* eslint object-curly-newline:0 */
const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
