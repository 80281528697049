/* eslint-disable */
import React from "react";

import { Drawer } from "antd";

import ServoMotorParameterForm from "./ServoMotorParameterForm.jsx";

function ServoMotorParameterDrawer({ setEditDrawer, editDrawer,row }) {
  return (
    <div>
      <Drawer
        title={`${editDrawer.path} Parameter`}
        placement="right"
        height="100%"
        width="400px"
        closable
        visible={editDrawer.status}
        className="failuremodulePage"
        closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
        onClose={() =>
          setEditDrawer({
            status: false,
            path: "",
          })
        }
        destroyOnClose
        push={{ distance: 378 }}
        
      >
        <ServoMotorParameterForm
          setEditDrawer={setEditDrawer}
          editDrawer={editDrawer}
          row={row}
        />
      </Drawer>
    </div>
  );
}

export default ServoMotorParameterDrawer;
