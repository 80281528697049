/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import AddEntryPopupda from './AddEntryPopup';
import authHeader from '../../../config/auth.header';
import config from '../../../config/api.config.json';
import { getUserId } from '../../../services/getUserPreference.service';

function EditableCell({
  data,
  value,
  indexvalue,
  column,
  setData,
  weekId,
  disableStatus
  // This is a custom function that we supplied to our table instance
}) {
  // We need to keep and update the state of the cell normally
  const [inValue, setValue] = React.useState(value);
  const onChange = (e) => {
    const regexp = /^(?:\d*\.\d{1,2}|\d+)$/;
    let rsdValue = e.target.value;
    if (rsdValue.match(regexp)) {
      setValue(rsdValue);
    } else {
      setValue('');
    }
  };
  /* eslint no-unused-vars: 0 */
  const updateMyData1 = (indexval, col, inValue, data1) => {
    // const updateMyData1 = (indexval, col) => {
    setData((data1) => data1.map((row1) => {
      if (row1.machine === data.machine) {
        return {
          ...data1[indexval],
          edited: true,
          [weekId]: {
            ...row1[weekId], [col]: inValue, last_edit: moment().format(),
          },
        };
      }
      return row1;
    }));
  };
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData1(indexvalue, column, inValue, data);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <input
      value={inValue}
      disabled={disableStatus}
      style={{
        color: 'white',
        // backgroundColor: '#2A2A2A',
        backgroundColor: 'transparent',
        width: '25px',
        textAlign: 'center',
        padding: 0,
        'border-radius': '3px',
        border: '0px solid',
      }}
      maxLength="3"
      onChange={onChange}
      onBlur={onBlur}
      type="number"
      onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
      placeholder="--"
    />
  );
}

// const defaultColumn = {
//   Cell: EditableCell,
// };

function Table({
  columns, data, updateMyData, skipPageReset,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps, getTableBodyProps, rows, headerGroups, prepareRow,
  } = useTable({
    columns,
    data,
    autoResetPage: !skipPageReset,
    updateMyData,
  });
  // console.log('data--table', data);
  // Render the UI for your table
  /* eslint  react/jsx-props-no-spreading:0 */

  /* eslint react/no-array-index-key:0 */
  return (
    <div className="weekTbleFix">
      <table {...getTableProps()} className="weeklyTable">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index1) => (
                <th key={index1} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const date = new Date();
const currentYear = date.getFullYear();
const newyear = Number(currentYear) + 1;
// const month = date.getMonth();
function Weeklydata({
  calenderFilter, plantFilter, yearFilter, setOnSave, weekRanges, reportInfo
}) {
  const timeToCloseToast = 4000;
  const defaultToast = {
    display: false,
    msg: '',
    cls: '',
  };
  const [showToast, setShowToast] = useState(defaultToast);
  // const getweekRange = (monthValue) => {
  //   const rangeLastvalue = monthValue * 5;
  //   const rangeStartValue = rangeLastvalue + 1 - 5;
  //   const rangeSets = _.range(rangeStartValue, rangeLastvalue + 1);
  //   return rangeSets;
  // };
  // const [weekRangeData, setWeekRangeData] = useState([]);

  // const { ...hooks } = addhook({ undefined, calenderFilter, plantFilter });
  const [popUpDisppop, setPopUpDisPop] = useState(false);
  const [popupData, setPopupData] = useState('');
  const [week, setWeek] = useState('');
  const [columns, setColumns] = useState([]);
  // settypeColors
  const [typeColors] = useState({
    // G: "#FFFF",
    'Gelatin': '#ED5F53',
    'HPMC': '#5AAF6C',
    'H/H': '#FCD050',
    // 'H+': '#FCD050',
    'HR': '#FCD050',
    'Tabsule': '#355dcd',
    'H+': '#08b1f1'
  });

  useState(() => {
    // const weekRangeData = getweekRange(calenderFilter.value);
    // const weeklyRangeData = getweekRange(calenderFilter.value);
    // setWeekRangeData(weeklyRangeData);
  }, []);
  const [data, setData] = useState([]);
  const getPlantList = async () => {
    await axios
      .get(
        config.GET_WEEKLY_DATA,
        {
          params: {
            UserKey: localStorage.getItem('UserKey'),
            plantName: plantFilter.label,
            plantKey: plantFilter.value,
            // pantId: plantFilter.label,
            month: calenderFilter.value,
            year: yearFilter.value,
          },
          headers: authHeader()
        },
      )
      .then((response) => {
        const arr = [];
        const dataset = response?.data?.weekdata || [];
        if (dataset.length > 0) {
          const PreviosData = response.data.previosData
            ? response.data.previosData
            : [];
          // console.log('dataset', dataset);
          dataset.forEach((data) => {
            if (arr.length > 0) {
              const found = arr.findIndex((x) => x.machine === data.machine);
              if (found !== -1) {
                arr.forEach((ele) => {
                  if (ele.machine === data.machine) {
                    const predata = PreviosData.filter(
                      (d) => d.machine === data.machine,
                    );
                    /* eslint-disable no-param-reassign */
                    ele[data.week] = {
                      type: data.type !== null ? data.type : '-',
                      size:
                        data.size !== null
                          ? data.size !== 0
                            ? data.size
                            : predata.length > 0
                              ? predata[0].size
                              : 0
                          : '-',
                      rsd: data.rsd !== null ? data.rsd : '-',
                      last_edit: data.last_edit,
                      disableStatus: data.disableStatus
                    };
                  }
                });
              } else {
                const predata = PreviosData.filter(
                  (d) => d.machine === data.machine,
                );
                arr.push({
                  preWeek: predata.length > 0 ? predata[0].size : 0,
                  machine: data.machine,
                  plant: data.plant,
                  month: data.month,
                  year: data.year,
                  id: data.id,

                  [data.week]: {
                    type: data.type !== null ? data.type : '-',
                    size:
                      data.size !== null
                        ? data.size !== 0
                          ? data.size
                          : predata.length > 0
                            ? predata[0].size
                            : 0
                        : '-',
                    rsd: data.rsd !== null ? data.rsd : '-',
                    last_edit: data.last_edit,
                    disableStatus: data.disableStatus
                  },
                });
              }
            } else {
              const predata = PreviosData.filter(
                (d) => d.machine === data.machine,
              );

              arr.push({
                preWeek: predata.length > 0 ? predata[0].size : 0,
                machine: data.machine,
                plant: data.plant,
                month: data.month,
                year: data.year,
                id: data.id,

                [data.week]: {
                  type: data.type !== null ? data.type : '-',
                  size:
                    data.size !== null
                      ? data.size !== 0
                        ? data.size
                        : predata.length > 0
                          ? predata[0].size
                          : 0
                      : '-',
                  rsd: data.rsd !== null ? data.rsd : '-',
                  last_edit: data.last_edit,
                  disableStatus: data.disableStatus
                },
              });
            }
          });
          // console.log('arrr', arr);
          setData(arr);
        } else {
          setData([]);
        }

        //
        // setPlantsList(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        //   setPlantsList([]);
      });
  };
  const getFYear = (year, month) => {
    if (currentYear === year && month < 3) {
      return `${currentYear}-${newyear}`;
    } if (currentYear === year && month > 3) {
      return `${currentYear}-${currentYear + 1}`;
    }
    return `${year}-${Number(year) + 1}`;
  };
  const getRSDValue = (rsd) => {
    if (['2.01', '2.02', '2.03', '2.04', 2.01, 2.02, 2.03, 2.04].includes(rsd)) {
      return "2";
    }
    return rsd;
  }
  const onSaveData = (monthValue, yearValue, plantId) => {
    // const rangeLastvalue = monthValue * 5;
    // const rangeStartValue = rangeLastvalue + 1 - 5;
    // const rangeSets = _.range(rangeStartValue, rangeLastvalue + 1);
    const rangeSets = weekRanges;
    const dataSave = [];

    const FyDecide = monthValue > 3 ? yearValue : +yearValue + 1;
    // console.log('data--save', data);
    // data.map((dataRow) => {
    data.forEach((dataRow) => {
      // if (dataRow?.edited) {

      dataSave.push({
        machine: dataRow.machine,
        plant: dataRow.plant,
        year: FyDecide,
        month: dataRow.month,
        week: `w${rangeSets[0]}`,
        type: dataRow[`w${rangeSets[0]}`].type,
        size: dataRow[`w${rangeSets[0]}`].size,
        rsd: getRSDValue(dataRow[`w${rangeSets[0]}`].rsd),
        finacial_year: getFYear(yearValue, monthValue),
        last_edit: dataRow[`w${rangeSets[0]}`].last_edit,

        operator: getUserId(),
      });
      dataSave.push({
        machine: dataRow.machine,
        plant: dataRow.plant,
        year: FyDecide,
        month: dataRow.month,
        week: `w${rangeSets[1]}`,
        type: dataRow[`w${rangeSets[1]}`].type,
        size: dataRow[`w${rangeSets[1]}`].size,
        rsd: getRSDValue(dataRow[`w${rangeSets[1]}`].rsd),
        operator: getUserId(),
        last_edit: dataRow[`w${rangeSets[1]}`].last_edit,

        finacial_year: getFYear(yearValue, monthValue),
      });

      dataSave.push({
        machine: dataRow.machine,
        plant: dataRow.plant,

        year: FyDecide,
        month: dataRow.month,
        week: `w${rangeSets[2]}`,
        type: dataRow[`w${rangeSets[2]}`].type,
        size: dataRow[`w${rangeSets[2]}`].size,
        rsd: getRSDValue(dataRow[`w${rangeSets[2]}`].rsd),
        operator: getUserId(),
        last_edit: dataRow[`w${rangeSets[2]}`].last_edit,

        finacial_year: getFYear(yearValue, monthValue),
      });
      dataSave.push({
        machine: dataRow.machine,
        plant: dataRow.plant,

        year: FyDecide,
        month: dataRow.month,
        week: `w${rangeSets[3]}`,
        type: dataRow[`w${rangeSets[3]}`].type,
        size: dataRow[`w${rangeSets[3]}`].size,
        rsd: getRSDValue(dataRow[`w${rangeSets[3]}`].rsd),
        operator: getUserId(),
        last_edit: dataRow[`w${rangeSets[3]}`].last_edit,

        finacial_year: getFYear(yearValue, monthValue),
      });
      if(rangeSets[4]){
        dataSave.push({
          machine: dataRow.machine,
          plant: dataRow.plant,
  
          year: FyDecide,
          month: dataRow.month,
          week: `w${rangeSets[4]}`,
          type: dataRow[`w${rangeSets[4]}`].type,
          size: dataRow[`w${rangeSets[4]}`].size,
          rsd: getRSDValue(dataRow[`w${rangeSets[4]}`].rsd),
          operator: getUserId(),
          last_edit: dataRow[`w${rangeSets[4]}`].last_edit,
  
          finacial_year: getFYear(yearValue, monthValue),
        });
      }
      // }
    });
    axios
      .post(
        config.GET_WEEKLY_DATA_SAVE,
        {
          dataSave, month: monthValue, year: yearValue, plantId,
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        setOnSave((data) => !data);
        setShowToast({
          display: true,
          msg: 'Data Saved Successfully',
          cls: 'Success',
        });

        // hide Toast after 5 seconds
        setTimeout(() => {
          setShowToast(defaultToast);
        }, timeToCloseToast);
        return {
          status: response.status,
          message: 'Success',
        };
      })
      .catch((error) => {
        console.log(error);
        // toast.error("Oop! Something went worng.", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        setShowToast({
          display: true,
          msg: 'Something Went Wrong',
          cls: 'Danger',
        });
        return {
          status: 'Error',
        };
      });
  };

  useEffect(() => {
    // getWeekRanges();
      getPlantList();
    
  }, []);
  useEffect(() => {
    // getWeekRanges();
      getPlantList();
    // console.log('=====================>', plantFilter, calenderFilter, yearFilter);
  }, [plantFilter, calenderFilter, yearFilter]);

  function Headers() {
    return (
      <table>
        <tr>
          <td>RSD</td>
          {' '}
          <td>Size</td>
        </tr>
      </table>
    );
  }

  const updateMyData = (rowIndex, columnId, value) => { console.log(rowIndex, columnId, value); };
  // const Celldata1 = (data, indexValue) => {
  function Celldata1({ data, indexValue }) {
    const defaultType1 = (data[`w${weekRanges[0]}`] && data[`w${weekRanges[0]}`].hasOwnProperty(
      'type',
    ))
      ? data[`w${weekRanges[0]}`].type
      : 'Gelatin';
    return (
      <table
        style={{ color: typeColors[defaultType1] }}
        className="pointer innerTableweek"
        onDoubleClick={() => {
          // setPopUpDisPop((pre) => (!pre));
          setPopUpDisPop((pre) => (
            (data[`w${weekRanges[0]}`] && +data[`w${weekRanges[0]}`].rsd !== 0) ? false : true
          ))
          setPopupData(data);
          setWeek(`w${weekRanges[0]}`);
        }}
      >
        <tr>
          <td>
            <span style={{ border: `1px solid  ${typeColors[defaultType1]} ` }}>
              {/* {data.data["w" + getweekRangeData[0]].rsd} */}
              <EditableCell
                data={data}
                value={data[`w${weekRanges[0]}`] ? data[`w${weekRanges[0]}`].rsd : 0}
                indexvalue={indexValue}
                column="rsd"
                weekId={`w${weekRanges[0]}`}
                setData={setData}
                disableStatus={!reportInfo?.EditAccess}
              />
            </span>
          </td>
          <td>
            <span>{data[`w${weekRanges[0]}`] ? data[`w${weekRanges[0]}`].size : 'N/A'}</span>
          </td>
        </tr>
      </table>
    );
  }
  function Celldata2({ data, indexValue }) {
    const defaultType2 = (data[`w${weekRanges[1]}`] && data[`w${weekRanges[1]}`].hasOwnProperty(
      'type',
    ))
      ? data[`w${weekRanges[1]}`].type
      : 1;

    return (
      <table
        className="pointer innerTableweek"
        onDoubleClick={() => {
          // setPopUpDisPop((pre) => !pre);
          setPopUpDisPop((pre) => (
            (+data[`w${weekRanges[1]}`] && +data[`w${weekRanges[1]}`].rsd !== 0) ? false : true
          ))
          setPopupData(data);
          setWeek(`w${weekRanges[1]}`);
        }}
      >
        <tr>
          <td>
            <span style={{ border: `1px solid  ${typeColors[defaultType2]} ` }}>
              {/* {data.data["w" + weekRangeData[1]].rsd} */}
              <EditableCell
                data={data}
                value={data[`w${weekRanges[1]}`] ? data[`w${weekRanges[1]}`].rsd : 0}
                indexvalue={indexValue}
                column="rsd"
                weekId={`w${weekRanges[1]}`}
                setData={setData}
                // disableStatus={data[`w${weekRanges[1]}`] ? data[`w${weekRanges[1]}`].disableStatus : false}
                disableStatus={!reportInfo?.EditAccess}
              />
            </span>
          </td>
          <td>
            <span>{data[`w${weekRanges[1]}`] ? data[`w${weekRanges[1]}`].size : 'N/A'}</span>
          </td>
        </tr>
      </table>
    );
  }
  function Celldata3({ data, indexValue }) {
    const defaultType3 = (data[`w${weekRanges[2]}`] && data[`w${weekRanges[2]}`].hasOwnProperty('type'))
      ? data[`w${weekRanges[2]}`].type : 1;
    return (
      <table
        className="pointer innerTableweek"
        onDoubleClick={() => {
          // setPopUpDisPop((pre) => !pre);
          setPopUpDisPop((pre) => (
            (+data[`w${weekRanges[2]}`] && +data[`w${weekRanges[2]}`].rsd !== 0) ? false : true
          ));
          setPopupData(data);
          setWeek(`w${weekRanges[2]}`);
        }}
      >
        <tr>
          <td>
            <span style={{ border: `1px solid  ${typeColors[defaultType3]} ` }}>
              <EditableCell
                data={data}
                value={data[`w${weekRanges[2]}`] ? data[`w${weekRanges[2]}`].rsd : 0}
                indexvalue={indexValue}
                column="rsd"
                weekId={`w${weekRanges[2]}`}
                setData={setData}
                // disableStatus={data[`w${weekRanges[2]}`] ? data[`w${weekRanges[2]}`].disableStatus : false}
                disableStatus={!reportInfo?.EditAccess}
              />
            </span>
          </td>
          <td>
            <span>{data[`w${weekRanges[2]}`] ? data[`w${weekRanges[2]}`].size : 'N/A'}</span>
          </td>
        </tr>
      </table>
    );
  }

  function Celldata4({ data, indexValue }) {
    const defaultType4 = (data[`w${weekRanges[3]}`] && data[`w${weekRanges[3]}`].hasOwnProperty(
      'type',
    ))
      ? data[`w${weekRanges[3]}`].type
      : 1;

    return (
      <table
        className="pointer innerTableweek"
        onDoubleClick={() => {
          // setPopUpDisPop((pre) => !pre);
          setPopUpDisPop((pre) => (
            (+data[`w${weekRanges[3]}`] && +data[`w${weekRanges[3]}`].rsd !== 0) ? false : true
          ));
          setPopupData(data);
          setWeek(`w${weekRanges[3]}`);
        }}
      >
        <tr>
          <td>
            <span style={{ border: `1px solid  ${typeColors[defaultType4]} ` }}>
              {/* {data.data["w" + weekRangeData[3]].rsd} */}

              <EditableCell
                data={data}
                value={data[`w${weekRanges[3]}`] ? data[`w${weekRanges[3]}`].rsd : 0}
                indexvalue={indexValue}
                column="rsd"
                weekId={`w${weekRanges[3]}`}
                setData={setData}
                // disableStatus={data[`w${weekRanges[3]}`] ? data[`w${weekRanges[3]}`].disableStatus : false}
                disableStatus={!reportInfo?.EditAccess}
              />
            </span>
          </td>
          <td>
            <span>{data[`w${weekRanges[3]}`] ? data[`w${weekRanges[3]}`].size : 'N/A'}</span>
          </td>
        </tr>
      </table>
    );
  }

  function Celldata5({ data, indexValue }) {
    if (weekRanges[4]) {
      const defaultType5 = (data[`w${weekRanges[4]}`] && data[`w${weekRanges[4]}`].hasOwnProperty(
        'type',
      ))
        ? data[`w${weekRanges[4]}`].type
        : 1;
      return (
        <table
          className="pointer innerTableweek"
          onDoubleClick={() => {
            // setPopUpDisPop((pre) => !pre);
            setPopUpDisPop((pre) => (
              (+data[`w${weekRanges[4]}`] && +data[`w${weekRanges[4]}`].rsd !== 0) ? false : true
            ));
            setPopupData(data);
            setWeek(`w${weekRanges[4]}`);
          }}
        >
          <tr>
            <td>
              <span style={{ border: `1px solid  ${typeColors[defaultType5]} ` }}>
                {/* {data.data["w" + weekRangeData[4]].rsd} */}
                <EditableCell
                  data={data}
                  value={data[`w${weekRanges[4]}`] ? data[`w${weekRanges[4]}`].rsd : 0}
                  indexvalue={indexValue}
                  column="rsd"
                  weekId={`w${weekRanges[4]}`}
                  setData={setData}
                  // disableStatus={data[`w${weekRanges[4]}`] ? data[`w${weekRanges[4]}`].disableStatus : false}
                  disableStatus={!reportInfo?.EditAccess}
                />
              </span>
            </td>
            <td>
              <span>{data[`w${weekRanges[4]}`] ? data[`w${weekRanges[4]}`].size : 'N/A'}</span>
            </td>
          </tr>
        </table>
      );
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (weekRanges && weekRanges.length) {
      let columnsdata = [
        {
          Header: 'Machine',
          accessor: 'machine',
        },
        {
          Header: 'Previous Week size',
          accessor: 'preWeek',
        },

        {
          Header: `Week ${weekRanges[0]}`,
          columns: [
            {
              Header: <Headers />,
              accessor: `w${weekRanges[0]}`,
              Cell: ({ row }) => (
                <Celldata1 data={row.original} indexValue={row.index} />
              ),
            },
          ],
        },

        {
          Header: `Week ${weekRanges[1]}`,
          columns: [
            {
              Header: <Headers />,
              accessor: `w${weekRanges[1]}`,
              Cell: ({ row }) => (
                <Celldata2 data={row.original} indexValue={row.index} />
              ),
            },
          ],
        },

        {
          Header: `Week ${weekRanges[2]}`,
          columns: [
            {
              Header: <Headers />,
              accessor: `w${weekRanges[2]}`,
              Cell: ({ row }) => (
                <Celldata3 data={row.original} indexValue={row.index} />
              ),
            },
          ],
        },

        {
          Header: `Week ${weekRanges[3]}`,
          columns: [
            {
              Header: <Headers />,
              accessor: `w${weekRanges[3]}`,
              Cell: ({ row }) => (
                <Celldata4 data={row.original} indexValue={row.index} />
              ),
            },
          ],
        },
        weekRanges[4] ? {
          Header: `Week ${weekRanges[4]}`,
          columns: [
            {
              Header: <Headers />,
              accessor: `w${weekRanges[4]}`,
              Cell: ({ row }) => (
                <Celldata5 data={row.original} indexValue={row.index} />
              ),
            },
          ],
        } : null,
      ];
      columnsdata = columnsdata.filter((item => item))
      setColumns(columnsdata);
   }
  }, [weekRanges,data]);
  return (
    <>
      {/* <ToastContainer /> */}
      {showToast.display === true ? (
        <div className={`toast_popup ${showToast.cls}`}>
          <span
            className="toast_close"
            onClick={() => {
              setShowToast(defaultToast);
            }}
          >
            X
          </span>
          {showToast.msg}
        </div>
      ) : (
        ''
      )}
      {popUpDisppop && (
        <AddEntryPopupda
          data={data}
          popupData={popupData}
          week={week}
          dataSet={setData}
          closePopUPdata={setPopUpDisPop}
          monthValue={calenderFilter.value}
          weekRanges={weekRanges}
        />
      )}
      {data && (
        <Table columns={columns} data={data} updateMyData={updateMyData} />
      )}
      <div className="RSD_save_btn">
        <button
          type="button"
          onClick={() => onSaveData(
            calenderFilter.value,
            yearFilter.value,
            plantFilter.label,
          )}
          disabled={!reportInfo?.EditAccess}
        >
          Save
        </button>
      </div>
    </>
  );
}

export default Weeklydata;
