import React, { useState } from 'react';
import {
  BrowserRouter, Switch, Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastContainer } from 'react-toastify';
import Login from './components/Login/Login';
// import Dashboard from './components/Dashboard/Dashboard';
import ChangePassword from './components/Change Password/ChangePassword';
// import ResetPassword from './components/Change Password/ForgotPassword';

import SetNewPassword from './components/SetPassword/setPassword';

import EmailPass from './components/ForgotPassword/emailpass';
import FileUpload from './components/FTR/fileUpload';
import Dashboard from './components/Dashboard/Dashboard';
import FTRPrint from './components/FTR';
import IOMatrix from './components/IOMatrix/reportSlide';
import Reports from './components/Reports';
import PowerBIReport from './components/Reports/PowerBIReport';
import ConditionMonitoringComponent from './components/ConditionMonitoring/Condition-Monitoring/condition-monitoring';
import AddGeneralCondition from './components/ConditionMonitoring/general-condition/AddGeneralCondition';
import AddFailureCondition from './components/ConditionMonitoring/failure-notification/AddFailureNotification';
import AddMaintainaceMaster from './components/ConditionMonitoring/maintenance-condition/AddMaintenanceCondition';
import NotificationGroupComponent from './components/ConditionMonitoring/NotificationGroup/notification-group';
import UpdateNotificationGroupComponent from './components/ConditionMonitoring/NotificationGroup/update-notification-group/update-notification-group';
import ConditionMonitoringDashboard from './components/ConditionMonitoring/CmDashboard/conditionMonitoringDashboard';
import NotificationTemplateComponent from './components/ConditionMonitoring/NotificationTemplate/notification-template';
import ExecutionResultComponent from './components/ConditionMonitoring/ExecutionResult/execution-result';
import AlertNotificationLogComponent from './components/ConditionMonitoring/alerts-notifications/alertnotifications';
import DigitalMaintenance from './components/DigitalMaintenance/DigitalMaintenance';

// import FMEATabs from './components/ConditionMonitoring/FMEA/failure-mode-listing/Components/tab';
import FmeaRepository from './components/ConditionMonitoring/FMEA/failure-mode-listing/FMEA_Repository';
import { FailureModeDetails } from './components/ConditionMonitoring/FMEA/failure-mode-details/FailureModeDetails';
import AdminPanel from './components/AdminPanel';
import Grouping from './components/Grouping/Grouping';
// import Grouping from './components/Grouping';
// import KpiConfigureGroups from './components/EnergyMeter/index';
import CapsuleParamTabs from './components/CentralizedCapsule/CapsuleParamTabs';

import { LangEstrings } from './config/englishStrings';
import { LangHstrings } from './config/hindiStrings';
import AppRoutes from './config/appicationRoutes.config.json';
// import { UpdateNotificationGroupRoute, NotificationGroupRoute } from './helpers/constants';
import { LanguageContext } from './LanguageContext';
import './App.css';
import './styles/cm.css';
import './styles/Home.css';
import './styles/dashboard.css';
import './styles/events.css';
import './styles/filter.css';
import './styles/breadcrum.css';
import DigitalMaintenanceRepository from './components/DigitalMaintenanceStatus/DigitalMaintenanceRepository';
// import ClearCache from './common/ClearCache';
import LinkExpired from './components/ForgotPassword/linkexpired.setting';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import PasswordResetSuccess from './components/ResetSuccess/ResetSuccess';
// GETTING ROUTES
const {
  FTR, FTR_UPLOAD, IOMATRIX, REPORT, REPORTS, CHANGE_PASSWORD, WELCOME,
  CmDashboard, NotificationGroup, NotificationTemplate, UpdateNotificationGroup,
  ExecutionResult, AlertNotificationLog, CmGeneralConditionAdd,
  CmGeneralConditionActions, CmFailureConditionAdd, CmFailureConditionActions, ConditionMasters,
  CmMaintainanceConditionAdd, CmMaintainanceConditionActions,
  FailureModeDetails: FailureModeDetailsRoute, FailureModeListingTabs, ADMIN_PANEL, ENERGY_METER,
  DIGITALMAINTENANCELISTINGTABS, DIGITALMAINTENANCE, FORGETPASSWORD, RESETPASSWORD, SETPASSWORD,
  FORGOT_PASSWORD_SUCESS, RESET_SUCCESS, CENTRALIZED_CAPSULE,
} = AppRoutes;
/* eslint-disable */
function App() {
  const { Menus, RoleName } = useSelector((state) => state.user);
  // for language change
  const [Language, setLanguage] = useState(LangEstrings);
  
  function EnglishConverter() {
    setLanguage(LangEstrings);
  }
  function HindiConverter() {
    setLanguage(LangHstrings);
  }
  console.log('onload removed');
  return (
    <div className="">
      <ToastContainer />
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <LanguageContext.Provider
            value={[Language, EnglishConverter, HindiConverter]}
          >
            {/* <ClearCache /> */}
            <Switch>
              <Route exact path={FTR}>
                <Dashboard>{Menus.FTR !== undefined ? <FTRPrint /> : ''}</Dashboard>
              </Route>
              <Route exact path={FTR_UPLOAD}>
                <Dashboard>
                  {Menus.FTRUpload !== undefined ? <FileUpload /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={REPORTS}>
                <Dashboard>{Menus.Reports !== undefined ? <Reports /> : ''}</Dashboard>
                {/* <Dashboard><Reports /></Dashboard> */}
              </Route>
              <Route exact path={REPORT}>
                <Dashboard>
                  {Menus.Reports !== undefined ? <PowerBIReport /> : ''}
                </Dashboard>
              </Route>              
              <Route exact path={IOMATRIX}>
                <Dashboard>
                  {Menus.IOMatrix !== undefined ? <IOMatrix /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={CmDashboard}>
                <Dashboard>
                  {Menus.COND !== undefined ? <ConditionMonitoringDashboard /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={NotificationGroup}>
                <Dashboard>
                  {Menus.COND !== undefined ? <NotificationGroupComponent /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={UpdateNotificationGroup}>
                <Dashboard>
                  {Menus.COND !== undefined ? <UpdateNotificationGroupComponent /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={NotificationTemplate}>
                <Dashboard>
                  {Menus.COND !== undefined ? <NotificationTemplateComponent /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={ConditionMasters}>
                <Dashboard>
                  {Menus.COND !== undefined ? <ConditionMonitoringComponent /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={CmGeneralConditionAdd}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AddGeneralCondition /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={CmGeneralConditionActions}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AddGeneralCondition /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={CmFailureConditionAdd}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AddFailureCondition /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={CmFailureConditionActions}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AddFailureCondition /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={CmMaintainanceConditionAdd}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AddMaintainaceMaster /> : ''}
                </Dashboard>
              </Route>              
              <Route exact path={CmMaintainanceConditionActions}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AddMaintainaceMaster /> : ''}
                </Dashboard>
              </Route>

              <Route exact path={ExecutionResult}>
                <Dashboard>
                  {Menus.COND !== undefined ? <ExecutionResultComponent /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={AlertNotificationLog}>
                <Dashboard>
                  {Menus.COND !== undefined ? <AlertNotificationLogComponent /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={FailureModeListingTabs}>
                <Dashboard>
                  {Menus.FMEA !== undefined ? <FmeaRepository /> : ''}
                </Dashboard>
              </Route>
              <Route exact path={FailureModeDetailsRoute}>
                <Dashboard>
                  <FailureModeDetails />
                </Dashboard>
              </Route>
              <Route exact path={ENERGY_METER}>
                <Dashboard>
                  <Grouping />
                  {/* <KpiConfigureGroups /> */}
                </Dashboard>
              </Route>
              <Route exact path={WELCOME}>
                <Dashboard>
                  <h3>WELCOME TO ACPL</h3>
                </Dashboard>
              </Route>
              <Route exact path={CHANGE_PASSWORD}>
                <Dashboard>
                  <ChangePassword />
                </Dashboard>
              </Route>
              <Route exact path={FORGETPASSWORD}>
                  <EmailPass />
              </Route>
              <Route exact path={RESETPASSWORD}>
                  {/* <ResetPassword /> */}
                  <ForgotPassword />
              </Route>
              <Route exact path={SETPASSWORD}>
                  <SetNewPassword />
              </Route>
              <Route exact path={RESET_SUCCESS}>
                  <PasswordResetSuccess />
              </Route>
              
              {/* <Route exact path="/forgotpass">
                <ForgotPasswordNew />
              </Route> */}
              <Route exact path={FORGOT_PASSWORD_SUCESS}>
                  <LinkExpired />
              </Route>
              <Route exact path={DIGITALMAINTENANCE}>
                <Dashboard>
                  <DigitalMaintenance />
                </Dashboard>
              </Route>
              
              <Route exact path={DIGITALMAINTENANCELISTINGTABS}>
                <Dashboard>
                  <DigitalMaintenanceRepository />
                </Dashboard>
              </Route>

              <Route exact path={CENTRALIZED_CAPSULE}>
                <Dashboard>
                  <CapsuleParamTabs />
                </Dashboard>
              </Route>
              
              <Route exact path="/" component={Login} />
              {RoleName === 'Admin Panel'
                ? (
                  <Route exact path={ADMIN_PANEL}>
                    <Dashboard>
                      <AdminPanel />
                    </Dashboard>
                  </Route>
                )
                : ''}
              <Route exact path="*">
                <Dashboard>
                  <h3>Page not Found</h3>
                </Dashboard>
              </Route>
            </Switch>
          </LanguageContext.Provider>
          <div className="content" />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
