/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  MachineStatusFilter: { inputData: null, dateValue: null },
  ParameterPerformanceFilter: { inputData: null },
  ParameterPerformanceFirstRender: false,
  VibrationAnalysisFilter: { inputData: null },
};
/* eslint  no-param-reassign:0 */
export const DMFilter = createSlice({
  name: "DMFilter",
  initialState,
  reducers: {
    setMachineStatusFilter: (state, action) => {
      state.MachineStatusFilter.inputData = action.payload?.inputData;
      state.MachineStatusFilter.dateValue = action.payload?.dateValue;
    },
    setParameterPerformanceFilter: (state, action) => {
      state.ParameterPerformanceFilter.inputData = action.payload?.inputData;
    },
    setVibrationAnalysisFilter: (state, action) => {
      state.VibrationAnalysisFilter.inputData = action.payload?.inputData;
    },
  },
});
export const {
  setMachineStatusFilter,
  setParameterPerformanceFilter,
  setVibrationAnalysisFilter,
} = DMFilter.actions;

export default DMFilter.reducer;
