import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { useState } from 'react';
import { IoMdSend } from 'react-icons/io';

import Checkbox from '@material-ui/core/Checkbox';
// IMPORTING ICONS
import Icon from '../Icon';

// IMPORTING CSS
import Style from './style.module.css';
/* eslint no-shadow:0 */
/* eslint no-unused-vars:0 */
const {
  actionBtns,
  goToPageInput,
  left,
  pageCount,
  pagination,
  right,
  spsendbtn,
} = Style;
const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow: overlay;
    height:100%;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      white-space:nowrap;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

`;
const index = ({
  columns, data, handleUpdate, handleCheck, pageNumber,
}) => {
  console.log('columns', columns);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber || 0 },
    },
    usePagination,
  );

  const handleChange = (value, column, row) => {
    handleCheck(column, value, row?.values?.RoleReportMapKey, pageIndex);
  };
  // // Render the UI for your table
  return (
    <Styles>
      <table {...getTableProps()} className="tableWrap">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>Edit</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            console.log('row', row);
            return (
              <tr {...row.getRowProps()}>
                <td>
                  <div className={`${actionBtns}`}>
                    <span
                      className="toggleIconOnHover"
                      onClick={() => {
                        handleUpdate(row.original, 'UPDATE');
                      }}
                      title="Edit"
                    >
                      <Icon name="EDIT" enableHover />
                    </span>
                    <span
                      className="toggleIconOnHover"
                      onClick={() => {
                        handleUpdate(row.original, 'DELETE');
                      }}
                      title="Delete"
                    >
                      <Icon name="DELETE" enableHover />
                    </span>
                    { row?.original?.PwdSetFlag === 'Y' && (
                      <span
                        className="toggleIconOnHover"
                        onClick={() => {
                          handleUpdate(row.original, 'SETPASSWORD');
                        }}
                        title="Resend set password link"
                      >
                        {/* <Icon name="SEND" enableHover /> */}
                        <IoMdSend className={spsendbtn} />
                      </span>
                    )}
                  </div>
                </td>
                {row.cells.map((cell) => {
                  if (typeof cell.value === 'string') {
                    const value = cell.value.length > 30 ? `${cell.value.substr(0, 30)}...` : cell.value;
                    cell.value = value;
                  }
                  return typeof cell.value === 'boolean'
                    ? (
                      <td {...cell.getCellProps()}>
                        <Checkbox
                          checked={cell.value}
                          onChange={(e) => handleChange(e.target.checked, cell.column.Header, row)}
                        />
                      </td>
                    )
                    : <td {...cell.getCellProps()}>{cell.value}</td>;
                  // return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
            Pagination can be built however you'd like.
            This is just a very basic UI implementation:
          */}
      <div className={`${pagination}`}>
        <div className={`${left}`}>
          <div>
            <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <Icon name="FIRST-PAGE" active={canPreviousPage} />
            </button>
            <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <Icon name="PREV" active={canPreviousPage} />
            </button>
            <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              <Icon name="NEXT" active={canNextPage} />
            </button>
            <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <Icon name="LAST-PAGE" active={canNextPage} />
            </button>
          </div>
          <div className={`${pageCount}`}>
            <span>
              Page
              {' '}
              <strong>
                {pageIndex + 1}
                {' '}
                of
                {' '}
                {pageOptions.length}
              </strong>
              {' '}
            </span>
          </div>
        </div>
        <div className={`${right}`}>
          <span>
            Go to page:
            {' '}
            <input
              type="number"
              className={`${goToPageInput}`}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                console.log('pageeeeeeeeee', page);
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>
          {' '}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show
                {' '}
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Styles>

  );
};

export default index;
