import axiosInstance from '../../utils/api';

const getSyncJobStatus = async (runID) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const result = await axiosInstance
    .get(`/api/od/capsules/getSyncJobStatus?runID=${runID}`, config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        // The client was given an error response (5xx, 4xx)
        console.log(err);
      } else if (err.request) {
        // The client never received a response, and the request was never left
        console.log(err);
      } else {
        // Anything else
        console.log('Error', err.message);
      }
    });
  return result?.data ?? null;
};

export default getSyncJobStatus;
