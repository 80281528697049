/* eslint-disable */
import axiosInstance from "../utils/api";

export default function getServoMotorParameterDataCategory()  {
    let config = {
        headers: {
          "Content-Type": "application/json",
        },
    };

    return axiosInstance
    .get(`/api/dm/servo-motors-parameters-data-categories`, config )
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      if (err.response) {
        return err;
      } else if (err.request) {
        return err;
      } else {
        return err.message;
      }
    });
  }
  