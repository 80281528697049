import axiosInstance from '../../utils/api';

const getMachineType = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axiosInstance.get('/api/od/capsules/machine-type-details', config)
    .then((response) => response)
    .catch((error) => error);
};
export default getMachineType;
