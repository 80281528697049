import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
/* eslint no-unused-vars:0 */
// IMPORTING ROUTES
import AppRoutes from '../../../config/appicationRoutes.config.json';
import { getSubMenuRoute } from '../../../helpers/constants';
// IMPORTING LOGOS
import Logo from './images/Logo';
import Icon from './images/Icon';

// IMPORTING Styles
import Styles from './style.module.css';

// GETTING ROUTES

const {
  FTR: FTR_ROUTE, FTR_UPLOAD, IOMATRIX, REPORTS: REPORTS_ROUTE, CHANGE_PASSWORD, CmDashboard,
  NotificationGroup, NotificationTemplate, CmGeneralCondition, ExecutionResult, AlertNotificationLog,
  ENERGY_METER, FailureModeListing, ADMIN_PANEL, DIGITALMAINTENANCE,
  DIGITALMAINTENANCESETTINGS, CENTRALIZED_CAPSULE,
} = AppRoutes;

function Sidebar() {
  const { Menus, DefaultRoute, RoleName } = useSelector((state) => state.user);
  // let { Menus } = useSelector((state) => state.user);
  // const { DefaultRoute } = useSelector((state) => state.user);
  const {
    FTR, FTRUpload, Reports, IOMatrix, COND: ConditionMonitoring, FMEA: FmeaRepository,
    DM, CCOD,
  } = Menus;

  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = history.location.pathname.split('/');
  const pagename = location[1]; // location[location.length - 1];
  const siderbar = useRef();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handelDocumentClick = (e) => {
    if (siderbar.current.contains(e.target) === false) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handelDocumentClick);
    return () => {
      document.removeEventListener('click', handelDocumentClick);
    };
  }, []);

  const getSubmenu = () => (ConditionMonitoring?.SubMenu || []).map((item) => (
    <li
      className={`${pagename === item.MenuName.replaceAll(' ', '') ? `${Styles.active} active` : ''
      }`}
    >
      <Link to={getSubMenuRoute(item.MenuCode)}>{item.MenuName}</Link>
    </li>
  ));

  return (
    <div
      className={`${Styles.sidebar} ${isOpen ? `${Styles.openMenu}` : ''}`}
      ref={siderbar}
    >
      <ul className={`${Styles.sideNav}`}>
        <li className={`${Styles.navItem} ${Styles.menuIcon}`}>
          <div className={`${Styles.navAnchor}`}>
            <div className={`${Styles.icon}`} onClick={toggleMenu}>
              <div className={`${Styles.customeMenu}`}>
                <div className={`${Styles.menuBtn}`}>
                  <span className={`${Styles.topLine}`} />
                  <span className={`${Styles.middleLine}`} />
                  <span className={`${Styles.bottomLine}`} />
                </div>
              </div>
            </div>
            <div className={`${Styles.logo}`}>
              <Link to={DefaultRoute}>
                <Logo />
              </Link>
            </div>
          </div>
        </li>

        {/* Reports  */}
        {Reports !== undefined ? (
          <>
            <li
              className={`${Styles.navItem} ${pagename === 'Reports' || pagename === 'Report'
                ? `${Styles.active} active`
                : ''
              } toggleIconOnHover`}
            >
              <Link className={`${Styles.navAnchor}`} to={REPORTS_ROUTE}>
                <div className={`${Styles.icon}`}>
                  <Icon name="REPORT" />
                </div>
                <div className={`${Styles.text}`}>{Reports.MenuName}</div>
              </Link>
            </li>
          </>
        ) : (
          ''
        )}
        {/* FTR  */}
        {FTR !== undefined ? (
          <>
            <li
              className={`${Styles.navItem} ${pagename === 'FTR' ? `${Styles.active} active` : ''
              } toggleIconOnHover`}
            >
              <Link className={`${Styles.navAnchor}`} to={FTR_ROUTE}>
                <div className={`${Styles.icon}`}>
                  <Icon name="FTR" />
                </div>
                <div className={`${Styles.text}`}>{FTR.MenuName}</div>
              </Link>
            </li>
          </>
        ) : (
          ''
        )}
        {/* FTR Upload  */}
        {FTRUpload !== undefined ? (
          <>
            <li
              className={`${Styles.navItem} ${pagename === 'FTRUpload' ? `${Styles.active} active` : ''
              } toggleIconOnHover`}
            >
              <Link className={`${Styles.navAnchor}`} to={FTR_UPLOAD}>
                <div className={`${Styles.icon}`}>
                  <Icon name="FTR-UPLOAD" />
                </div>
                <div className={`${Styles.text}`}>{FTRUpload.MenuName}</div>
              </Link>
            </li>
          </>
        ) : (
          ''
        )}
        {/* IO Matrix */}
        {IOMatrix !== undefined ? (
          <>
            <li
              className={`${Styles.navItem} ${pagename === 'IOMatrix' ? `${Styles.active} active` : ''
              } toggleIconOnHover`}
            >
              <Link className={`${Styles.navAnchor}`} to={IOMATRIX}>
                <div className={`${Styles.icon}`}>
                  <Icon name="IOMATRIX" />
                </div>
                <div className={`${Styles.text}`}>{IOMatrix.MenuName}</div>
              </Link>
            </li>
          </>
        ) : (
          ''
        )}
        {/* Condition Monitoring  */}
        {
          ConditionMonitoring
          && (
            <li
              className={`${Styles.navItem} ${['Dashboard', 'NotificationGroup', 'NotificationTemplate', 'ConditionMasters', 'ExecutionResult',
                'AlertNotificationLog', 'Details',
              ].includes(pagename)
                ? `${Styles.active} active`
                : ''
              } toggleIconOnHover`}
            >
              <Link className={`${Styles.navAnchor}`} to="#">
                <div className={`${Styles.icon}`}>
                  <Icon name="ConditionMonitoring" />
                </div>
                <div className={`${Styles.text}`}>{ConditionMonitoring.MenuName}</div>
              </Link>
              <ul className={`${Styles.subMenu}`}>
                {getSubmenu()}
              </ul>
            </li>
          )
        }

        {/* FMEA Repository */}
        {FmeaRepository
          && (
            <li
              className={`${Styles.navItem} ${['FMEA'].includes(pagename) ? `${Styles.active} active` : 'non-active'} 
            toggleIconOnHover`}
              id="FailureModeListing"
            >
              <Link className={`${Styles.navAnchor}`} to={FailureModeListing}>
                <div className={`${Styles.icon}`}>
                  <Icon name="FMEAIcon" />
                </div>
                <div className={`${Styles.text}`}>{FmeaRepository.MenuName}</div>
              </Link>
            </li>
          )}
        {DM && (
          <li
            className={`${Styles.navItem} ${['DigitalMaintenance'].includes(pagename)
              ? `${Styles.active} active`
              : 'non-active'
            } 
          toggleIconOnHover`}
            id="DigitalMaintenance"
          >
            <Link className={`${Styles.navAnchor}`} to={DIGITALMAINTENANCE}>
              <div className={`${Styles.icon}`}>
                <Icon name="DM" />
              </div>
              <div className={`${Styles.text}`}>{DM.MenuName}</div>
            </Link>
          </li>
        )}
        {CCOD && (
        <li
          className={`${Styles.navItem} ${['centralized-capsule-dimension'].includes(pagename)
            ? `${Styles.active} active`
            : 'non-active'
          } 
          toggleIconOnHover`}
          id="CentralizedCapsule"
        >
          <Link className={`${Styles.navAnchor}`} to={CENTRALIZED_CAPSULE}>
            <div className={`${Styles.icon}`}>
              <Icon name="OD" />
            </div>
            <div className={`${Styles.text}`}>{CCOD.MenuName}</div>
          </Link>
        </li>
        )}
      </ul>

      <ul className={`${Styles.sideNav}`}>
        {/* CHANGE PASSWORD */}
        <li
          className={`${Styles.navItem} ${pagename === 'settings' || pagename === 'change-password' || pagename === 'AdminPanel'
            ? `${Styles.active} active`
            : ''
          } toggleIconOnHover`}
        >
          <Link className={`${Styles.navAnchor}`} to="#">
            <div className={`${Styles.icon}`}>
              <Icon name="SETTING" />
            </div>
            <div className={`${Styles.text}`}>Settings</div>
          </Link>
          <ul className={`${Styles.subMenu}`}>
            <li
              className={`${pagename === 'change-password' ? `${Styles.active} active` : ''
              }`}
            >
              <Link to={CHANGE_PASSWORD}>Change Password</Link>
            </li>
            {RoleName === 'Admin Panel'
              && (
              <li
                className={`${pagename === 'EnergyMeter' ? `${Styles.active} active` : ''
                }`}
              >
                <Link to={ENERGY_METER}>Energy Meter</Link>
              </li>
              )}
            {/* ===== Digital Maintenance Management ====  */}
            {/* {DM && (
            <li
              className={`${pagename === 'settings/servoMotor' ? `${Styles.active} active` : 'non-active'
              }`}
            >
              <Link to={DIGITALMAINTENANCESETTINGS}>Digital Maintenance</Link>
            </li>
            )} */}

            {RoleName === 'Admin Panel'
              ? (
                <li
                  className={`${pagename === 'AdminPanel' ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={ADMIN_PANEL}>Admin Panel</Link>
                </li>
              )

              : ''}
          </ul>
        </li>
      </ul>
    </div>
  );
}
export default Sidebar;
