/*eslint-disable*/
import React from 'react';
import { Line } from "react-chartjs-2";
import 'chart.js/auto';
import { BiExpand } from 'react-icons/bi';

const PerformanceCard = ({ title, item, setItemData, color, toggleExpand }) => {
  const option = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        ticks: {
          color: "#ffffff"
        },
      },
      xAxes: {
        ticks: {
          color: "#ffffff"
        },
      }
    },
  }
  return (
    <div style={{ background: '#2a2c2d', alignItems: "center", padding: 10, display: 'flex', flexDirection: 'column', borderRadius: 4, margin: '20px 10px' }}>
      <div style={{ margin: '5px 0px 15px 0px', display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: '100%' }}>
        <span>{title}</span>
        <span style={{ cursor: "pointer" }}>
          <BiExpand size={16} onClick={() => { toggleExpand(); setItemData({ ...item, color, title }) }} />
        </span></div>
      <div style={{ width: '100%' }}>
        <Line data={{
          labels: item?.timestamp,
          datasets: [
            {
              label: "Trigger Limit",
              data: item?.MaxStopValue,
              fill: false,
              pointRadius: 0,
              borderColor: "#DC3838",
            },
            {
              label: "Trigger Limit",
              data: item?.MinStopValue,
              fill: false,
              pointRadius: 0,
              borderColor: "#DC3838"
            },
            {
              label: "Warning Limit",
              data: item?.MaxWarningValue,
              fill: false,
              pointRadius: 0,
              borderColor: "#FFEC19",
            },
            {
              label: "Warning Limit",
              data: item?.MinWarningValue,
              fill: false,
              pointRadius: 0,
              borderColor: "#FFEC19"
            },
            {
              label: "value",
              data: item?.values,
              pointRadius: 0,
              fill: false,
              borderColor: color,
            }
          ]
        }} options={option}
        />
      </div>
    </div>
  )
}

export default PerformanceCard