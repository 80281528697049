export const batchSelectBox = {
  placeholder: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#404040',
    border: 'none',
    minHeight: '40px',
    minWidth: '200px',
    maxWidth: '800px',
    boxShadow: state.isFocused && 'none',
    fontSize: '12px',
    outline: 'none',
    fontFamily: 'Montserrat, Helvetica, sans-serif',
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#5D97F6' : '#1f1f1f',
    color: state.isFocused ? '#121212' : '#e0e0e0',
    fontSize: '12px',
    fontFamily: 'Montserrat, Helvetica, sans-serif',
    cursor: 'pointer',
    // fontWeight:"bold"
  }),
  input: (styles) => ({
    ...styles,
    color: 'white',
  }),
  menu: (props) => ({
    ...props,
    backgroundColor: '#1f1f1f',
    borderRadius: '4px',
  }),
  singleValue: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  multiValue: (base, state) => (state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base),
  multiValueLabel: (base, state) => (state.data.isFixed
    ? {
      ...base, fontWeight: 'bold', color: 'white', paddingRight: 6,
    }
    : { ...base, fontWeight: 'bold', fontFamily: 'Montserrat' }),
  multiValueRemove: (base, state) => (state.data.isFixed
    ? { ...base, display: 'none' }
    : { ...base, color: 'black' }),

};

export const updatesStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(98vw)',
  bgcolor: '#1F1F1F',
  borderRadius: 2,
  border: '1px solid #313131',
  boxShadow: 50,
  height: '95vh',
  outline: 'none',
  overflow: 'hidden',
};

export const customSelect = {
  placeholder: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  control: (props, state) => ({
    ...props,
    width: '110px',
    marginLeft: '5px',
    border: 'none',
    boxShadow: state.isFocused && 'none',
    backgroundColor: '#252525',
    fontSize: '12px',
    outline: 'none',
    height: '15px',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  singleValue: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  input: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  menu: (props) => ({
    ...props,
    backgroundColor: '#1f1f1f !important',
    borderRadius: '5px',
    position: 'absolute',
    width: 'max-content',
    top: -10,
    height: '50px',
    right: '-100px',
    paddingTop: '12px',
    border: '1px solid #f7f7f7',
  }),
  option: (props, state) => ({
    ...props,
    backgroundColor: state.isFocused ? '#5D97F6' : '#1f1f1f',
    color: state.isFocused ? '#121212' : '#e0e0e0',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    display: 'inline',
    padding: '10px 10px',
  }),
};

export const customEditSizeSelect = {
  placeholder: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  control: (props, state) => ({
    ...props,
    width: '200px',
    marginLeft: '5px',
    border: 'none',
    boxShadow: state.isFocused && 'none',
    backgroundColor: '#404040',
    fontSize: '12px',
    outline: 'none',
    height: '30px',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  singleValue: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  input: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  menu: (props) => ({
    ...props,
    backgroundColor: '#1f1f1f !important',
    borderRadius: '5px',
    position: 'absolute',
    width: 'max-content',
    top: -10,
    height: '50px',
    right: '-100px',
    paddingTop: '12px',
    border: '1px solid #f7f7f7',
  }),
  option: (props, state) => ({
    ...props,
    backgroundColor: state.isFocused ? '#5D97F6' : '#1f1f1f',
    color: state.isFocused ? '#121212' : '#e0e0e0',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    display: 'inline',
    padding: '10px 10px',
  }),
};
