/* eslint-disable */
import axiosInstance from "../utils/api";

export default function getMaintenanceHistory(key, selectData) {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = {};
  if (selectData?.StartDate) {
    data = {
      "StartDate": selectData?.StartDate,
      "EndDate": selectData?.EndDate
    }
  }
  return axiosInstance
    .post(`api/dm/machines/${key}/maintainance-history`, data, config)
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      if (err.response) {
        return err;
      } else if (err.request) {
        return err;
      } else {
        return err.message;
      }
    });
}
