/* eslint-disable */
import { toast } from "react-toastify";
function Toast(status,msg) {
  toast[status](msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export default Toast;
