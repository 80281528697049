/* eslint-disable */
import React,{useState} from 'react';

export default function DataComponent({data, year, Month:selectedMonth}) {

const getMonthYear = (item) => {
    return ['January', 'February', 'March'].includes(item) ? +year + 1 : year
}
const [color] = useState('#5D97F6');
// Group the data by Machine
const tableStyle = {
  border: "1px solid black",
  borderCollapse: "collapse",
  background: "black",
  color: "white"
};

const thStyle = {
  // border: "1px solid white",
  padding: "8px",
};

const tdStyle = {
  // border: "1px solid white",
  padding: "8px",
  textAlign: "center"
};
// Group the data by MonthName
const groupedData = data.reduce((acc, obj) => {
const monthName = obj.MonthName;
if (!acc[monthName]) {
  acc[monthName] = [];
}
acc[monthName].push(obj);
return acc;
}, {});

const getUniqueWeekNumbers = (monthData) => {
const weekNumbers = monthData.map((data) => data.WeekNumber);
return [...new Set(weekNumbers)];
};

// Group the data by Machine
const groupedDataMachine = data.reduce((acc, obj) => {
const machineKey = obj.Machine;
if (!acc[machineKey]) {
  acc[machineKey] = [];
}
acc[machineKey].push(obj);
return acc;
}, {});

return (
<div>
{/* id="my-tables" */}
  <table style={tableStyle} id="rds-table">
    <thead>
      <tr>
        <th style={thStyle}>Machine</th>
        <th style={thStyle}>Month</th>
        {Object.entries(groupedData).map(([monthName, monthData]) => (
          <th style={{ color: monthName === selectedMonth ? color : '' }} key={monthName} colSpan={getUniqueWeekNumbers(monthData).length}>
            {monthName} - {getMonthYear(monthName)}
          </th>
        ))}
      </tr>
      <tr>
        <th style={thStyle}></th>
        <th style={thStyle}>Week</th>
        {Object.entries(groupedData).map(([monthName, monthData]) =>
          getUniqueWeekNumbers(monthData).map((weekNumber) => (
            <th style={{ color: monthName === selectedMonth ? color : '' }} key={weekNumber}>W{weekNumber}</th>
          ))
        )}
      </tr>
    </thead>
    <tbody>
    {Object.entries(groupedDataMachine).map(([machine, MachineData]) =>
    {
      const groupedByMonth = MachineData.reduce((acc, obj) => {
              const key = obj.MonthName;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});          
        return(  
         
          <tr style={thStyle}>
          <th style={thStyle}>{machine}</th>
          <th style={thStyle}>
                <tr style={thStyle} ><td style={{width:'100%'}}>RSD</td></tr>
                <tr style={thStyle}><td style={{width:'100%'}}>Size</td></tr>
                <tr style={thStyle}><td style={{width:'100%'}}>Type</td></tr>
          </th>
            {Object.entries(groupedData).map(([month, monthData]) => (
                groupedByMonth[month].map((dataObj) => (
                  <th style={{ color: month === selectedMonth ? color : '' }}>
                    <tr style={{ color: month === selectedMonth ? color : '' }}><td>{dataObj?.RSD ? dataObj.RSD : '-'}</td></tr>
                    <tr style={{ color: month === selectedMonth ? color : '' }}><td>{dataObj?.Size ? dataObj?.Size : '-' }</td></tr>
                    <tr style={{ color: month === selectedMonth ? color : '' }}><td>{dataObj?.Type ? dataObj.Type : '-'}</td></tr>
                  </th>
                ))
              ))} 
        </tr>
        );
    })}
    </tbody>
  </table>
</div>
);
};