/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { message } from "antd";

import { Formik, Form as FormikForm } from "formik";
import { Button, Spin } from "antd";

import putEditServoMotorParameter from "../../../../API/putEditServoMotorParameter.js";
import getServoMotor from "../../../../API/getServoMotor.js";
import postAddServoMotorParameter from "../../../../API/postAddServoMotorParameter.js";
import getServoMotorParameterDataCategory from "../../../../API/getServoMotorParameterDataCategory.js";
import Toast from "../../../Reusable/Toast.js";
import Input from "./Input.jsx";
import Select from "./Select.jsx";
import ConfirmationModal from "../../Modal.jsx";

import "../../../../styles/failureMode.css";

const validateAdd = Yup.object().shape({
  ServoMotorKey: Yup.string()
    .required("Servo Motor Name is required")
    .notOneOf([""], "Value cannot be empty"),
  DTagName: Yup.string().trim().required("Please enter DTag Name"),
  ParameterName: Yup.string().trim().required("Please enter name"),
  UOM: Yup.string().trim().required("Please enter UOM"),
  DataCategory: Yup.string()
    .required("Data Category is required")
    .notOneOf([""], "Value cannot be empty"),
  Description: Yup.string().trim().required("Please enter description"),
  MaxValue: Yup.number().integer().required("Please enter Max Value"),
  MinValue: Yup.number().integer().required("Please enter Min Value"),
});

const ServoMotorParameterForm = ({ setEditDrawer, editDrawer, row }) => {
  const [spinning, setSpinning] = useState(true);
  const [dropDown, setDropDown] = useState([]);
  const [servoDropdown, setServoDropDown] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    status: false,
    method: "",
  });
  const [values, setValues] = useState(null);

  const handleClose = () => {
    setConfirmModal({ status: false, method: "" });
  };

  const initialValues = {
    ServoMotorKey: editDrawer.path == "Edit" ? row?.ServoMotorKey : "",
    DTagName: editDrawer.path == "Edit" ? row?.DTagName : "",
    ParameterName: editDrawer.path == "Edit" ? row?.ParameterName : "",
    UOM: editDrawer.path == "Edit" ? row?.UOM : "",
    DataCategory: editDrawer.path == "Edit" ? row?.DataCategory : "",
    Description: editDrawer.path == "Edit" ? row?.Description : "",
    MaxValue: editDrawer.path == "Edit" ? +row?.MaxValue : 0,
    MinValue: editDrawer.path == "Edit" ? +row?.MinValue : 0,
  };

  const getDataCategory = async () => {
    const res = await getServoMotorParameterDataCategory();
    setDropDown(res?.data?.data);
    // console.log("data", res);
  };
  const getServoMotorDropdown = async () => {
    const res = await getServoMotor();
    setServoDropDown(res?.data?.data);
    console.log("data", res);
  };

  const editServoMotor = async () => {
    delete values.ServoMotorKey;
    const res = await putEditServoMotorParameter(values, row?.ServoMotorKey);
    if (res?.data?.status == 1) {
      message.success(res?.data?.data);
      setEditDrawer({
        status: false,
        path: "success",
      });
      return;
    }
    message.error(res?.data?.error?.message);
    handleClose();
  };
  const addServoMotor = async (e) => {
    console.log("vaaaa", e);
    const res = await postAddServoMotorParameter(e);
    if (res?.data?.status == 1) {
      message.success(res?.data?.data);
      setEditDrawer({
        status: false,
        path: "success",
      });
      return;
    }
    message.error(res?.data?.error?.message);
  };

  const handleSubmitEvent = (values) => {
    if (values.MaxValue == 0) {
      return Toast("error", "Max Value cannot be zero");
    }
    if (values.MinValue == 0) {
      return Toast("error", "Min Value cannot be zero");
    }
    if (values.MinValue > values.MaxValue) {
      return Toast("error", "Min Value cannot be greater than Max Value");
    }

    setValues(values);
    if (editDrawer.path == "Edit") {
      setConfirmModal({ status: true, method: "Edit" });
    }
    if (editDrawer.path == "Add") {
      setConfirmModal({ status: true, method: "Add" });
    }
  };

  let inputBoxes = [
    {
      title: "Servo Motor Name",
      name: "ServoMotorKey",
      type: "select",
      options: servoDropdown.map((e) => {
        let data = { label: e.ServoMotorName, value: e.ServoMotorKey };
        return data;
      }),
      disable: editDrawer.path == "Edit",
    },
    {
      title: "D Tag Name",
      name: "DTagName",
      type: "text",
    },
    {
      title: "Parameter Name",
      name: "ParameterName",
      type: "text",
    },
    {
      title: "UOM",
      name: "UOM",
      type: "text",
    },
    {
      title: "Data Category",
      name: "DataCategory",
      type: "select",
      options: dropDown.map((e) => {
        let data = { label: e.Category, value: e.Category };
        return data;
      }),
    },
    {
      title: "Description",
      name: "Description",
      type: "text",
    },
    {
      title: "Max Value",
      name: "MaxValue",
      type: "number",
    },
    {
      title: "Min Value",
      name: "MinValue",
      type: "number",
    },
  ];

  useEffect(() => {
    setSpinning(false);
  }, [spinning]);

  useEffect(() => {
    getDataCategory();
    getServoMotorDropdown();
  }, []);

  return (
    <Spin spinning={spinning}>
      <Formik
        initialValues={initialValues}
        validationSchema={validateAdd}
        onSubmit={handleSubmitEvent}
      >
        {({ values, errors, handleChange, touched }) => {
          return (
            <div>
              <FormikForm id="failure-mode-form" className=" fm">
                {inputBoxes?.map((e, i) => (
                  <div>
                    <div className="form-group">
                      <label className="font10">
                        {e?.title}
                        <span className="redStar">*</span>
                      </label>
                      <div style={{ display: "block" }}>
                        {e?.type == "select" ? (
                          <Select
                            key={i}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            values={values}
                            title={e?.title}
                            name={e?.name}
                            inputType={e?.type}
                            options={e?.options ?? ""}
                            disable={e.disable}
                          />
                        ) : (
                          <Input
                            key={i}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            values={values}
                            title={e?.title}
                            name={e?.name}
                            inputType={e?.type}
                          />
                        )}
                      </div>
                      {errors[e?.name] && touched[e?.name] && (
                        <span
                          className="error-field"
                          style={{ color: "red", marginTop: ".5rem" }}
                        >
                          {errors[e?.name]}
                        </span>
                      )}
                    </div>
                  </div>
                ))}

                <div className="pull-right">
                  <Button
                    type="button"
                    style={{ marginRight: "8px" }}
                    onClick={() => setEditDrawer(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    trigger="click"
                    htmlType="submit"
                    onClick={() => {
                      console.log("ssss", values);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </FormikForm>
            </div>
          );
        }}
      </Formik>
      <ConfirmationModal
        open={confirmModal}
        handleClose={handleClose}
        handleConfirm={confirmModal == "Add" ? addServoMotor : editServoMotor}
      />
    </Spin>
  );
};
export default ServoMotorParameterForm;
