/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
// import { getPlants } from '../../../../../server/services/common.service';
import { GET_SHIFT_REPORT_PLANTS, GET_COMMON_SHIFTS, GET_SHIFT_REPORT_DATE } from '../../../config/api.config.json';
import CallCommonAPI from '../../../services/commonAPI.service';
import { setShiftReportData, setShiftReportFilters } from '../../../redux/slices/shiftReportSlice';

const customSelect = {
  placeholder: (props) => ({
    ...props,
    color: "#e0e0e0",
  }),
  control: (props, state) => ({
    ...props,
    width: "195px",
    border: "none",
    boxShadow: state.isFocused && "none",
    backgroundColor: "#1f1f1f",
    fontSize: "12px",
    outline: "none",
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  singleValue: (props) => ({
    ...props,
    color: "#e0e0e0",
  }),
  input: (props) => ({
    ...props,
    color: "#e0e0e0",
  }),
  menu: (props) => ({
    ...props,
    backgroundColor: "#1f1f1f",
  }),
  option: (props, state) => ({
    ...props,
    backgroundColor: state.isFocused ? "#5D97F6" : "",
    color: state.isFocused ? "#121212" : "#e0e0e0",
    fontSize: "12px",
  }),
};

function ShiftFilters() {
  const [date, setDate] = useState(moment());
  const [value, setValue] = useState(0);
  const [plants, setPlants] = useState([]);
  const [plantFilter, setPlantFilter] = useState({});
  const [shifts, setShifts] = useState([]);
  const [shiftFilter, setShiftFilter] = useState([]);
  const [phases, setPhases] = useState([]);
  const [phaseFilter, setPhaseFilter] = useState([]);
  const [halls, setHalls] = useState([]);
  const [hallFilter, setHallFilter] = useState([]);
  const [filterResponse, setFilterResponse] = useState([]);
  const [supervisor, setSupervisor] = useState('');

  // const [filters, setFilter] = useState({});
  let { filters } = useSelector((state) => state?.shiftReport);
  console.log('filters--top', filters)
  // dispatch actions
  const dispatch = useDispatch();


  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getPlants();
  }, [])
  const getPlants = async () => {
    let apiInfo = {
      method: 'get',
      url: `${GET_SHIFT_REPORT_PLANTS}`,
    }
    let plantResponse = await CallCommonAPI(apiInfo);
    filters = {...filters, DateKey: date};
    if (+plantResponse?.data?.status === 1) {
      let plants = plantResponse?.data?.data;
      let response = plantResponse?.data?.response;
      setPlants(plants);
      setFilterResponse(response);
      if (plants && plants.length) {
        filters = {...filters, PlantKey: plants[5].value}
        setPlantFilter(plants[5]);
        // phase dropdown values and filters values setting
        let phases = (response || []).filter(item => item.PlantKey === plants[5].value);
        if (phases && phases.length) {
          console.log('phasesssssssssssssssssss', phases);
          phases = (phases || []).filter((obj, index) => {
            return index === (phases || []).findIndex(o => obj.PhaseName === o.PhaseName);
          });
          if (phases && phases.length) {
            let filterPhases = (phases || []).map((item) => { return { label: item.PhaseName, value: item.PhaseName } });
            setPhases(filterPhases);
            setPhaseFilter(filterPhases[0]);
            filters = {...filters, PhaseKey: filterPhases[0].value}
          }
        }

        // Hall dropdown values and filters values setting
        let halls = (response || []).filter(item => item.PlantKey === plants[5].value);

        if (halls && halls.length) {
          let filterHalls = (halls || []).map((item) => { return { label: item.HallName, value: item.HallName } });
          setHalls(filterHalls);
          setHallFilter(filterHalls[0]);
          filters = {...filters, HallKey: filterHalls[0].value}
        }
        console.log('filterValues--before', filters);
        // setFilter(filterValues);
        dispatch(setShiftReportFilters(filters));
        await getShifts(filters);
      }
    } else {
      setPlants([]);
    }
  }
  const getShifts = async (filterValues) => {
    let apiInfo = {
      method: 'get',
      url: `${GET_COMMON_SHIFTS}`,
    }
    let shiftResponse = await CallCommonAPI(apiInfo);
    if (+shiftResponse?.data?.status === 1) {
      let shifts = shiftResponse?.data?.data;
      setShifts(shifts);
      if (shifts && shifts.length) {
        dispatch(setShiftReportFilters({...filters, ShiftKey: shifts[0].value}));
        setShiftFilter(shifts[0]);
        filters = {...filterValues, ...filters, ShiftKey: shifts[0].value};
        // setFilter(filterValues);
        console.log('dddddddddddddddddddddd', shifts[0]);
        console.log("shift-filterss", filters);
        await getShiftReportData();
      }
    } else {
      setShifts([]);
    }
  }

  const getShiftReportData = async (filterValues) => {
    // console.clear();
    console.log('filterValuesssssssssssss', filterValues, filters);
    let DateKey  = filters?.DateKey || date;
    const PlantKey =  filters?.PlantKey;
    const ShiftKey = filters?.ShiftKey || shiftFilter.value;
    const PhaseKey = filters?.PhaseKey; 
    const LineKey = filters?.HallKey;
    DateKey = moment(DateKey).format('YYYYMMDD');
    // dispatch(setShiftReportFilters(filters));
    let apiInfo = {
      method: 'get',
      url: `${GET_SHIFT_REPORT_DATE}?PlantKey=${PlantKey}&DateKey=${DateKey}&ShiftKey=${ShiftKey}&LineKey=${LineKey}&PhaseKey=${PhaseKey}`,
    }
    let reportResponse = await CallCommonAPI(apiInfo);
    if (+reportResponse?.data?.status === 200) {
      dispatch(setShiftReportData({ data: reportResponse?.data?.data, isLoad: true }));
    } else {
      dispatch(setShiftReportData({ data: [], isLoad: true }));
    }
  }

  // console.log("Filterssssss===========>", plantFilter, shiftFilter);
  const filtersChange = (filter, key, e) => {
    if( key === 'supervisor') {
      const filteredValue = e.target.value.replace(/[^a-zA-Z ]/g, '')
      filter(filteredValue);
      
    } else {
      filter(e);
    }
    if (key === 'supervisor') {
      filters = {...filters, SuperVisor: e.target.value};
      dispatch(setShiftReportFilters(filters));
    } else if (key === 'plant') {
      // setFilter((prevState) => ( {...prevState,PlantKey: e.value} ));
      filters = {...filters, PlantKey: e.value};
      dispatch(setShiftReportFilters(filters));
      getPhases(e.value);
      getHalls(e.value);
    } else if (key === 'phase') {
      // setFilter((prevState) => ( {...prevState,PhaseKey: e.value} ));
      filters = {...filters, PhaseKey: e.value};
      dispatch(setShiftReportFilters(filters));
      getHalls(e.value);
    } else if (key === 'hall') {
      // setFilter((prevState) => ( {...prevState,PhaseKey: e.value} ));
      filters = {...filters, HallKey: e.value};
      dispatch(setShiftReportFilters(filters));
      // getHalls(e.value);
      getShiftReportData();
    } else if (key === 'shift') {
      // setFilter((prevState) => ( {...prevState,ShiftKey: e.value} ));
      filters = {...filters, ShiftKey: e.value};
      dispatch(setShiftReportFilters(filters));
      getShiftReportData();
    } else if(key === 'date') {
      filters = {...filters, DateKey: moment(e).format('YYYYMMDD') };
      dispatch(setShiftReportFilters(filters));
      getShiftReportData();
    } 
    
  }
  // phase dropdown values and filters values setting
  const getPhases = (plantkey) => {
    let phases = (filterResponse || []).filter(item => item.PlantKey === plantkey);
    const uniquePhases = (phases || []).filter((obj, index) => {
      return index === (phases || []).findIndex(o => obj.PhaseName === o.PhaseName);
    });
    if (uniquePhases && uniquePhases.length) {
      let filterPhases = (uniquePhases || []).map((item) => { return { label: item.PhaseName, value: item.PhaseName } });
      setPhases(filterPhases);
      setPhaseFilter(filterPhases[0]);
      // setFilter((prevState) => ( {...prevState,PhaseKey: filterPhases[0].value} ));
      filters = {...filters, PhaseKey: filterPhases[0].value};
      dispatch(setShiftReportFilters(filters));
    }
    getShiftReportData();
  }
  // halls dropdown values and filters values setting
  const getHalls = (plantkey) => {
    let halls = (filterResponse || []).filter(item => item.PlantKey === plantkey);
    if (halls && halls.length) {
      let filterHalls = (halls || []).map((item) => { return { label: item.HallName, value: item.HallName } });
      setHalls(filterHalls);
      setHallFilter(filterHalls[0]);
      // setFilter((prevState) => ( {...prevState,LineKey: filterHalls[0].value} ));
      filters = {...filters, HallKey: filterHalls[0].value};
      dispatch(setShiftReportFilters(filters));
    }
    getShiftReportData();
  }
  return (
    <div className='shfilter-blk'>
      <Select
        placeholder="Select Plant"
        options={plants}
        className="sh-select"
        styles={customSelect}
        // onChange={(e) => { setPlantFilter(e); getShiftReportData(e.value, shiftFilter.value, date) }}
        onChange={(e) => filtersChange(setPlantFilter, 'plant', e)}
        value={plantFilter}
      />
      <Select
        placeholder="Select Phase"
        options={phases}
        className="sh-select"
        styles={customSelect}
        // onChange={(e) => { setPhaseFilter(e); getShiftReportData(plantFilter.value, e.value, date) }}
        onChange={(e) => filtersChange(setPhaseFilter, 'phase', e)}
        value={phaseFilter}
      />
      <Select
        placeholder="Select Hall"
        options={halls}
        className="sh-select"
        styles={customSelect}
        // onChange={(e) => { setHallFilter(e); getShiftReportData(plantFilter.value, e.value, date) }}
        onChange={(e) => filtersChange(setHallFilter, 'hall', e)}
        value={hallFilter}
      />
      <Select
        placeholder="Select Shift"
        options={shifts}
        className="sh-select"
        styles={customSelect}
        // onChange={(e) => { setShiftFilter(e); getShiftReportData(plantFilter.value, e.value, date) }}
        onChange={(e) => filtersChange(setShiftFilter, 'shift', e)}
        value={shiftFilter}
      />

      <div className="sht-dateFilter">
        <DatePicker
          value={date}
          maxDate={new Date()}
          autoOk
          ampm={false}
          onChange={(e) => {filtersChange(setDate, 'date', e) }}
          // onChange={async (newValue) => {
          //   console.log('newValue', newValue)
          //   dispatch(setShiftReportFilters({...filters, DateKey: newValue}))
          //   setDate(newValue);
          //   // filters = {...filters, DateKey: newValue}
          //   await getShiftReportData();
          //   // getShiftReportData(plantFilter.value, shiftFilter.value, newValue);
          // }}
          // onChange={(e) => setDate(e.target.value)}
          className="form-control shftDate input-md date-picker"
          format="dd-MM-yyyy"
        />
      </div>
      <div className='sh-inputBlock'>
        <input type="text" placeholder='Enter Supervisor name' value={supervisor} onChange={(e) => filtersChange(setSupervisor, 'supervisor', e)} />
      </div>
    </div>
  );
}

export default ShiftFilters;
