/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'date-fns';
import './rsc.css';
import 'react-datepicker/dist/react-datepicker.css';

const filterOptions = [
  { value: 'Gelatin', label: 'Gelatin' },
  { value: 'HPMC', label: 'HPMC' },
  // { value: 'H+', label: 'HPMC/Hot Pin' },
  { value: 'H+', label: 'H+'}, 
  { value: 'HR', label: 'HR' },
  { value: 'Tabsule', label: 'Tabsule' },
];

const filterDefaultValue = {
  Gelatin: { value: 'Gelatin', label: 'Gelatin' },
  HPMC: { value: 'HPMC', label: 'HPMC' },
  'HR': { value: 'HR', label: 'HR' },
  'Tabsule': { value: 'Tabsule', label: 'Tabsule' },
  'H+': { value: 'H+', label: 'H+' },
  //  'H+': { value: 'H+', label: 'HPMC/Hot Pin' },
};
const customStyles = {
  // option: () => ({ backgroundColor: '#1F1F1F',paddingBottom: '5px' }),
  option: (props, state) => ({
    ...props,
    backgroundColor: state.isFocused ? "#5D97F6" : "",
    color: state.isFocused ? "#121212" : "#e0e0e0",
  }),
  menu: (provided) => ({ ...provided, backgroundColor: 'black' }),

  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};
function AddentryPopUp({
  closePopUPdata,
  data,
  popupData,
  week,
  dataSet,
  monthValue,
  weekRanges
}) {
  useEffect(() => {}, []);
  const rdsData = popupData; //data.find((item) => item[week]);
  const [rds] = useState(rdsData?.[week]?.rsd);
  const [size, setSize] = useState(rdsData?.[week]?.size);
  const [type] = useState(rdsData?.[week]?.type);
  const [onChangeData, setOnChangeData] = useState();
  const [rsdType] = useState(filterOptions);

  const [rsdTypeFilter, setRsdTypeFilter] = useState(filterDefaultValue[type]);
  // const DefaultDate = popupData.data[week].last_edit != null && popupData.data[week].last_edit !== ''
  //   ? popupData.data[week].last_edit
  //   : new Date();

  const DefaultDate = rdsData?.[week]?.last_edit != null && rdsData?.[week]?.last_edit !== ''
    ? rdsData?.[week]?.last_edit
    : new Date();


  function convertToDate(timestamp) {
    const date = new Date(timestamp);
    const result = `${date.getDate()
    }/${
      date.getMonth() + 1
    }/${
      date.getFullYear()}`;

    return result;
  }

  const [selectedDate, setSelectedDate] = useState(convertToDate(DefaultDate));

  const [lastEdit, setlastEdit] = useState(DefaultDate);

  // const updateMyData1 = (indexvalue, column, value, data1) => {
  //   setData((data1) =>
  //     data1.map((row1, index) => {
  //       if (index === indexvalue) {
  //         return {
  //           ...data1[indexvalue],
  //           [weekId]: { ...row1[weekId], [column]: inValue },
  //         };
  //       }

  //       return row1;
  //     })
  //   );
  // };

  const handleDateChange = (date) => {
    // var dat = new Date(date).toLocaleString();
    setlastEdit(date);
    setSelectedDate(convertToDate(date));
  };
  useEffect(() => {
    // const getweekRange = (monthValue) => {
    //   const rangeLastvalue = monthValue * 5;
    //   const rangeStartValue = rangeLastvalue + 1 - 5;
    //   const rangeSets = _.range(rangeStartValue, rangeLastvalue + 1);
    //   return rangeSets;
    // };
    // const weekSet = getweekRange(monthValue);
    const weekSet = weekRanges;
    const data12 = data.map((data) => {
      // if (data.machine === popupData.data.machine) {
      if (data.machine === rdsData?.machine) {
        if(data[week]){
          data[week].rsd = rds;
          data[week].type = rsdTypeFilter?.value;
          data[week].size = size;
          let weekNumber = week.split('w')[1];
          let findIndex = weekSet.findIndex((item) => item === +weekNumber);
          // let weekvalue = 0;
          // let sliceValue = 0;
          // if (week.length > 0) {
          //   if (week.length > 2) {
          //     weekvalue = week[2];
          //   } else {
          //     weekvalue = week[1];
          //   }
          //   console.log('weekvalue', weekvalue);
          //   if (weekvalue >= 5) {
          //     sliceValue = weekvalue - 5;
          //     console.log('sliceValue', sliceValue, weekSet.slice(sliceValue - 1, 5));
          //     console.log('findIndex', findIndex);
          //     weekSet.slice(sliceValue - 1, 5).map((value) => {
          //       if(data[`w${value}`]){
          //         data[`w${value}`].size = size;
          //         // data["w" + value]["rsd"] = rds;
          //         data[`w${value}`].type = rsdTypeFilter.value;  
          //       }
          //       // data["w" + value]["last_edit"] = lastEdit;
          //     });
          //   } else {
          //     sliceValue = weekvalue % 5;
          //     console.log('elseeeeee-week', sliceValue, weekSet.slice(sliceValue - 1, 5));
          //     weekSet.slice(sliceValue - 1, 5).map((value) => {
          //       data[`w${value}`].size = size;
          //       data[`w${value}`].type = rsdTypeFilter.value;
          //       // data["w" + value]["rsd"] = rds;
          //       //  data["w" + value]["last_edit"] = lastEdit;
          //     });
          //   }
          // } 
          weekSet.slice(findIndex + 1, weekSet.length).map((value) => {
            data[`w${value}`].size = size;
            data[`w${value}`].type = rsdTypeFilter?.value;
            // data["w" + value]["rsd"] = rds;
            //  data["w" + value]["last_edit"] = lastEdit;
          });
          data[week].last_edit = lastEdit;
        }
      }
    });
    setOnChangeData(data);
  }, [rds, size, rsdTypeFilter, lastEdit]);

  const handleChange = (data) => {
    setRsdTypeFilter(data);
  };

  const dataUpdata = () => {
    dataSet(onChangeData);
    closePopUPdata(false);
  };
  const sizeChange = (e) => {
    let regex = /^[A-Z0-9]*$/;
    if(e.target.value.match(regex)) {
      setSize(e.target.value); 
    } else {
      return false;
    }
  }
  return (
    <>
      <div className="RSD_Edit_popup">
        <div className="RSD_Edit_popup_head">
          <h5 className="RSD_Edit_title">Enter value for selected cell</h5>
          <span
            className="RSD_Edit_close"
            onClick={() => closePopUPdata((prev) => !prev)}
          >
            X
          </span>
        </div>
        <div className="RSD_Edit_popup_body">
          <div className="RSD_Edit_popup_row">
            <div className="RSD_Edit_popup_label">
              <label>Type</label>
            </div>
            <div className="RSD_Edit_popup_value">
              <Select
                className="dropdown_rsd"
                name="typedropdown"
                onChange={handleChange}
                defaultValue={false}
                options={rsdType}
                value={rsdTypeFilter}
                noOptionsMessage={() => 'No other plant are available'}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="RSD_Edit_popup_row">
            <div className="RSD_Edit_popup_label">
              <label>Size</label>
            </div>
            <div className="RSD_Edit_popup_value">
              <input
                name="size"
                type="text"
                value={size}
                // onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                // ^[a-zA-Z0-9]*$
                onChange={(e) =>  { sizeChange(e)}}
                // onBlur={onBlur}
              />
            </div>
          </div>
          <div className="RSD_Edit_popup_row">
            <div className="RSD_Edit_popup_label">
              <label>Date:</label>
            </div>
            <div className="RSD_Edit_popup_value">
              <DatePicker
                id="RSD_DatePicker"
                dateFormat="YYYY/MM/dd"
                selectedDate={selectedDate}
                value={selectedDate}
                onChange={handleDateChange}
                maxDate= {new Date()}
              />
            </div>
          </div>
          <div className="RSD_Edit_popup_row">
            <label />
            <div className="">
              <button
                type="button"
                variant="contained"
                className="btn-primary"
                onClick={dataUpdata}
                color="primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="RSD_Edit_popup_BG"
        onClick={() => closePopUPdata((prev) => !prev)}
      />
    </>

  );
}

export default AddentryPopUp;
