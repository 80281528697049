export function convertToDate(timestamp) {
  const date = new Date(timestamp);
  const result = `${date.getDate()
  }/${date.getMonth() + 1
  }/${date.getFullYear()}`;

  return result;
}

export const extractTokenData = (token) => {
  try {
    const base64Url = token.split('.')[1]; // Extract payload part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert Base64URL to Base64
    const jsonPayload = JSON.parse(atob(base64)); // Decode payload

    // Convert timestamps to readable format
    const issuedAt = new Date(jsonPayload.iat * 1000).toLocaleString();
    const expiresAt = new Date(jsonPayload.exp * 1000).toLocaleString();

    // Get current timestamp
    const now = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    if (jsonPayload.exp < now) {
      return { status: false, message: 'Token expired' };
    }

    return { status: true, issuedAt, expiresAt };
  } catch (error) {
    console.error('Invalid token', error);
    return { status: false, message: 'Invalid token' };
  }
};

export default { convertToDate, extractTokenData };
