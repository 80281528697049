/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { TbHandClick } from "react-icons/tb";
import CircularProgress from "@mui/material/CircularProgress";

import getConditionAlarms from "../../../API/getConditionAlarms.js";
import NoData from "../../Reusable/NoData.jsx";
import Log from "../../../images/log-icon.svg";

import "./Conditions&Alarm.css";
function Conditons_Alarm({ datas, toggleMaintenance, setLogTitle }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAlarmData = async (e) => {
    let res = await getConditionAlarms(e);
    setTableData(res?.data?.data);
    setLoading(false);
  };

  useQuery(
    "conditionAlramAPI",
    () => {
      getAlarmData(datas);
    },
    {
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    if (datas.flag != "new") {
      setLoading(true);
      getAlarmData(datas);
    }
  }, [datas]);
  const Table = (prop) => {
    return (
      <table className="alarm-table">
        <tbody>
          {prop.data.map((el, i) => (
            <tr key={i}>
              <td>
                <div className="table-index" style={{ fontSize: "12px" }}>
                  {i + 1}
                </div>
              </td>
              <td style={{ fontSize: "12px" }}>{el.ConditionDescription}</td>
              <td style={{ fontSize: "12px" }}>{el.Timestamp}</td>
              {/* <td>
                <img src={Analize} alt="" />
              </td> */}
              <td>
                {/* <TbHandClick
                  size={20}
                  color="#8e8e8e"
                  style={{ paddingTop: "3px" }}
                /> */}
                <img
                  src={Log}
                  alt=""
                  className="log-icon"
                  onClick={() => {
                    toggleMaintenance();
                    setLogTitle(el.ConditionDescription);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <div className="condition-container">
      <div className="condition-heading" style={{ fontSize: "14px" }}>
        Conditions/Alarms
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "125px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
        </div>
      ) : (
        <div style={{ overflow: "auto", height: "125px" }}>
          {(tableData && tableData.length > 0) ? <Table data={tableData} /> : <NoData />}
        </div>
      )}
    </div>
  );
}

export default Conditons_Alarm;
