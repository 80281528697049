/* eslint no-unused-vars:0 */
/* eslint no-alert:0 */
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { message } from 'antd';
import EMSelect from './EnergyMeter/EMSelect';
import LogTable from './Table';
// import Switch from '../../common/Switch/Switch';
import DateRange from './DateRange';
import Icon from './Icon';
import './style.css';
import Popup from './EditGroupPopup';
import API from '../../config/api.config.json';
import { callApi } from '../../helpers/apiCall';

// const PhaseSelection = ['Gelatine', 'HPMC', 'H+', 'Plant'];
// const GroupSelection = ['HCM', 'AHU Panel', 'Utility', 'Gelroom', 'PFD'];
//

const {
  LoadPlant, LoadEMPlant, LoadEMPlantGroup, LoadEMPlantSubGroup, LoadEMDetails, LoadEMGroupsData,
  SaveEnergyMeters,
} = API;
console.log(LoadPlant);
function log(...arr) {
  console.log(...arr);
}
const FormatAsOptions = (data, { label = '', value = '', percentage = '' }) => {
  const Options = [];
  for (let i = 0; i < data.length; i += 1) {
    const element = data[i];
    const opt = { label: element[label], value: element[value], percentage: element[percentage] };
    Options.push(opt);
  }
  return Options;
};
// const dummy = [
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     SubGroupCode: '101',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-102',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 3,
//     SubGroupCode: '102',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-103',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 4,
//     SubGroupCode: '103',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-104',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 6,
//     SubGroupCode: '104',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-105',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 7,
//     SubGroupCode: '105',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-106',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 8,
//     SubGroupCode: '106',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-107',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 9,
//     SubGroupCode: '107',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-108',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 10,
//     SubGroupCode: '108',
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     TypeName: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-109',
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 11,
//     SubGroupCode: '109',
//   },
// ];
const requestInfo = {};
const plant = 'Plant';
const phaseSelection = 'Phase Selection';
const groupSelection = 'Group Selection';

// const AllMachineData = [
//   {
//     MachineSerialNo: 'INPI_ETP_BIOTECH_EM',
//     PlantKey: 5,
//     MachineKey: 291,
//   },
//   {
//     MachineSerialNo: 'INPI_ETP_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 292,
//   },
//   {
//     MachineSerialNo: 'INPI_ETP_EM_2',
//     PlantKey: 5,
//     MachineKey: 294,
//   },
//   {
//     MachineSerialNo: 'INPI_FEED_WATER_SYSTEM_EM',
//     PlantKey: 5,
//     MachineKey: 295,
//   },
//   {
//     MachineSerialNo: 'INPI_GUEST_HOUSE_EM',
//     PlantKey: 5,
//     MachineKey: 296,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_CIRCULN_PUMP_01_EM',
//     PlantKey: 5,
//     MachineKey: 298,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_CIRCULN_PUMP_02_EM',
//     PlantKey: 5,
//     MachineKey: 299,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_CIRCULN_PUMP_03_EM',
//     PlantKey: 5,
//     MachineKey: 300,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_01_EM',
//     PlantKey: 5,
//     MachineKey: 301,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_02_EM',
//     PlantKey: 5,
//     MachineKey: 302,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_03_EM',
//     PlantKey: 5,
//     MachineKey: 303,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_04_EM',
//     PlantKey: 5,
//     MachineKey: 304,
//   },
//   {
//     MachineSerialNo: 'INPI_HWG_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 305,
//   },
//   {
//     MachineSerialNo: 'INPI_KITCHEN_EM',
//     PlantKey: 5,
//     MachineKey: 306,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_AHU_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 307,
//   },
//   {
//     MachineSerialNo: 'INPI_COMPRESSOR_02_EM',
//     PlantKey: 5,
//     MachineKey: 308,
//   },
//   {
//     MachineSerialNo: 'INPI_COMPRESSOR_04_EM',
//     PlantKey: 5,
//     MachineKey: 309,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CAPACITOR_PANEL_01_EM',
//     PlantKey: 5,
//     MachineKey: 363,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CHILLER_ACC_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 368,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CHILLER_03_EM',
//     PlantKey: 5,
//     MachineKey: 369,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CHILLER_04_EM',
//     PlantKey: 5,
//     MachineKey: 370,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_COLOR_ROOM_EM',
//     PlantKey: 5,
//     MachineKey: 371,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_01_EM',
//     PlantKey: 5,
//     MachineKey: 393,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_02_EM',
//     PlantKey: 5,
//     MachineKey: 394,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_03_EM',
//     PlantKey: 5,
//     MachineKey: 395,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_04_EM',
//     PlantKey: 5,
//     MachineKey: 396,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_DM_HOT_WATER_PLANT_EM',
//     PlantKey: 5,
//     MachineKey: 397,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_DM_WATER_PLANT_EM',
//     PlantKey: 5,
//     MachineKey: 398,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_GELROOM_INCOMER_01_EM',
//     PlantKey: 5,
//     MachineKey: 399,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_GELROOM_INCOMER_02_EM',
//     PlantKey: 5,
//     MachineKey: 400,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_ATS_105_EM',
//     PlantKey: 5,
//     MachineKey: 401,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_ATS_106_EM',
//     PlantKey: 5,
//     MachineKey: 402,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_ATS_107_EM',
//     PlantKey: 5,
//     MachineKey: 403,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_ATS_108_EM',
//     PlantKey: 5,
//     MachineKey: 404,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_ATS_109_EM',
//     PlantKey: 5,
//     MachineKey: 405,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_ATS_110_EM',
//     PlantKey: 5,
//     MachineKey: 406,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_ATS_111_EM',
//     PlantKey: 5,
//     MachineKey: 407,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_ATS_113_EM',
//     PlantKey: 5,
//     MachineKey: 408,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_DRY_AHU_101_EM',
//     PlantKey: 5,
//     MachineKey: 454,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_DRY_AHU_102_EM',
//     PlantKey: 5,
//     MachineKey: 455,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_DRY_AHU_103_EM',
//     PlantKey: 5,
//     MachineKey: 456,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_DRY_AHU_104_EM',
//     PlantKey: 5,
//     MachineKey: 457,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_DRY_AHU_105_EM',
//     PlantKey: 5,
//     MachineKey: 458,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_DRY_AHU_106_EM',
//     PlantKey: 5,
//     MachineKey: 459,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_DRY_AHU_107_EM',
//     PlantKey: 5,
//     MachineKey: 460,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_DRY_AHU_108_EM',
//     PlantKey: 5,
//     MachineKey: 461,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_DRY_AHU_109_EM',
//     PlantKey: 5,
//     MachineKey: 462,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_DRY_AHU_110_EM',
//     PlantKey: 5,
//     MachineKey: 463,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_DRY_AHU_111_EM',
//     PlantKey: 5,
//     MachineKey: 464,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_DRY_AHU_112&113_EM',
//     PlantKey: 5,
//     MachineKey: 465,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_FEEDER_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 466,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HALL_AHU_101_EM',
//     PlantKey: 5,
//     MachineKey: 479,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HALL_AHU_102_EM',
//     PlantKey: 5,
//     MachineKey: 480,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HALL_AHU_103_EM',
//     PlantKey: 5,
//     MachineKey: 481,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_101_EM',
//     PlantKey: 5,
//     MachineKey: 495,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_102_EM',
//     PlantKey: 5,
//     MachineKey: 496,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_103_EM',
//     PlantKey: 5,
//     MachineKey: 497,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_104_EM',
//     PlantKey: 5,
//     MachineKey: 498,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_105_EM',
//     PlantKey: 5,
//     MachineKey: 499,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_106_EM',
//     PlantKey: 5,
//     MachineKey: 500,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_107_EM',
//     PlantKey: 5,
//     MachineKey: 501,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_108_EM',
//     PlantKey: 5,
//     MachineKey: 502,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_109_EM',
//     PlantKey: 5,
//     MachineKey: 503,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_110_EM',
//     PlantKey: 5,
//     MachineKey: 504,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_111_EM',
//     PlantKey: 5,
//     MachineKey: 505,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_112_EM',
//     PlantKey: 5,
//     MachineKey: 506,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_113_EM',
//     PlantKey: 5,
//     MachineKey: 507,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_UPS_101_EM',
//     PlantKey: 5,
//     MachineKey: 595,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_UPS_102_EM',
//     PlantKey: 5,
//     MachineKey: 596,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_UPS_103_EM',
//     PlantKey: 5,
//     MachineKey: 597,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_UPS_104_EM',
//     PlantKey: 5,
//     MachineKey: 598,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_UPS_105_EM',
//     PlantKey: 5,
//     MachineKey: 599,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_UPS_106_EM',
//     PlantKey: 5,
//     MachineKey: 600,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_107_EM',
//     PlantKey: 5,
//     MachineKey: 601,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_108_EM',
//     PlantKey: 5,
//     MachineKey: 602,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_109_EM',
//     PlantKey: 5,
//     MachineKey: 603,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_110_EM',
//     PlantKey: 5,
//     MachineKey: 604,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_111_EM',
//     PlantKey: 5,
//     MachineKey: 605,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_112_EM',
//     PlantKey: 5,
//     MachineKey: 606,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_UPS_113_EM',
//     PlantKey: 5,
//     MachineKey: 607,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_120_EM',
//     PlantKey: 5,
//     MachineKey: 608,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_121_EM',
//     PlantKey: 5,
//     MachineKey: 609,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_122_EM',
//     PlantKey: 5,
//     MachineKey: 610,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_123_EM',
//     PlantKey: 5,
//     MachineKey: 611,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_124_EM',
//     PlantKey: 5,
//     MachineKey: 612,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_125_EM',
//     PlantKey: 5,
//     MachineKey: 613,
//   },
//   {
//     MachineSerialNo: 'P1_H2_UPS_EM_126',
//     PlantKey: 5,
//     MachineKey: 614,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_FEEDER_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 615,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_HCM_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 616,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_HCM_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 617,
//   },
//   {
//     MachineSerialNo: 'P1_HCM_INCOMER_101-106_EM',
//     PlantKey: 5,
//     MachineKey: 618,
//   },
//   {
//     MachineSerialNo: 'INPI_HT_METER_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 624,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM1_15F1_EM',
//     PlantKey: 5,
//     MachineKey: 625,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM1_2F1_EM',
//     PlantKey: 5,
//     MachineKey: 626,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_55KW_WARM_WATER_PUMP_EM',
//     PlantKey: 5,
//     MachineKey: 628,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM1_7F2_EM',
//     PlantKey: 5,
//     MachineKey: 629,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM1_8F3_EM',
//     PlantKey: 5,
//     MachineKey: 630,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_AHF_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 632,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_AHU_PANEL_01_EM',
//     PlantKey: 5,
//     MachineKey: 633,
//   },
//   {
//     MachineSerialNo: 'INPI_COMPRESSOR_01_EM',
//     PlantKey: 5,
//     MachineKey: 634,
//   },
//   {
//     MachineSerialNo: 'INPI_COMPRESSOR_03_EM',
//     PlantKey: 5,
//     MachineKey: 635,
//   },
//   {
//     MachineSerialNo: 'INPI_BIO_REMEDIES_EM',
//     PlantKey: 5,
//     MachineKey: 636,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CAPACITOR_PANEL_01_EM',
//     PlantKey: 5,
//     MachineKey: 637,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CAPACITOR_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 638,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CHILLER_02_EM',
//     PlantKey: 5,
//     MachineKey: 639,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CHILLER_ACC_PANEL_01_EM',
//     PlantKey: 5,
//     MachineKey: 640,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_CHILLER_01_EM',
//     PlantKey: 5,
//     MachineKey: 641,
//   },
//   {
//     MachineSerialNo: 'INPI_DEHUMIDIFIER_EM',
//     PlantKey: 5,
//     MachineKey: 643,
//   },
//   {
//     MachineSerialNo: 'INPI_HEAT_PUMP_01_EM',
//     PlantKey: 5,
//     MachineKey: 644,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_AHU_PANEL_01_EM',
//     PlantKey: 5,
//     MachineKey: 645,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_ACC_PANEL_01_EM',
//     PlantKey: 5,
//     MachineKey: 646,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_HEAT_PUMP_ACC_PANEL_EM',
//     PlantKey: 5,
//     MachineKey: 647,
//   },
//   {
//     MachineSerialNo: 'INPI_HEAT_PUMP_03_EM',
//     PlantKey: 5,
//     MachineKey: 648,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_MLDB_EM',
//     PlantKey: 5,
//     MachineKey: 649,
//   },
//   {
//     MachineSerialNo: 'INPI_LAB_QA_QC_PANEL_EM',
//     PlantKey: 5,
//     MachineKey: 650,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM2_15F1_EM',
//     PlantKey: 5,
//     MachineKey: 651,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM2_17F2_EM',
//     PlantKey: 5,
//     MachineKey: 652,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM2_18F1_EM',
//     PlantKey: 5,
//     MachineKey: 653,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_LT_ROOM2_18F2_EM',
//     PlantKey: 5,
//     MachineKey: 654,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_AHF_PANEL_03_EM',
//     PlantKey: 5,
//     MachineKey: 655,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_05_EM',
//     PlantKey: 5,
//     MachineKey: 656,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_06_EM',
//     PlantKey: 5,
//     MachineKey: 657,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_07_EM',
//     PlantKey: 5,
//     MachineKey: 658,
//   },
//   {
//     MachineSerialNo: 'INPI_DG_08_EM',
//     PlantKey: 5,
//     MachineKey: 659,
//   },
//   {
//     MachineSerialNo: 'INPI_HEAT_PUMP_02_EM',
//     PlantKey: 5,
//     MachineKey: 660,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_AHU_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 661,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CAPACITOR_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 662,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_ACC_PANEL_02_EM',
//     PlantKey: 5,
//     MachineKey: 663,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_MPDB_EM',
//     PlantKey: 5,
//     MachineKey: 664,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_PRIMARY_WTP_EM',
//     PlantKey: 5,
//     MachineKey: 665,
//   },
//   {
//     MachineSerialNo: 'INPI_TRANSFORMER_04_SUB_EM',
//     PlantKey: 5,
//     MachineKey: 666,
//   },
//   {
//     MachineSerialNo: 'INPI_MAIN_GATE_EM',
//     PlantKey: 5,
//     MachineKey: 667,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_MAINTENANCE_LOAD_EM',
//     PlantKey: 5,
//     MachineKey: 668,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_MM_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 669,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_MLDB_CONN_LOAD_200KW_EM',
//     PlantKey: 5,
//     MachineKey: 670,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_MPDB_CONN_LOAD_385KW_EM',
//     PlantKey: 5,
//     MachineKey: 671,
//   },
//   {
//     MachineSerialNo: 'INPI_P1_OFFLINE_MAINTENANCE_EM',
//     PlantKey: 5,
//     MachineKey: 672,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H1_PRINTING_EM',
//     PlantKey: 5,
//     MachineKey: 679,
//   },
//   {
//     MachineSerialNo: 'INPI_P1H2_PRINTING_EM',
//     PlantKey: 5,
//     MachineKey: 686,
//   },
//   {
//     MachineSerialNo: 'P1_PRINTING_EM',
//     PlantKey: 5,
//     MachineKey: 730,
//   },
//   {
//     MachineSerialNo: 'INPI_TRANSFORMER_01_EM',
//     PlantKey: 5,
//     MachineKey: 739,
//   },
//   {
//     MachineSerialNo: 'INPI_TRANSFORMER_02_EM',
//     PlantKey: 5,
//     MachineKey: 740,
//   },
//   {
//     MachineSerialNo: 'INPI_TRANSFORMER_03_EM',
//     PlantKey: 5,
//     MachineKey: 741,
//   },
//   {
//     MachineSerialNo: 'INPI_TRANSFORMER_04_EM',
//     PlantKey: 5,
//     MachineKey: 742,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_ATS_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 757,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_05_EM',
//     PlantKey: 5,
//     MachineKey: 799,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CW_CIRCULN_PUMP_01_EM',
//     PlantKey: 5,
//     MachineKey: 812,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CW_CIRCULN_PUMP_02_EM',
//     PlantKey: 5,
//     MachineKey: 813,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CW_CIRCULN_PUMP_03_EM',
//     PlantKey: 5,
//     MachineKey: 814,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_DM_WATER_DSTRBN_PANEL_EM',
//     PlantKey: 5,
//     MachineKey: 815,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_DM_WATER_DSTRBN_SYSTEM_EM',
//     PlantKey: 5,
//     MachineKey: 816,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_DM_SECONDARY_WTP_EM',
//     PlantKey: 5,
//     MachineKey: 817,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_ETP_GUEST_HOUSE_EM',
//     PlantKey: 5,
//     MachineKey: 818,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_FIRE_HYDRANT_WTP_EM',
//     PlantKey: 5,
//     MachineKey: 819,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_DRY_AHU_114_EM',
//     PlantKey: 5,
//     MachineKey: 832,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_DRY_AHU_115_EM',
//     PlantKey: 5,
//     MachineKey: 833,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_DRY_AHU_116_EM',
//     PlantKey: 5,
//     MachineKey: 834,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_DRY_AHU_117_EM',
//     PlantKey: 5,
//     MachineKey: 835,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_DRY_AHU_118_EM',
//     PlantKey: 5,
//     MachineKey: 836,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_DRY_AHU_119_EM',
//     PlantKey: 5,
//     MachineKey: 837,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_114_EM',
//     PlantKey: 5,
//     MachineKey: 844,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_115_EM',
//     PlantKey: 5,
//     MachineKey: 845,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_116_EM',
//     PlantKey: 5,
//     MachineKey: 846,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_117_EM',
//     PlantKey: 5,
//     MachineKey: 847,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_118_EM',
//     PlantKey: 5,
//     MachineKey: 848,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_119_EM',
//     PlantKey: 5,
//     MachineKey: 849,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_DRY_AHU_120_EM',
//     PlantKey: 5,
//     MachineKey: 957,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_DRY_AHU_121_EM',
//     PlantKey: 5,
//     MachineKey: 958,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_DRY_AHU_122_EM',
//     PlantKey: 5,
//     MachineKey: 959,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_DRY_AHU_123_EM',
//     PlantKey: 5,
//     MachineKey: 960,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_DRY_AHU_124_EM',
//     PlantKey: 5,
//     MachineKey: 961,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_DRY_AHU_125_EM',
//     PlantKey: 5,
//     MachineKey: 962,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_120_EM',
//     PlantKey: 5,
//     MachineKey: 981,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_121_EM',
//     PlantKey: 5,
//     MachineKey: 982,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_122_EM',
//     PlantKey: 5,
//     MachineKey: 983,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_123_EM',
//     PlantKey: 5,
//     MachineKey: 984,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_124_EM',
//     PlantKey: 5,
//     MachineKey: 985,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_125_EM',
//     PlantKey: 5,
//     MachineKey: 986,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_UPS_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 987,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_HCM_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 988,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HCM_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 989,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SOLN_COLOR_PANEL_EM',
//     PlantKey: 5,
//     MachineKey: 995,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SOLN_INCOMER_01_EM',
//     PlantKey: 5,
//     MachineKey: 996,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SOLN_INCOMER_02_EM',
//     PlantKey: 5,
//     MachineKey: 997,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_HW_CIRCULN_PUMP_01_EM',
//     PlantKey: 5,
//     MachineKey: 998,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_HW_CIRCULN_PUMP_02_EM',
//     PlantKey: 5,
//     MachineKey: 999,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_HW_CIRCULN_PUMP_03_EM',
//     PlantKey: 5,
//     MachineKey: 1000,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_HWG_ACC_PANEL_EM',
//     PlantKey: 5,
//     MachineKey: 1001,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_03_EM',
//     PlantKey: 5,
//     MachineKey: 1002,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_04_EM',
//     PlantKey: 5,
//     MachineKey: 1003,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_01_EM',
//     PlantKey: 5,
//     MachineKey: 1005,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_CHILLER_02_EM',
//     PlantKey: 5,
//     MachineKey: 1006,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H1_PRINTING_EM',
//     PlantKey: 5,
//     MachineKey: 1012,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_PRINTING_EM',
//     PlantKey: 5,
//     MachineKey: 1019,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SECONDARY_WTP_EM',
//     PlantKey: 5,
//     MachineKey: 1060,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SPARE_4F1_EM',
//     PlantKey: 5,
//     MachineKey: 1061,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SPARE_4F2_EM',
//     PlantKey: 5,
//     MachineKey: 1062,
//   },
//   {
//     MachineSerialNo: 'INPI_P2_SPARE_4F3_EM',
//     PlantKey: 5,
//     MachineKey: 1063,
//   },
//   {
//     MachineSerialNo: 'INPI_PCC_INCOMER_EM',
//     PlantKey: 5,
//     MachineKey: 1064,
//   },
//   {
//     MachineSerialNo: 'INPI_STP_EM',
//     PlantKey: 5,
//     MachineKey: 1065,
//   },
//   {
//     MachineSerialNo: 'INPI_P2H2_HEATER_PANEL_125_EM',
//     PlantKey: 5,
//     MachineKey: 1412,
//   },
// ];
// const tableDummyData = [
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },

// ];
const index = () => {
  const [DefaultHeaderCol, setDefaultHeaderCol] = useState({
    PlantName: '',
    Type: '',
    GroupName: '',
    SubGroupName: '',
    MachineSerialNo: '',
    Factor: 1,
    Addition: 'Y',
    Substraction: null,
    ValidFrom: '',
    ValidTo: '',
    IsActive: '',
    ManualEntryKey: null,
    PlantKey: '',
    PlantTypeKey: '',
    EMGroupKey: '',
    EMSubGroupKey: '',
    MachineKey: '',
    Remark: '',
  });
  const [JSONData, setJSONData] = useState([]);
  const [titles, setTitles] = useState({
    plant,
    phaseSelection,
    groupSelection,
  });
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [plantData, setPlantData] = useState([]);
  const [phaseSelectionData, setPhaseSelectionData] = useState([]);
  const [groupSelectionData, setGroupSelectionData] = useState([]);
  const [subGroupData, setSubGroupData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tempTableData, setTempTableData] = useState([]);
  const [allMachines, setAllMachines] = useState([]);
  // const [editData,setEditData] = useState([]);
  const [itemsToRemove, setItemsToRemove] = useState([]);
  const SubGroupListRef = useRef();

  const [startDate, setStartDate] = useState(new Date('2014/02/08'));
  const [endDate, setEndDate] = useState(new Date('2014/02/10'));

  const ActiveLI = (e) => {
    const li = e.target.closest('.EM_subGroupItem ');
    const list = SubGroupListRef.current.querySelectorAll('.EM_subGroupItem');
    for (let i = 0; i < list.length; i += 1) {
      const element = list[i];
      if (element.classList.contains('active') === true) {
        element.classList.remove('active');
        break;
      }
    }
    li.classList.add('active');
  };
  const fetchTableData = (details) => {
    if (!details) {
      setTableData([]);
      return;
    }
    const { EMSubGroupKey, SubGroupName } = details;
    requestInfo.EMSubGroupKey = EMSubGroupKey;
    setDefaultHeaderCol({ ...DefaultHeaderCol, EMSubGroupKey, SubGroupName });
    // log(PlantKey);
    const request = {
      method: 'POST',
      url: LoadEMGroupsData,
      postData: requestInfo,
    };
    callApi(request).then((res) => {
      const { status, data } = res;
      // log(data);
      if (status === 200) {
        const FetchedData = data.data;
        setTableData(FetchedData);
        setTempTableData(FetchedData);
        console.log(FetchedData);
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
  };
  const fetchAllMachines = () => {
    const { PlantKey } = requestInfo;
    const request = {
      method: 'POST',
      url: LoadEMDetails,
      postData: {
        PlantKey,
      },
    };
    callApi(request).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        const FetchedData = data.data;
        console.log(FetchedData);
        setAllMachines(FetchedData);
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
  };
  const handlePlantSelect = (e) => {
    const PlantKey = e.target.value;
    const PlantName = e.target.dataset.label;
    setDefaultHeaderCol({ ...DefaultHeaderCol, PlantName, PlantKey });
    setTitles({
      ...titles, plant: e.target.dataset.label, phaseSelection, groupSelection,
    });
    requestInfo.PlantKey = PlantKey;
    const request = {
      method: 'POST',
      url: LoadEMPlant,
      postData: {
        PlantKey,
      },
    };
    callApi(request).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        const FetchedData = data.data;
        const details = {
          label: 'Type',
          value: 'PlantTypeKey',
          percentage: 'Factor',
        };
        const Options = FormatAsOptions(FetchedData, details);
        setPhaseSelectionData(Options);
        setGroupSelectionData([]);
        console.log(Options);
        console.log(data);
        // const PlantOptions = [];
        // for (let i = 0; i < FetchedPlants.length; i += 1) {
        //   const { PlantName, PlantKey } = FetchedPlants[i];
        //   const opt = { label: PlantName, value: PlantKey };
        //   PlantOptions.push(opt);
        // }
        // setPlantData(PlantOptions);
        // console.log(FetchedPlants);
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
    console.log();
  };
  const handlePhaseSelect = (e) => {
    const PlantTypeKey = e.target.value;
    const Type = e.target.dataset.label;
    setTitles({ ...titles, phaseSelection: Type, groupSelection });
    setDefaultHeaderCol({ ...DefaultHeaderCol, Type, PlantTypeKey });

    requestInfo.PlantTypeKey = PlantTypeKey;
    log(PlantTypeKey);
    const request = {
      method: 'POST',
      url: LoadEMPlantGroup,
      postData: {
        PlantTypeKey,
      },
    };
    callApi(request).then((res) => {
      const { status, data } = res;
      log(data);
      if (status === 200) {
        const FetchedData = data.data;
        const details = {
          label: 'GroupName',
          value: 'EMGroupKey',
        };
        const Options = FormatAsOptions(FetchedData, details);
        setGroupSelectionData(Options);
        console.log(Options);
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
  };
  const handleGroupSelect = (e) => {
    const EMGroupKey = e.target.value;
    const GroupName = e.target.dataset.label;
    setTitles({ ...titles, groupSelection: GroupName });

    requestInfo.EMGroupKey = EMGroupKey;
    log(EMGroupKey);
    const request = {
      method: 'POST',
      url: LoadEMPlantSubGroup,
      postData: {
        EMGroupKey,
      },
    };
    callApi(request).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        const FetchedData = data.data;
        console.log(FetchedData);
        setSubGroupData(FetchedData);
        const details = FetchedData[0];
        fetchTableData(details);
        fetchAllMachines(details);

        const { SubGroupName, EMSubGroupKey } = details || {};
        setDefaultHeaderCol({
          ...DefaultHeaderCol, SubGroupName, EMSubGroupKey, GroupName, EMGroupKey,
        });
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
  };
  const handleSelectSubGroup = (e, details) => {
    ActiveLI(e);
    // log(list);
    // log();
    setItemsToRemove([]);
    fetchTableData(details);
  };
  const openPopup = (e) => {
    e.stopPropagation();
    ActiveLI(e);
    setIsShowPopup(true);
  };
  const saveData = () => {
    // console.clear();
    const dataToAdd = [];
    const dataToRemove = [];
    // const dataToUpdate = [];
    const presentInTableData = {};
    console.log('tableData', tableData);
    for (let i = 0; i < tableData.length; i += 1) {
      const element = tableData[i];
      presentInTableData[element.MachineSerialNo] = true;
      if (element.isNew === true || element.isUpdate === true) {
        element.LoggedUserKey = localStorage.getItem('UserKey');
        dataToAdd.push(element);
      }
      // if (element.isUpdate === true) {
      //   dataToUpdate.push(element);
      // }
    }

    for (let i = 0; i < itemsToRemove.length; i += 1) {
      const element = itemsToRemove[i];
      if (element.isNew !== true && !presentInTableData[element.MachineSerialNo]) {
        element.LoggedUserKey = localStorage.getItem('UserKey');
        element.IsActive = 'N';
        element.ValidTo = moment().format('YYYY-MM-DD');
        element.Remark = `Removed: ${moment().format('YYYY-MM-DD')}`;
        dataToRemove.push(element);
      }
    }
    console.log('dataToAdd', dataToAdd);
    console.log('dataToRemove', dataToRemove);
    // console.log('dataToUpdate', dataToUpdate);

    const dataToSend = [...dataToAdd, ...dataToRemove];
    const uniqueRecords = dataToSend.reduce((accumulator, current) => {
      if (!accumulator.find((item) => (item.MachineSerialNo === current.MachineSerialNo))) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    // dataToSend = [...new Map(dataToSend.map((m) => [m.ID, m])).values()];
    console.log('dataToSend', uniqueRecords);
    /* eslint-disable */
    // return false;
    if (uniqueRecords.length <= 0) {
      alert('Please Made Changes First');
      return '';
    }
    const request = {
      method: 'POST',
      url: SaveEnergyMeters,
      postData: {
        JSONData: uniqueRecords,
      },
    };
    callApi(request).then((res) => {
      console.log(res);
      const { status, data } = res;
      if (status === 200) {
        const msg = data.data[0].ActivityResult;
        setItemsToRemove([]);
        // alert(msg);
        message.success(msg);
        fetchTableData(requestInfo);
        fetchAllMachines(requestInfo);
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
  };
  useEffect(() => {
    const request = {
      method: 'POST',
      url: LoadPlant,
    };
    callApi(request).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        const FetchedData = data.data;
        const details = {
          label: 'PlantName',
          value: 'PlantKey',
        };
        const Options = FormatAsOptions(FetchedData, details);
        setPlantData(Options);
        console.log(Options);
      } else {
        throw res;
      }
    }).catch((err) => {
      // setPlantData([]);
      console.log(err);
    });
  }, []);
  const handleStartDate = (startdate) => {
    setStartDate(startdate);
  };
  const handleEndDate = (enddate) => {
    setEndDate(enddate);
  };
  const getFilterData = () => {
    const data = tableData.filter((item) => new Date(startDate).getTime() <= new Date(item.ValidFrom).getTime() &&  new Date(endDate).getTime() >= new Date(item.ValidTo).getTime());
    setTableData(data);
  };
  const getResetFilterData = () => {
    setTableData(tempTableData);
  }
  const onTableSubmit = (updatedRecord) => {
    const update = (tableData || []).map((item) => {
      // console.log('dataaaaa', item);
      if (item.MachineSerialNo === updatedRecord.MachineSerialNo && item.ID === updatedRecord.ID) {
        return { ...item, ...updatedRecord, isUpdate: true };
      }
      return item;
    });
    console.log('updated', update);
    setTableData(update);
  };
  return (
    <>
      <div className="EnergyMeter__container">
        <div className="EM__Header">
          <div className="EM__groups">
            <EMSelect title={titles.plant} options={plantData} onChange={handlePlantSelect} />
            <EMSelect title={titles.phaseSelection} options={phaseSelectionData} onChange={handlePhaseSelect} />
            <EMSelect title={titles.groupSelection} options={groupSelectionData} onChange={handleGroupSelect} />
          </div>
          <DateRange startDate={startDate} endDate={endDate} handleStartDate={handleStartDate} handleEndDate={handleEndDate} />
          <div className="EM__rightHead">
            {/* <button type="button" className="btn-primary" onClick={openPopup}>Edit</button> */}
            <button type="button" className="btn-primary" onClick={() => getFilterData()}>Go</button>
            <button type="button" className="btn-primary" onClick={() => getResetFilterData()}>Reset</button>
            <button type="button" className="btn-primary" onClick={saveData}>Save</button>
          </div>
        </div>
        <div className="EM__content">
          <div className="EM__subGroups">
            <ul ref={SubGroupListRef}>

              {subGroupData.length > 0 ? subGroupData.map((current, i) => {
                const { SubGroupName } = current;
                return (
                  <li
                    className={`EM_subGroupItem ${i === 0 ? 'active' : ''}`}
                    onClick={(e) => {
                      handleSelectSubGroup(e, current);
                    }}
                  >
                    {SubGroupName}

                    <Icon
                      name="edit"
                      onClick={openPopup}
                    />
                  </li>
                );
              }) : 
              // <li>No Sub Groups Found</li>
                null
              }
              {/* <li className="active">HCM101</li> */}
            </ul>
            <div className='noSubgrp'>
               {subGroupData.length === 0 &&
                  <p>No Sub Groups Found</p>
              }
            </div>
          </div>
          <div className="EM__right">
            <div className="EM__rightContent">
              <LogTable data={tableData} onTableSubmit={onTableSubmit} />
              {/* <LogTable data={tableDummyData} /> */}
            </div>
            {/* <div className="EM__rightHead">
              <button type="button" className="btn-primary" onClick={openPopup}>Edit</button>
              <button type="button" className="btn-primary">Save</button>
            </div> */}
          </div>
        </div>
      </div>
      {console.log('isShowPopup', isShowPopup)}
      {isShowPopup ? (
        <Popup
          setIsShowPopup={setIsShowPopup}
          allMachines={allMachines}
          setAllMachines={setAllMachines}
          tableData={tableData}
          setTableData={setTableData}
          DefaultHeaderCol={DefaultHeaderCol}
          JSONData={JSONData}
          setJSONData={setJSONData}
          setItemsToRemove={setItemsToRemove}
          itemsToRemove={itemsToRemove}
        />
      ) : ''}
    </>
  );
};
export default index;
