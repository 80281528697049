/* eslint-disable */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Logo from './images/Logo'; 
import Styles from './setPasswordStyle.module.css';
/* eslint no-shadow:0 */
// IMPORTING SERVICES
import userManagementService from '../../services/userManagement.service';

// IMPORTIG ACTIONS
import {
  setMenuAndReport,
  setDefaultRoute,
  resetState,
} from '../../redux/slices/userSlice';

// IMPORT POSSIBLE ROUTES
import AppRoutes from '../../config/appicationRoutes.config.json';
const { RESET_SUCCESS } = AppRoutes;
const {
  login, forgetLink, getUserData, getDefaultRoute,
  setPasswordApi
} = userManagementService;
import { 
  EMAIL_ID_AND_PASSWORD_IS_REQUIRED, 
  EMAIL_IS_REQUIRED,
  PASSWORD_CONFIRM_PASSWORD_REQUIRED,
  
  PASSWORD_RULES,
  PASSWORD_CONFIRM_PASSWORD_SAME,
  EMAILID_NOT_REGISTERED_WITH_US,
  PLEASE_CHECK_EMAILID_CONTACT_ADMIN
} from '../../helpers/constants';
/* eslint no-shadow:0 */

function SetNewPassword() {
  const {email, token} =  useParams();
//   console.log('params', params);; 
//   const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [linkExperror, setLinkExpError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    if (email === '') {
      setError(EMAIL_IS_REQUIRED);
    } else {
      forgetLink(email).then((res) => {
        if (res.status !== 200) {
          setError(EMAILID_NOT_REGISTERED_WITH_US);
        } else {
          setError(res?.data?.data);
          // setIsForget(true)
          history.replace('/');
        }
      }).catch(() => setError(PLEASE_CHECK_EMAILID_CONTACT_ADMIN));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#\^(){}|\[\]])[A-Za-z\d@$!%*?&#\^(){}|\[\]]{8,}$/;

    if (email === '' && password === '') {
      setError(EMAIL_ID_AND_PASSWORD_IS_REQUIRED);
    } else if (email === '') {
      setError(EMAIL_IS_REQUIRED);
    } else if (password === '' || confirmPassword === '') {
      setError(PASSWORD_CONFIRM_PASSWORD_REQUIRED);
    } else if (!passwordRegex.test(password)) {
      setError(PASSWORD_RULES);
    } else if(password !== confirmPassword){
      setError(PASSWORD_CONFIRM_PASSWORD_SAME);
    } else {
        let res = await setPasswordApi({ email, confirmPassword })
        if( res?.data?.status === 1){
          history.push(RESET_SUCCESS,{state: "setPasswordSuccess"});       
        } else {
          setError(res?.data?.data);
        }
    }
  };

  const extractTokenData = (token) => {
    try {
        const base64Url = token.split('.')[1]; // Extract payload part
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert Base64URL to Base64
        const jsonPayload = JSON.parse(atob(base64)); // Decode payload

        // Convert timestamps to readable format
        const issuedAt = new Date(jsonPayload.iat * 1000).toLocaleString();
        const expiresAt = new Date(jsonPayload.exp * 1000).toLocaleString();

        // Get current timestamp
        const now = Math.floor(Date.now() / 1000);

        // Check if the token is expired
        if (jsonPayload.exp < now) {
            return { status: false, message: "Token expired" };
        }

        return { status: true, issuedAt, expiresAt };
    } catch (error) {
        console.error("Invalid token", error);
        return { status: false, message: "Invalid token" };
    }
  };

  useEffect(() => {
    if(token){
      const res = extractTokenData(token);
      if(res?.status === false){
        setLinkExpError('Link expired.Please contact admin.');
      }
    }
  },[token]);
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className={Styles.setPasswrd}>
      <div className={Styles.setPassContainer}>
        <div className={Styles?.logo}>
          <Logo />
        </div>
        <div className={`${Styles.setPassBox}`}>
          <div className={`${Styles.setpass__header}`}>Set Password</div>
          <div className={`${Styles.setpass__body}`}>
            {error ? (
              <div className={`${Styles.setpass__error}`}>{error}</div>
            ) : (
              ''
            )}
            {linkExperror ? (
              <div className={`${Styles.setpass__error}`}>{linkExperror}</div>
            ) : (
              ''
            )}
            {!linkExperror ?
            <form className={`${Styles.setpass__form}`} onSubmit={handleSubmit}>
              <div className={`${Styles.setpass__inputControl}`}>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.setpass__label}`}>email id</div>
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter email id"
                    className={`${Styles.setpass__input}`}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.setpass__label}`}>New Password</div>
                  <input
                    type="password"
                    value={password}
                    placeholder="Enter password"
                    className={`${Styles.setpass__input}`}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.setpass__label}`}>Confirm Password</div>
                  <input
                    type="password"
                    value={confirmPassword}
                    placeholder="Enter Confirm password"
                    className={`${Styles.setpass__input}`}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={`${Styles.setpass__submitControl}`}>
                <input type="submit" value="Submit" />
              </div>
            </form>
            : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;
