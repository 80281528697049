/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import swal from "sweetalert";
import Select from "react-select";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { BiSearch } from "react-icons/bi";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import getAllMachineList from "../../../API/getAllMachineList.js";
import getMaintenanceType from "../../../API/getMaintenanceType.js";
import getComponentsList from "../../../API/getComponentsList.js";
import postMaintenanceLog from "../../../API/postMaintenanceLog.js";
import getMaintenanceHistory from "../../../API/getMaintenanceHistory.js";

import "./styles.css";

const MaintenanceLog = ({
  IsMaintenance,
  toggleMaintenance,
  inputData,
  setInputData,
  logTitle,
  setLogTitle,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "850px",
    height: "95vh",
    overflow: "auto",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "200px",
    overflow: "auto",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };
  const customSelect = {
    placeholder: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    control: (props, state) => ({
      ...props,
      width: "100%",
      border: "none",
      boxShadow: state.isFocused && "none",
      backgroundColor: "#1f1f1f",
      fontSize: "12px",
      outline: "none",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    singleValue: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    input: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    menu: (props) => ({
      ...props,
      backgroundColor: "#1f1f1f",
    }),
    option: (props, state) => ({
      ...props,
      backgroundColor: state.isFocused ? "#5D97F6" : "",
      color: state.isFocused ? "#121212" : "#e0e0e0",
      fontSize: "12px",
    }),
  };

  // const [maintenanceHistory, setmaintenanceHistory] = useState([]);
  const [logMachine, setLogMachine] = useState([]);
  const [MaintenanceType, setMaintenanceType] = useState([]);
  const [Components, setComponents] = useState([]);
  const [input, setInput] = useState(inputData);
  const getMachineList = async () => {
    const response = await getAllMachineList(input?.plant?.PlantKey);
    setLogMachine(response.data?.data);
  };
  const getMaintenanceList = async () => {
    const response = await getMaintenanceType();
    setMaintenanceType(response.data?.data);
  };

  const getComponents = async () => {
    const response = await getComponentsList();
    setComponents(response.data?.data);
  };

  // const callGetMaintenanceHistory = async () => {
  //   const response = await getMaintenanceHistory(input?.machine?.MachineKey);
  //   setmaintenanceHistory(response?.data?.data);
  // };

  useEffect(() => {
    setInput(inputData);
  }, [inputData]);

  useEffect(() => {
    getMachineList();
    getMaintenanceList();
    getComponents();
    // callGetMaintenanceHistory();
  }, []);

  const [LogData, setLogData] = useState([]);

  useEffect(() => {
    Components.map((item) => {
      setLogData((d) => [
        ...d.filter((o) => o.MMComponentKey !== item.MMComponentKey),
        {
          ComponentName: item.ComponentName,
          MMComponentKey: item.MMComponentKey,
          ActionTaken: "",
          ActionTakenTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          checkbox: false,
        },
      ]);
    });
  }, [Components]);

  const handleCheckBox = (e) => {
    let temp = [...LogData];
    temp[Number(e.target.name) - 1].checkbox =
      !LogData[Number(e.target.name) - 1]?.checkbox;
    temp[Number(e.target.name) - 1].ActionTaken = "Repair";
    setLogData(temp);
  };

  const handleTime = (e, index) => {
    let date = moment(e.toDate()).format("YYYY-MM-DD HH:mm:ss");
    let temp = [...LogData];
    temp[index - 1].ActionTakenTime = String(date);
    setLogData(temp);
  };

  const handleRadio = (e, index) => {
    let temp = [...LogData];
    temp[Number(e.target.name) - 1].ActionTaken = e.target.value;
    setLogData(temp);
  };

  const [SendLogData, setSendLogData] = useState([]);
  const [MainTypeKey, setMainTypeKey] = useState([]);
  const [Remark, setRemark] = useState("");

  useEffect(() => {
    if (MaintenanceType.length > 0) {
      setMainTypeKey(MaintenanceType[0]);
    }
  }, [MaintenanceType]);

  useEffect(() => {
    let temp = structuredClone(LogData);
    temp = temp.filter((o) => o.checkbox !== false);
    for (let i = 0; i < temp.length; i++) {
      delete temp[i].checkbox;
      delete temp[i].ComponentName;
    }
    const _machineKey = input?.machine?.MachineKey;
    const _mainTypeKey = MainTypeKey?.MainTypeKey;
    setSendLogData({
      MachineKey: _machineKey,
      MainTypeKey: _mainTypeKey,
      Remark: Remark,
      Components: temp,
    });

    return () => {
      temp = [];
    };
  }, [LogData, MainTypeKey, input, Remark]);

  const [FilteredComponents, setFilteredComponents] = useState([]);
  const handleSearch = (el) => {
    const value = el.target.value.toLocaleLowerCase();
    const filter = LogData.filter((o) => {
      if (o.ComponentName.toLocaleLowerCase().includes(value)) {
        return o;
      }
    });
    setFilteredComponents(filter);
  };

  const [TableData, setTableData] = useState([]);
  useEffect(() => {
    if (FilteredComponents.length === 0) {
      setTableData(LogData);
    } else setTableData(FilteredComponents);
  }, [FilteredComponents, LogData]);

  const submitLog = async () => {
    if (SendLogData.Components.length < 1 && SendLogData.Remark.length === 0) {
      swal(
        "Failed to sumbut",
        "Please select component and fill the remark",
        "error"
      );
    } else if (
      SendLogData.Components.length < 1 &&
      SendLogData.Remark.length != 0
    ) {
      swal("Failed to sumbut", "Please select component", "error");
    } else if (
      SendLogData.Components.length > 0 &&
      SendLogData.Remark.length === 0
    ) {
      swal("Failed to sumbut", "Please fill the remark", "error");
    } else {
      const response = await postMaintenanceLog(SendLogData);
      if (response?.status === 200) {
        swal(response?.data?.data, "", "success");
        getComponents();
        setRemark("");
        toggleMaintenance();
      } else {
        swal("Failed to sumbut", "Please try later", "error");
      }
    }
  };

  const closeModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setRemark("");
      setLogTitle("");
      getComponents();
      toggleMaintenance();
      setFilteredComponents([]);
    }
  };

  const [toggleConfirmation, settoggleConfirmation] = useState(false);
  const AskConfirmation = () => {
    settoggleConfirmation(true);
  };

  const handleAlert = (e, click) => {
    if (click != "backdropClick") {
      settoggleConfirmation(false);
    }
  };

  return (
    <>
      <Modal
        open={IsMaintenance}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "0.2px solid #444444",
              padding: "5px 20px",
              backgroundColor: "#565b60",
            }}
          >
            <h4
              style={{
                fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                marginTop: "0px",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            >
              Maintenance Log{" "}
              {logTitle && <span style={{ color: "red" }}>( {logTitle}) </span>}
            </h4>
            <CloseIcon onClick={closeModal} size={15} />
          </div>
          <div className="dropdown__group">
            <div className="dropdown__container">
              <p style={{ marginBottom: "10px", fontWeight: 600 }}>Machine</p>
              <div
                style={{
                  border: "1px solid #3E3E40",
                  borderRadius: "4px",
                  width: "100%",
                  height: "40px",
                }}
              >
                <Select
                  isSearchable={true}
                  styles={customSelect}
                  className="selct"
                  placeholder="Select Machine"
                  options={logMachine}
                  getOptionValue={(x) => x.MachineKey}
                  getOptionLabel={(x) => x.MachineSerialNo}
                  onChange={(e) => {
                    setInput({ ...input, machine: e });
                    setLogTitle("");
                  }}
                  value={input.machine}
                  isDisabled={logTitle != "" ? true : false}
                />
              </div>
            </div>
            <div className="dropdown__container">
              <p style={{ marginBottom: "10px", fontWeight: 600 }}>
                Change - Routinely/Breakdown
              </p>
              <div
                style={{
                  border: "1px solid #3E3E40",
                  borderRadius: "4px",
                  width: "100%",
                }}
              >
                <Select
                  isSearchable={true}
                  styles={customSelect}
                  className="select"
                  placeholder="Select Change Type"
                  options={MaintenanceType}
                  getOptionValue={(x) => x.MainTypeKey}
                  getOptionLabel={(x) => x.Type}
                  value={MainTypeKey}
                  onChange={(e) => {
                    setMainTypeKey(e);
                  }}
                  isDisabled={logTitle != "" ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="Maintenance__tableContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p style={{ fontWeight: 600 }}>Maintenance Components</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  background: "#393939",
                  borderRadius: 4,
                  paddingLeft: 10,
                  width: "250px",
                }}
              >
                <BiSearch size={25} />
                <input
                  type="text"
                  style={{
                    width: "250px",
                    padding: "10px",
                    borderRadius: "4px",
                    height: "38px",
                    float: "right",
                    background: "#393939",
                    color: "white",
                    border: 0,
                  }}
                  placeholder="Search machine components..."
                  onChange={(el) => {
                    handleSearch(el);
                  }}
                />
              </div>
            </div>
            <form>
              <TableContainer className="tableContainer">
                <Table
                  sx={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
                  stickyheader={"true"}
                  aria-label="sticky table"
                >
                  <TableBody>
                    {TableData?.map((item, index) => {
                      return (
                        <TableRow key={item.MMComponentKey}>
                          <TableCell
                            width={50}
                            sx={{ padding: "0px 15px" }}
                            align="center"
                            className="table-data"
                          >
                            <Checkbox
                              color="primary"
                              checked={item.checkbox}
                              onChange={(e) => handleCheckBox(e, index)}
                              name={String(item.MMComponentKey)}
                            />
                          </TableCell>
                          <TableCell
                            width={250}
                            sx={{ padding: "0px 15px" }}
                            align="center"
                            className="table-data"
                          >
                            {item.ComponentName}
                          </TableCell>
                          <TableCell
                            width={230}
                            sx={{ padding: "0px 15px" }}
                            align="center"
                            className="table-data"
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                value={dayjs(item.ActionTakenTime)}
                                inputFormat="DD-MM-YYYY HH:mm"
                                onChange={(e) => {
                                  handleTime(dayjs(e), item.MMComponentKey);
                                }}
                                disableFuture={true}
                                format="yyyy-MM-dd HH:mm:ss"
                                renderInput={(params) => (
                                  <TextField
                                    sx={{
                                      svg: { color: "#fff" },
                                      input: { color: "#fff" },
                                    }}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0px 15px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                            align="center"
                            className="table-data"
                          >
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={String(item.MMComponentKey)}
                              style={{ display: "flex", flexDirection: "row" }}
                              onChange={(e) => handleRadio(e, index)}
                              defaultValue={"Repair"}
                            >
                              <FormControlLabel
                                value="Repair"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#fff",
                                      width: 60,
                                    }}
                                  />
                                }
                                label="Repair"
                              />
                              <FormControlLabel
                                value="Replace"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#fff",
                                      width: 60,
                                    }}
                                  />
                                }
                                label="Replace"
                              />
                            </RadioGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
            <div style={{ margin: "10px 0px 10px 0px", fontWeight: 600 }}>
              <p>Remarks</p>
            </div>
            <textarea
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                height: "70px",
                background: "#393939",
                color: "white",
                border: 0,
                resize: "none",
              }}
              value={Remark}
              onChange={(e) => setRemark(e.target.value)}
            ></textarea>
            <div className="Maintenance_buttonGroup">
              <Button
                style={{
                  backgroundColor: "#666666",
                  fontFamily: "Montserrat, Helvetica, sans-serif",
                  fontSize: "13px",
                  color: "white",
                  border: "none",
                  marginRight: "10px",
                }}
                variant="outlined"
                onClick={closeModal}
              >
                Cancel{" "}
              </Button>
              <Button
                style={{
                  backgroundColor: "#5D97F6",
                  fontFamily: "Montserrat, Helvetica, sans-serif",
                  fontSize: "13px",
                  color: "Black",
                }}
                variant="contained"
                onClick={AskConfirmation}
              >
                Save{" "}
              </Button>
            </div>
            {/* <hr style={{ borderTop: " 1px solid #444" }} />
            <div style={{ margin: "-10px 0px 10px 0px", fontWeight: 600 }}>
              <p>Maintenance History</p>
            </div>
            <TableContainer
              className="tableContainer"
              style={{ marginBottom: 0 }}
            >
              <Table
                sx={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
                stickyheader={"true"}
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow className="tableHeader">
                    <TableCell
                      align="center"
                      width={100}
                      style={{ whiteSpace: "break-spaces" }}
                      className="head"
                    >
                      Machine
                    </TableCell>
                    <TableCell align="center" width={300} className="head">
                      Component
                    </TableCell>
                    <TableCell align="center" width={100} className="head">
                      Change Type
                    </TableCell>
                    <TableCell align="center" width={130} className="head">
                      Maintenance Type
                    </TableCell>
                    <TableCell align="center" className="head">
                      Timestamp
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenanceHistory?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{ padding: "0px 15px" }}
                          align="center"
                          className="table-data"
                        >
                          {item.MachineName}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0px 15px" }}
                          align="center"
                          className="table-data"
                        >
                          {item.ComponentName}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0px 15px" }}
                          align="center"
                          className="table-data"
                        >
                          {item.MaintenanceType}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0px 15px" }}
                          align="center"
                          className="table-data"
                        >
                          {item.ActionTaken}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0px 15px" }}
                          align="center"
                          className="table-data"
                        >
                          {item.ActionTakenTime}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer> */}
          </div>
        </Box>
      </Modal>
      <Modal
        open={toggleConfirmation}
        onClose={handleAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "0.2px solid #444444",
              padding: "5px 20px",
              backgroundColor: "#565b60",
            }}
          >
            <h4
              style={{
                fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                marginTop: "0px",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            >
              Alert
            </h4>
            <CloseIcon onClick={() => settoggleConfirmation(false)} size={15} />
          </div>
          <p style={{ textAlign: "center", margin: "30px 0" }}>
            Are you want to save the Maintenance Log?
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => settoggleConfirmation(false)}
              variant={"outlined"}
            >
              No
            </Button>
            <Button
              onClick={() => {
                settoggleConfirmation(false);
                submitLog();
              }}
              variant={"contained"}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MaintenanceLog;
