/* eslint-disable */
import React from "react";

import "../../../../styles/failureMode.css";

const Input = ({
  errors,
  touched,
  handleChange,
  values,
  title,
  name,
  inputType,
}) => (
  <input
    name={name}
    className={`form-control customForm input-md ${
      errors.DTagName && touched.DTagName && "error-field"
    }`}
    id="code"
    placeholder={`Enter ${title}`}
    type={inputType}
    value={values[name]}
    onChange={handleChange}
  />
);

export default Input;
