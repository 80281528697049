import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoCheckmarkCircle } from 'react-icons/io5';
// import './PasswordResetSuccess.css'; // make sure to create this CSS file
import './ResetStyles.css';
import Logo from '../Login/images/Logo';

const PasswordResetSuccess = () => {
  const history = useHistory();
  const stateValue = history?.location?.state?.state;
  const handleLogin = () => {
    // Implementation for login redirection
    history.push('/login');
  };

  return (
    <div className="passwordResetSuccess">
      <div className="passresetContainer">
        <div className="linkExLogo">
          <Logo />
        </div>
        {(stateValue && stateValue === 'forgetPassword')
          ? (
            <div className="card">
              <IoCheckmarkCircle className="icon" size="100" color="#4b974b" />
              <h6>Link has been sent to your mail.Please check</h6>
              {/* <p>Please login again</p> */}
            </div>
          )
          : (
            <div className="card">
              <IoCheckmarkCircle className="icon" size="100" color="#4b974b" />
              { (stateValue && stateValue === 'setPasswordSuccess')
                ? <h1>Password set successfully!</h1>
                : <h1>Password changed successfully!</h1>}
              {/* <p>Please login again</p> */}
              <button type="button" className="loginButton" onClick={handleLogin}>
                Login now
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
