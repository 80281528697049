/* eslint-disable */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Select, { components } from "react-select";
import { message } from 'antd';
import { Modal, Box, Button } from "@mui/material";
import { Form } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { TbMathFunction } from "react-icons/tb";
import { toast } from "react-toastify";
import * as Yup from 'yup';  // Ensure Yup is installed

import getMathOperator from "../../../API/CentralizedCapsuleApis/getMathOperator.js";
import getLockType from "../../../API/CentralizedCapsuleApis/getLockType.js";
import getWeightType from "../../../API/CentralizedCapsuleApis/getWeightType.js";
import getParameters from "../../../API/CentralizedCapsuleApis/getParameters.js";
import editSizeconfiguration from "../../../API/CentralizedCapsuleApis/editSizeConfiguration.js";

import { batchSelectBox, updatesStyle, customEditSizeSelect, customSelect } from "./style";

import styles from "./styles.module.css";
import { propTypes } from "react-bootstrap/esm/Image";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <TbMathFunction size={20} />
    </components.DropdownIndicator>
  );
};



const EditModal = ({
  setRefresh,
  refresh,
  ViewScreen,
  openModalStatus,
  ModalHeader,
  setOpenModalStatus,
  editedData,
  ProdType,
  MCTypeKey
}) => {
  console.log('edit model', editedData);

  const [OperatorList, setOperatorList] = useState([]);
  const [LockTypeList, setLockType] = useState([]);
  const [WeightTypeList, setWeightType] = useState([]);
  const [InitialValue, setInitialValue] = useState([]);
  const [IsError, setIsError] = useState(true)



  const callGetMathOperator = useCallback(async () => {
    const res = await getMathOperator();
    setOperatorList(res);
  }, []);

  const callGetWeightType = useCallback(async () => {
    const res = await getWeightType(MCTypeKey);
    setWeightType(res);
  }, [MCTypeKey]);
  const callGetLockType = useCallback(async () => {
    const res = await getLockType(MCTypeKey);
    setLockType(res);
  }, [MCTypeKey]);

  const callGetParameters = useCallback(async () => {
    const res = await getParameters(ProdType, MCTypeKey);
    if (res.length != 0) {
      let temp = {
        Size: null,
        BodyECNo: null,
        CapECNo: null,
        WeightTypeKey: null,
        LockTypeKey: null,
        JSONDATA: null
      }
      let jsonData = []
      res?.map((item) => {
        jsonData.push({
          parameter_name: item?.ParamName,
          parameter_id: item?.ParamKey,
          part_type: item?.PartType,
          target_value: null,
          spec_operator: null,
          spec_value: null,
          control_chart_operator: null,
          control_chart_value: null,
        })
      });
      temp.JSONDATA = jsonData;
      // setInitialValue(temp);

    } else {
      message.error("Please Conact to Admin");
    }

  }, [propTypes, MCTypeKey]);

  const getChangedValues = (initialValues, currentValues) => {
    const changedItems = (currentValues || []).reduce((acc, curr, index) => {
      const original = initialValues[index];
      if (!original || Object.keys(curr).some(key => curr[key] !== original[key])) {
        acc.push(curr);  // Push the entire current item if it's changed or not in the initial array
      }
      return acc;
    }, []);

    return changedItems;
  };

  const callEditSizeConfiuration = async (value) => {
    let filteredValues = getChangedValues(InitialValue?.JSONDATA, value?.JSONDATA);
    console.log("filteredValues", filteredValues);
    const res = await editSizeconfiguration(filteredValues);
    if (res?.data?.data?.Status === "Error") {
      message.error(res?.data?.data?.Message);
    } else {
      setRefresh(!refresh);
      message.success(res?.data?.data?.Message);
      handleCloseModal();
    }
  }
  const validate = (values) => {
    console.log('validate-values', values);
    // if ((values?.BodyECNo && /^[0-9]{3}\/[0-9]{3}$/.test(values.BodyECNo)) || values?.BodyECNo?.length <= 3) {
    //   values = { ...values, BodyECNo: values?.BodyECNo.replace(" ", "") }
    // } else {
    //   values = { ...values, BodyECNo: "" }
    // }
    // if ((values?.CapECNo && /^[0-9]{3}\/[0-9]{3}$/.test(values.CapECNo)) || values?.BodyECNo?.length <= 3) {
    //   values = { ...values, CapECNo: values?.CapECNo.replace(" ", "") }
    // } else {
    //   values = { ...values, CapsuleECNo: "" }
    // }
    // if (values?.Size && values?.Size.length <= 4) {
    //   values = { ...values, Size: values?.Size.replace(" ", "") }
    // } else {
    //   values = { ...values, Size: "" }
    // }
    let errors = {};
    let hasErrors = false;
    // Validate Size
    if (!values.Size) {
      errors.Size = 'Size is required';
      hasErrors = true;
    }

    // Validate BodyECNo
    if (!values.BodyECNo) {
      errors.BodyECNo = 'Body EC Number is required';
      hasErrors = true;
    }

    // Validate CapECNo
    if (!values.CapECNo) {
      errors.CapECNo = 'Cap EC Number is required';
      hasErrors = true;
    }

    // Validate WeightTypeKey
    if (!values.WeightTypeKey) {
      errors.WeightTypeKey = 'Weight Type is required';
      hasErrors = true;
    }

    // Validate LockTypeKey
    if (!values.LockTypeKey) {
      errors.LockTypeKey = 'Lock Type is required';
      hasErrors = true;
    }

    // Validate JSONDATA
    values.JSONDATA.forEach((item, index) => {
      let jsonErrors = {};
      // console.log(item,'item')
      if (!item.Targetvalue) {
        jsonErrors.Targetvalue = 'Target value is required';
        hasErrors = true;
      }
      if (!item.OperatorspecKey) {
        jsonErrors.OperatorspecKey = 'Specification operator is required';
        hasErrors = true;
      }
      if (!item.SpecificationLimit) {
        jsonErrors.SpecificationLimit = 'Specification value is required';
        hasErrors = true;
      }
      if (!item.OperatorcontrolKey) {
        jsonErrors.OperatorcontrolKey = 'Control chart operator is required';
        hasErrors = true;
      }
      if (!item.ControlLimit) {
        jsonErrors.ControlLimit = 'Control chart value is required';
        hasErrors = true;
      }
      if (!item.OperatoravgKey) {
        jsonErrors.OperatoravgKey = 'Average Specification operator is required';
        hasErrors = true;
      }
      if (!item.AverageLimit) {
        jsonErrors.ControlLimit = 'Average Specification  value is required';
        hasErrors = true;
      }

      if (Object.keys(jsonErrors).length) {
        errors.JSONDATA = errors.JSONDATA || [];
        errors.JSONDATA[index] = jsonErrors;
      }
    });
    console.log('haserror', hasErrors)
    if (hasErrors) {
      errors.common = 'Please correct the errors below before submitting.';
      setIsError(hasErrors)
    } else { setIsError(hasErrors) }

    return errors;
  };
  // Close handler function
  const handleCloseModal = () => {
    setOpenModalStatus(false); // This will set the modal's visibility to false
  };
  const handleSubmit = (values) => {
    callEditSizeConfiuration(values);
  };
  useEffect(() => {
    if (openModalStatus) {
      callGetParameters();
      callGetMathOperator();
      callGetWeightType();
      callGetLockType();
    }
  }, [openModalStatus, callGetParameters, callGetMathOperator, callGetWeightType, callGetLockType]);

  useEffect(() => {
    if (editedData && Object.keys(editedData)) {
      setInitialValue(editedData)
    }
  }, [editedData]);
  console.log('InitialValue', InitialValue);
  return (
    <Modal
      open={openModalStatus}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={updatesStyle}>
        <div
          style={{
            fontFamily: "Montserrat, Helvetica, sans-serif",
            fontSize: "14px",
            fontWeight: 600,
            padding: "18px",
            borderBottom: "1px solid #383838",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0 }}>{ModalHeader}</p>
        </div>
        {(InitialValue && InitialValue?.JSONDATA && InitialValue?.JSONDATA.length > 0) &&
          <Formik
            initialValues={InitialValue}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnChange={true}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue,
              isValid,
              dirty }) => {
              return (
                <FormikForm style={{ marginLeft: "20px" }}>
                  <CloseIcon
                    className="modal-cross-icon"
                    sx={{
                      position: "absolute",
                      right: "15px",
                      fontSize: "28px",
                      top: "10px",
                      cursor: "pointer",
                    }}

                    onClick={() => {
                      setInitialValue({
                        Size: "",
                        SizeMapKey: null,
                        BodyECNo: null,
                        CapECNo: null,
                        LockTypeKey: null,
                        WeightTypeKey: null,
                        JSONDATA: []
                      });
                      setTimeout(() => {
                        handleCloseModal(); // Close popup AFTER resetting values
                      }, 100); // Small delay ensures proper UI update
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      marginRight: "10px",
                      position: "sticky",
                      zIndex: "99999",
                      alignItems: "end",
                    }}
                  >
                    <div
                      style={{ width: Text ? "82%" : "76.5%", display: "flex" }}
                    >
                      <Form.Group controlId="CapsuleSize">
                        <label className={styles.label}>Size </label>
                        <Form.Control
                          type="text"
                          name="Size"
                          disabled
                          // disabled={CapsuleSize?.capsuleecno && true}
                          placeholder="Enter Size"
                          value={values?.Size || ""}
                          onChange={handleChange}
                          autoComplete="off"
                          style={{
                            backgroundColor: "#404040",
                            width: "200px",
                            height: "40px",
                            border: "none",
                            marginRight: "10px",
                            color: "#fff",
                            fontFamily: "Montserrat, Helvetica, sans-serif",
                            fontSize: "12px",
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="CapsuleSize">
                        <label className={styles.label}>Weight Type </label>
                        <div>
                          <Select
                            styles={customEditSizeSelect}
                            isDisabled
                            options={WeightTypeList}
                            getOptionValue={(option) => option.WTKey}
                            getOptionLabel={(option) => option.WeightType}
                            value={WeightTypeList.find(
                              (option) =>
                                option.WTKey ===
                                values?.WeightTypeKey
                            )}
                            onChange={({ WTKey }) =>
                              setFieldValue('WeightTypeKey', WTKey)
                            }
                            placeholder="Select..."
                            name="WeightType"
                            className="basic-single-select"
                            classNamePrefix="select"
                            isClearable={false}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group controlId="CapsuleSize" style={{ marginRight: "10px" }}>
                        <label className={styles.label}>Lock Type </label>
                        <div>
                          <Select
                            styles={customEditSizeSelect}
                            isDisabled
                            options={LockTypeList}
                            getOptionValue={(option) => option.LTKey}
                            getOptionLabel={(option) => option.LockType}
                            value={LockTypeList.find(
                              (option) =>
                                option.LTKey ===
                                values?.LockTypeKey
                            )}
                            onChange={({ LTKey }) =>
                              setFieldValue('LockTypeKey', LTKey)
                            }
                            placeholder="Select..."
                            name="LockType"
                            className="basic-single-select"
                            classNamePrefix="select"
                            // styles={batchSelectBox}
                            isClearable={false}
                          />
                        </div>
                      </Form.Group>
                      <div style={{ display: "flex", justifyContent: "space-between", position: "relative", gap: "30px" }}>
                        <label style={{ position: 'absolute', bottom: "100%", left: "30%" }}>EC Number</label>
                        <Form.Group controlId="CapsuleSAPSize">
                          <label className={styles.label}>Cap</label>
                          <Form.Control
                            type="text"
                            name="CapECNo"
                            placeholder="Enter Cap ECNo"
                            value={values?.CapECNo || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled
                            style={{
                              backgroundColor: "#404040",
                              width: "100px",
                              marginRight: "10px",
                              height: "40px",
                              border: "none",
                              color: "#fff",
                              fontFamily: "Montserrat, Helvetica, sans-serif",
                              fontSize: "12px",
                            }}
                          />
                        </Form.Group>
                        <div style={{ position: "absolute", top: "28%", left: "45%", fontSize: "3rem" }}>/</div>
                        <Form.Group controlId="CapsuleSAPSize">
                          <label className={styles.label}>Body</label>
                          <Form.Control
                            type="text"
                            name="BodyECNo"
                            disabled
                            placeholder="Enter Body ECNo"
                            value={values?.BodyECNo || ""}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              backgroundColor: "#404040",
                              width: "100px",
                              marginRight: "10px",
                              height: "40px",
                              border: "none",
                              color: "#fff",
                              fontFamily: "Montserrat, Helvetica, sans-serif",
                              fontSize: "12px",
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBox}>
                    <table className={styles.table}>
                      <thead className={styles.thead}>
                        <tr className={styles.tr}>
                          <th className={styles.th}>Parameters (Parts)</th>
                          <th className={styles.th}>Target</th>
                          <th className={`${styles.th} ${styles.thbr}`} width={"120px"}>
                            Operator{" "}
                          </th>
                          <th className={styles.th}>Specification Limit</th>
                          <th className={styles.th} width={"120px"}>
                            Operator
                          </th>
                          <th className={styles.th}>Control Limit</th>
                          <th className={styles.th} width={"120px"}>
                            Operator
                          </th>
                          <th className={styles.th}>Average Specification Limit</th>
                        </tr>
                      </thead>

                      <tbody className={styles.tbody}>
                        {(InitialValue?.JSONDATA || []).map((item, index) => (

                          <tr className={styles.tr} key={index}>

                            <td className={styles.ParamName}>
                              <div>
                                {item?.ParamName} ({item.PartType}){" "}
                              </div>
                            </td>

                            <td className={styles.td}>
                              <div>
                                <Form.Control
                                  type="text"
                                  disabled={ViewScreen}
                                  // name={`target_value_${item?.parameter_id}`}
                                  name={`JSONDATA[${index}].Targetvalue`}
                                  placeholder="Enter target value"
                                  value={values?.JSONDATA[index]?.Targetvalue || ''}
                                  // value={
                                  //   values?.[
                                  //   `target_value_${item?.parameter_id}`
                                  //   ] || ""
                                  // }
                                  onChange={handleChange}
                                  onKeyDown={(e) => {
                                    if (
                                      !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }

                                  }}
                                  autoComplete="off"
                                  style={{
                                    backgroundColor: "#252525",
                                    width: "calc(100% - 10px)",
                                    display: "flex",
                                    marginLeft: "5px",
                                    height: "40px",
                                    border: "none",
                                    color: "#fff",
                                    fontFamily:
                                      "Montserrat, Helvetica, sans-serif",
                                    fontSize: "12px",
                                  }}
                                />
                              </div>
                            </td>
                            <td className={styles.td}>
                              <Select
                                isSearchable={false}
                                name={`JSONDATA[${index}].OperatorspecKey`}
                                components={{ DropdownIndicator }}
                                styles={customSelect}
                                options={OperatorList}
                                getOptionValue={(x) => x.id}
                                getOptionLabel={(x) => x.operator_name}
                                // value={OperatorList.find(
                                //   (option) =>
                                //     option.id ===
                                //     values?.JSONDATA[index]?.[`OperatorspecKey`]
                                // )}
                                value={
                                  OperatorList?.find(
                                    (option) =>
                                      option.id === values?.JSONDATA?.[index]?.OperatorspecKey
                                  ) ?? {}
                                }
                                onChange={(selectedOption) =>
                                  setFieldValue(`JSONDATA[${index}].OperatorspecKey`, selectedOption.id)
                                }
                                // onChange={({ id }) =>
                                //   setFieldValue(
                                //     `spec_operator_${item?.parameter_id}`,
                                //     id
                                //   )
                                // }
                                isDisabled={ViewScreen}
                              />
                            </td>
                            <td className={styles.td}>
                              <Form.Control
                                type="text"
                                // name={`spec_value_${item?.parameter_id}`}
                                name={`JSONDATA[${index}].SpecificationLimit`}
                                placeholder="Enter specification value"
                                value={values?.JSONDATA[index]?.SpecificationLimit || ''}
                                // value={
                                //   values?.[`spec_value_${item?.parameter_id}`] ||
                                //   ""
                                // }
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                autoComplete="off"
                                style={{
                                  backgroundColor: "#252525",
                                  width: "calc(100% - 10px)",
                                  display: "flex",
                                  marginLeft: "5px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  fontFamily: "Montserrat, Helvetica, sans-serif",
                                  fontSize: "12px",
                                }}
                                disabled={ViewScreen}
                              />
                            </td>
                            <td className={styles.td}>
                              <div className={styles.input}>
                                <Select
                                  isSearchable={false}
                                  name={`JSONDATA[${index}].OperatorcontrolKey`}
                                  components={{ DropdownIndicator }}
                                  styles={customSelect}
                                  options={OperatorList}
                                  getOptionValue={(x) => x.id}
                                  getOptionLabel={(x) => x.operator_name}
                                  // value={OperatorList.find(
                                  //   (option) =>
                                  //     option.id ===
                                  //     values?.JSONDATA[index]?.[`OperatorcontrolKey`]
                                  // )}
                                  value={
                                    OperatorList?.find(
                                      (option) =>
                                        option.id === values?.JSONDATA?.[index]?.OperatorcontrolKey
                                    ) ?? {}
                                  }
                                  onChange={(selectedOption) =>
                                    setFieldValue(`JSONDATA[${index}].OperatorcontrolKey`, selectedOption.id)
                                  }
                                  isDisabled={ViewScreen}
                                />
                              </div>
                            </td>
                            <td className={styles.td}>
                              <Form.Control
                                type="text"
                                // name={`control_chart_value_${item?.parameter_id}`}
                                name={`JSONDATA[${index}].ControlLimit`}
                                placeholder="Enter control chart value"
                                value={values?.JSONDATA[index]?.ControlLimit || ''}
                                // value={
                                //   values?.[
                                //   `control_chart_value_${item?.parameter_id}`
                                //   ] || ""
                                // }
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }

                                }}
                                autoComplete="off"
                                style={{
                                  backgroundColor: "#252525",
                                  width: "calc(100% - 10px)",
                                  display: "flex",
                                  marginLeft: "5px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  fontFamily: "Montserrat, Helvetica, sans-serif",
                                  fontSize: "12px",
                                }}
                                disabled={ViewScreen}
                              />
                            </td>
                            <td className={styles.td}>
                              <div className={styles.input}>
                                <Select
                                  isSearchable={false}
                                  name={`JSONDATA[${index}].avgSpecificationcontrolKey`}
                                  components={{ DropdownIndicator }}
                                  styles={customSelect}
                                  options={OperatorList}
                                  getOptionValue={(x) => x.id}
                                  getOptionLabel={(x) => x.operator_name}
                                  value={OperatorList.find(
                                    (option) =>
                                      option.id ===
                                      values?.JSONDATA?.[index]?.OperatoravgKey
                                  )}

                                  onChange={(selectedOption) =>
                                    setFieldValue(`JSONDATA[${index}].OperatoravgKey`, selectedOption.id)
                                  }
                                  isDisabled={ViewScreen}
                                />
                              </div>
                            </td>
                            <td className={styles.td}>
                              <Form.Control
                                type="text"
                                // name={`control_chart_value_${item?.parameter_id}`}
                                name={`JSONDATA[${index}].AverageLimit`}
                                placeholder="Enter Average Specification Limit value"
                                value={values?.JSONDATA[index]?.AverageLimit || ''}
                                // value={
                                //   values?.[
                                //   `control_chart_value_${item?.parameter_id}`
                                //   ] || ""
                                // }
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/^\d*\.?\d*$/.test(e.target.value + e.key) &&
                                    e.key !== "Backspace"
                                  ) {
                                    e.preventDefault();
                                  }

                                }}
                                autoComplete="off"
                                style={{
                                  backgroundColor: "#252525",
                                  width: "calc(100% - 10px)",
                                  display: "flex",
                                  marginLeft: "5px",
                                  height: "40px",
                                  border: "none",
                                  color: "#fff",
                                  fontFamily: "Montserrat, Helvetica, sans-serif",
                                  fontSize: "12px",
                                }}
                                disabled={ViewScreen}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {(!ViewScreen) && (
                      <Button
                        type="sumbit"
                        className={styles.btn}
                        variant="contained"
                        style={{
                          borderRadius: 4,
                          fontSize: "12px",
                          background: (IsError) ? "#4c4c4c" : null,
                          cursor: (IsError) ? "not-allowed" : "pointer",
                          fontWeight: 600,
                          width: "100%",
                          margin: "0"
                        }}
                        disabled={IsError}
                      // onClick={handleSubmit}
                      >
                        {IsError ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <span style={{ marginTop: 8, marginRight: 10 }}>
                              <HiOutlineInformationCircle
                                size={20}
                                color="#1976D2"
                              />
                            </span>
                            <span style={{ lineHeight: 3 }}>
                              All fields should be filled{" "}
                            </span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    )}
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        }
      </Box>
    </Modal>
  );
};

EditModal.propTypes = {
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  openModalStatus: PropTypes.bool,
  ModalHeader: PropTypes.string,
  setOpenModalStatus: PropTypes.func,
  editedData: propTypes.Object
};

export default EditModal;
