import React from 'react';
import PropTypes from 'prop-types';
/* eslint react/require-default-props:0 */
export const Menu = ({
  selectedTab, id, onClickFun, tabName, tabValue,
}) => {
  const data = selectedTab === tabValue ? 'active' : '';

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <li className={`pointers ${data}`}>
        {/* eslint-disable-next-line */}
        <a id={id} data-toggle="tab" onClick={(e) => onClickFun(e)}>
          {tabName}
        </a>
      </li>
    </>
  );
};

Menu.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClickFun: PropTypes.func,
  tabName: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
};
export default React.memo(Menu);
