// import React from "react";
import axios from 'axios';
import authHeader from '../../../config/auth.header';
import config from '../../../config/api.config.json';

export default function addhooks({
  setPlantsList, calenderFilter, plantFilter, setCalenderOptions, setYearOption, setPlantFilter,
  setYearFilter, setCalenderFilter, setWeekRanges,
}) {
  const getPlantList = () => {
    axios
      .get(`${config.GET_PLANTS_LIST}?userKey=${localStorage.getItem('UserKey')}&customerKey=${-1}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          const plantList = response?.data?.data;
          const plant = [];
          if (plantList && plantList.length) {
            plantList.forEach((ele) => plant.push({ label: ele.plant_id, value: ele.plantKey }));
            // const filterDataIndex = plantList.findIndex((item) => item?.plant_id && item?.plant_id.toLowerCase() === 'pithampur');
            // setPlantFilter(filterDataIndex ? plant[filterDataIndex] : plant[0]);
            setPlantsList(plant);
            setPlantFilter((plant && plant.length) ? plant[0] : []);
            // setPlantFilter((plant && plant.length) ? plant[6] : []);
          } else {
            setPlantFilter([]);
            setPlantsList([]);
          }
        } else {
          setPlantFilter([]);
          setPlantsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setPlantsList([]);
        setPlantFilter([]);
      });
  };
  const getMachineList = () => {
    const data = { calenderFilter, plantFilter };
    axios
      .post(config.GET_MACHINE_LIST, data, { headers: authHeader() })
      .then((response) => {
        const plantList = response.data.message;

        const plant = [];
        plantList.forEach((ele, index) => plant.push({ label: ele.plant_id, value: index }));
        // setPlantsList(plant);
      })
      .catch((error) => {
        console.log(error);
        // setPlantsList([]);
      });
  };

  const getFinancialYear = (FYear) => {
    axios
      .get(`${config.GET_RDS_FINANCIAL_YEAR_MONTH}?FYear=${FYear}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          const years = (response?.data?.years || []).map((item) => ({ label: `FY-${item.toString().substr(-2)}`, value: item }));
          setYearOption(years);
          setYearFilter((years && years.length) ? years[0] : []);
        } else {
          setYearOption([]);
          setYearFilter([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setYearOption([]);
        setCalenderOptions([]);
      });
  };

  const getFinancialYearMonths = (FYear) => {
    axios
      .get(`${config.GET_RDS_FINANCIALYEAR_MONTHS}?FYear=${FYear}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          const calenderOptions = response.data.data;
          const list = (calenderOptions || []).map((item) => ({ label: item?.MonthName, value: item?.Month }));
          setCalenderOptions(list);
          setCalenderFilter(list[0]);
        } else {
          setCalenderOptions([]);
          setCalenderFilter([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setYearOption([]);
        setCalenderOptions([]);
      });
  };
  const getWeekRanges = async (Month, Year) => {
    await axios
      .get(
        config.GET_WEEK_RANGES,
        {
          params: {
            month: Month,
            year: Year,
          },
          headers: authHeader(),
        },
      )
      .then(async (response) => {
        if (response?.data?.data && response?.data?.data.length) {
          setWeekRanges(response?.data?.data);
          // await getPlantList(response?.data?.data);
        }
        // console.log('response', response);
      }).catch((err) => {
        console.log('err', err);
      });
  };

  return {
    getPlantList,
    getMachineList,
    getFinancialYear,
    getFinancialYearMonths,
    getWeekRanges,
  };
}
