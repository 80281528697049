/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdViewDay, MdEdit } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import getSizeConfig from '../../../API/CentralizedCapsuleApis/getSizeConfig';
import getParameters from '../../../API/CentralizedCapsuleApis/getParameters';
import getSizeConfigbyKey from '../../../API/CentralizedCapsuleApis/getSizeConfigbyKey';
import EditModal from '../Modal/EditModal';
import styles from './styles.module.css';
import './swal.css';

function TableData({ ProdType, MCTypeKey, AddModalStatus }) {
  // console.log(MCTypeKey)
  const [data, setData] = useState([]);

  const [uniqueparameters, setUniqueParameters] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [sizeData, setSizeData] = useState([]);
  const [sizeKey, setSizeKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewScreen, setViewScreen] = useState(true);

  const callGetParameters = useCallback(async () => {
    setLoading(true);

    const res = await getParameters(ProdType, MCTypeKey);

    if (res && res.length > 0) {
      const data = res;
      const uniqueElement = new Set(data.map((item) => item.ParamName));
      setUniqueParameters(Array.from(uniqueElement));
    } else {
      toast.error('Something went wrong please try after sometime.');
    }
    setLoading(false);
  }, [MCTypeKey]);

  const callGetSizeConfigByKey = async (sizeKey, actionType) => {
    if (actionType === 'view') {
      setViewScreen(true);
    } else {
      setViewScreen(false);
    }
    if (sizeKey) {
      setSizeKey(sizeKey);
      const res = await getSizeConfigbyKey(sizeKey);
      if (res?.data && res?.data?.status === 1) {
        setSizeData(res?.data?.data);
        setOpenModalStatus(true);
      } else if (res?.data && res?.data?.status === 0) {
        toast.error(res?.data?.data?.Message);
      } else {
        toast.error('Something went wrong please try after sometime.');
      }
    }
  };

  const callGetSizes = useCallback(async () => {
    setLoading(true);
    const res = await getSizeConfig(ProdType, MCTypeKey);
    if (res?.data && res?.data?.status === 1) {
      setData(res?.data?.data);
    } else if (res?.data && res?.data?.status === 0) {
      toast.error(res?.data?.data?.Message);
    } else {
      toast.error('Something went wrong please try after sometime.');
    }
    setLoading(false);
  }, [MCTypeKey]);
  /* eslint-disable */
  // useEffect(() => { callGetSizeConfigByKey() }, [sizeKey])
  useEffect(() => { callGetParameters(); }, [MCTypeKey]);
  useEffect(() => { callGetSizes(); }, [uniqueparameters, openModalStatus, AddModalStatus])

  return (
    <div>
      <div className={styles.headerBox}>
        <div>Size</div>
        <div>Weight Type</div>
        <div>Lock Type</div>
        <div>Part</div>
        <div>ECNo</div>
        {(uniqueparameters || []).map((e, i) => (<div key={i}>{e}</div>))}
        <div>Actions</div>
      </div>

      <div className={styles.body}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress size={20} />
          </div>
        ) : (
          <>

            {
              (data && data.length > 0) ?
                data.map((item, index) => {
                  return (
                    <>
                      {(item?.Sizes || []).map((item1, index1) => (

                        <div key={item1?.Size} className={styles.accordianBox} style={(item?.Sizes.length > 1 && item?.Sizes[index1]?.Size == item?.Sizes[index1 - 1]?.Size) ? { marginTop: '0px' } : { marginTop: '10px' }}>
                          <div className={styles.boxs}>{item1?.Size}</div>
                          <div className={styles.boxs}>{item1?.WeightType}</div>
                          <div className={styles.boxs}>{item1?.LockType}</div>
                          <div className={styles.boxs}>
                            <div>CAP</div>
                            <div>BODY</div>
                          </div>
                          <div className={styles.boxs}>
                            <div>{item1.ECNo.Cap}</div>
                            <div>{item1.ECNo.Body}</div>
                          </div>
                          {(uniqueparameters || []).map((e, i) => (


                            item1.CAP[e] || item1.BODY[e] ?
                              <div className={styles.boxs}>
                                <div>{item1.CAP[e]}</div>
                                <div>{item1.BODY[e]}</div>
                              </div>
                              :
                              <div className={styles.boxs}>{item1.CAPS && item1.CAPS[e] ? item1.CAPS[e] : 'N/A'}</div>



                          ))}
                          <div className={styles.boxs}>
                            <div>
                              <div>
                                <MdEdit
                                  size={23}
                                  data-tooltip-id="Capsule"
                                  data-tooltip-content="Click here to edit"
                                  onClick={() => callGetSizeConfigByKey(item1.SizeMapKey, 'edit')}
                                />
                              </div>
                              <MdViewDay
                                size={23}
                                data-tooltip-id="Capsule"
                                data-tooltip-content="Click here to view"
                                onClick={() => callGetSizeConfigByKey(item1.SizeMapKey, 'view')}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )
                })


                : null
            }
          </>
        )}
      </div>

      {/* <ReactTooltip id="Capsule" type="dark" effect="solid" /> */}
      {openModalStatus ?
        <EditModal
          setRefresh={setRefresh}
          ViewScreen={viewScreen}
          refresh={refresh}
          openModalStatus={openModalStatus}
          ModalHeader={viewScreen ? "View Version " : "Edit Version"}
          setOpenModalStatus={setOpenModalStatus}
          editedData={sizeData}
          sizeKey={sizeKey}
          ProdType={ProdType}
          MCTypeKey={MCTypeKey}
        />
        : null
      }
    </div>
  );
}
TableData.propTypes = {
  MCTypeKey: PropTypes.number,
  ProdType: PropTypes.string,
  AddModalStatus: PropTypes.bool,
}
export default TableData;
