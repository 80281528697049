import { useState, useEffect } from 'react';
// import Logo from './images/Logo';
import { useParams, useHistory } from 'react-router-dom';
import Logo from '../Login/images/Logo';
import Styles from './forgotPassword.module.css';
/* eslint no-shadow:0 */
import {
  PASSWORD_RULES,
  PLEASE_ENTER_NEWPASSWORD,
  PLEASE_ENTER_CONFIRMPASSWORD,
  NEWPASSWORD_CONFIRM_PASSWORD_SAME,
} from '../../helpers/constants';
import AppRoutes from '../../config/appicationRoutes.config.json';

import userManagementService from '../../services/userManagement.service';
import { extractTokenData } from '../../helpers/util';

const { forgotPasswordApi } = userManagementService;
function ForgotPassword() {
  const { id: token } = useParams();
  const { RESET_SUCCESS } = AppRoutes;
  const initialPAssword = {
    newPassword: '',
    confirmPassword: '',
  };
  const [password, setPassword] = useState(initialPAssword);
  const [, setHasPasswordChanged] = useState('');
  const [error, setError] = useState('');
  const [linkExperror, setLinkExpError] = useState('');

  const { id } = useParams();
  const history = useHistory();
  const handleSetPassword = (e) => {
    const { passwordStatus } = e.target.dataset;
    setPassword({
      ...password,
      [passwordStatus]: e.target.value,
    });
  };
  const handleReset = () => {
    setPassword(initialPAssword);
    setError('');
    setHasPasswordChanged('');
  };
  /* eslint-disable */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#\^(){}|\[\]])[A-Za-z\d@$!%*?&#\^(){}|\[\]]{8,}$/;
    const { newPassword, confirmPassword } = password;
    if (newPassword === '') {
      setError(PLEASE_ENTER_NEWPASSWORD);
      setHasPasswordChanged('');
    } else if (!passwordRegex.test(newPassword)) {
      setError(PASSWORD_RULES);
    } else if (confirmPassword === '') {
      setError(PLEASE_ENTER_CONFIRMPASSWORD);
      setHasPasswordChanged('');
    } else if (newPassword !== confirmPassword) {
      setError(NEWPASSWORD_CONFIRM_PASSWORD_SAME);
      setHasPasswordChanged('');
    } else {
      setError('');
      setHasPasswordChanged('');
      const res = await forgotPasswordApi({ password, id });
      if (res?.data?.status === 1) {
        setHasPasswordChanged(res?.data?.data);
        setPassword(initialPAssword);
        history.push(RESET_SUCCESS);
      } else {
        setError(res?.data?.data);
      }
    }
  };

  useEffect(() => {
    if(token){
      const res = extractTokenData(token);
      if(res?.status === false){
        setLinkExpError('Link expired.Please try again.');
      }
    }
  },[token]);

  return (
    <div className={Styles.Login}>
      <div className={Styles.loginContainer}>
        <div className={Styles.logo}>
          <Logo />
        </div>
        <div className={`${Styles.loginBox}`}>
          <div className={`${Styles.login__header}`}>Forgot Password</div>
          <div className={`${Styles.login__body}`}>
            {error ? (
              <div className={`${Styles.login__error}`}>{error}</div>
            ) : (
              ''
            )}
            {linkExperror ? (
              <div className={`${Styles.login__error}`}>{linkExperror}</div>
            ) : (
              ''
            )}
            { !linkExperror ? 
              <form className={`${Styles.login__form}`} action="" onReset={handleReset} onSubmit={handleSubmit}>
                <div className={`${Styles.login__inputControl}`}>
                  <div className={`${Styles.login__fieldGroup}`}>
                    <div className={`${Styles.login__label}`}>New Password</div>
                    <input
                      className={`${Styles.login__input}`}
                      placeholder="Enter New Password"
                      type="password"
                      value={password.newPassword}
                      onChange={handleSetPassword}
                      data-password-status="newPassword"
                      id="newPassword"
                    />
                  </div>
                  <div className={`${Styles.login__fieldGroup}`}>
                    <div className={`${Styles.login__label}`}>Confirm Password</div>
                    <input
                      className={`${Styles.login__input}`}
                      placeholder="Enter Confirm Password"
                      type="password"
                      value={password.confirmPassword}
                      onChange={handleSetPassword}
                      data-password-status="confirmPassword"
                      id="confirmPassword"
                    />
                  </div>
                </div>
                <div className={`${Styles.login__submitControl}`}>
                  <input type="submit" value="Submit" />
                </div>
              </form>
              : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
