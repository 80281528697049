import React, {
  useState, useEffect, useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { Checkbox } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import CircularIndeterminate from '../../../common/CircularIndeterminate';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';
// import CircularIndeterminate from "../../../../components/dashboard/common/CircularIndeterminate";
import { LanguageContext } from '../../../LanguageContext';
// import PaginationTab from "../../../../components/dashboard/common/PaginationTab";
// import SchedulingModal from '../settings/SchedulingModal';
import { getGeneralConditionsAsync, saveActiveStatusAsync, AddGeneralConditionViewStatus } from '../../../redux/slices/generalConditionViewSlice';
// import { ReactComponent as Edit } from "../../../../../src/components/dashboard/reports/ECR/svgs/editSpoc.svg";
import Icon from '../../Dashboard/Sidebar/images/Icon';
// import settings from '../../../images/settings.svg';

const GCDataTable = () => {
  const [page, setPage] = useState(1);
  const defaultCardCount = 15;
  const size = defaultCardCount;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  const [search, setSearch] = useState('');
  // const [settingsOpen, setSettingsOpen] = useState(false);

  const [sortName, setSortName] = useState('');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [activeStatus, setActiveStatus] = useState(true);
  console.log('check');
  useEffect(() => {
    dispatch(getGeneralConditionsAsync({
      page, size, search, sortName, sortDirection, activeStatus,
    }));
  }, [page, size, search, sortName, sortDirection, activeStatus]);

  const count = useSelector(
    (state) => state?.generalConditionsView?.generalConditions?.count || 0,
  );
  const listData = useSelector(
    (state) => state?.generalConditionsView?.generalConditions?.rows || [],
  );

  const isLoading = useSelector(
    (state) => state.generalConditionsView.status,
  ) === 'loading';
  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onSearchChange = debounce((e) => {
    setPage(1);
    setSearch(e.target.value);
  }, 2000);
  // const onSearchKeyUp = (e) => {
  //   if (e.key == "Enter") {
  //     setPage(1);
  //     dispatch(getGeneralConditionsAsync({ page: 1, size, search, sortName, sortDirection }));
  //   }
  // };

  const onSortHeaderClick = (fieldName) => {
    setPage(1);
    setSortName(fieldName);
    if (sortDirection === 'ASC') {
      setSortDirection('DESC');
    } else if (sortDirection === 'DESC') {
      setSortDirection('ASC');
    }
    // if (sortDirection === '') {
    //   setSortDirection('ASC');
    // } else if (sortDirection === 'ASC') {
    //   setSortDirection('DESC');
    // } else {
    //   setSortDirection('');
    // }
  };

  const onActiveClick = (id, status) => {
    dispatch(saveActiveStatusAsync({ id, status }));
    dispatch(getGeneralConditionsAsync({
      page, size, search, sortName, sortDirection, activeStatus,
    }));
  };

  // for language change
  const [Lang] = useContext(LanguageContext);
  // const Lang = 'English';
  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };
  /* eslint react/button-has-type:0 */
  return (
    <>
      {/* <div className="alarmBar padTopOnly row dropRow"> */}
      <div className="alarmBar padTopOnly cmRow dropRow">
        {/* <button className="pull-right btn btn-outline icon-btn" onClick={() => setSettingsOpen(true)}>
          <img src={settings} alt="settings" title="settings" />
        </button> */}
        <div className="input-group pull-right search-container">
          <svg className="searchIconGlobalSvg" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="searchP1" fillRule="evenodd" clipRule="evenodd" d="M6.125 2.82751C4.192 2.82751 2.625 4.39452 2.625 6.32751C2.625 8.26051 4.192 9.82751 6.125 9.82751C8.058 9.82751 9.625 8.26051 9.625 6.32751C9.625 4.39452 8.058 2.82751 6.125 2.82751ZM1.625 6.32751C1.625 3.84223 3.63972 1.82751 6.125 1.82751C8.61028 1.82751 10.625 3.84223 10.625 6.32751C10.625 8.8128 8.61028 10.8275 6.125 10.8275C3.63972 10.8275 1.625 8.8128 1.625 6.32751Z" fill="#b7b7b7" />
            <path className="searchP2" fillRule="evenodd" clipRule="evenodd" d="M8.5964 8.79896C8.79166 8.6037 9.10824 8.6037 9.3035 8.79896L11.4785 10.974C11.6738 11.1692 11.6738 11.4858 11.4785 11.6811C11.2832 11.8763 10.9667 11.8763 10.7714 11.6811L8.5964 9.50607C8.40114 9.3108 8.40114 8.99422 8.5964 8.79896Z" fill="#b7b7b7" />
          </svg>
          <input
            type="search"
            className="form-control py-2 customForm input-md search-box"
            placeholder="Search by Condition Name, Machine Model and Machine Section"
            onChange={(e) => onSearchChange(e.nativeEvent)}
          />
        </div>
        <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={() => { dispatch(AddGeneralConditionViewStatus(false)); history.push('/ConditionMasters/General-Conditions/Add-General-Condition'); }}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99951 0.666504V11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.666504 6H11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

          Add Entry
        </button>
      </div>
      <div className="alertDivsBg hover-row gd-tabledata">
        <div className="alertDivs">
          <div className="headerTable">
            <span className="row0 hide">&nbsp;</span>
            <span className="row1 hide">&nbsp;</span>
            <span
              className="row2 tbl-head"
              onClick={() => onSortHeaderClick('Description')}
            >
              {Lang.ConditionName}
              {sortName === 'Description' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
              {sortName === 'Description' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
            </span>
            <span
              className="row3 tbl-head"
              onClick={() => onSortHeaderClick('MachineModel_Name')}
            >
              {Lang.MachineModel}
              {sortName === 'MachineModel_Name' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
              {sortName === 'MachineModel_Name' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
            </span>
            <span
              className="row4 tbl-head"
              onClick={() => onSortHeaderClick('MachineSection_Name')}
            >
              {Lang.MachineSection}
              {sortName === 'MachineSection_Name' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
              {sortName === 'MachineSection_Name' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
            </span>
            <span
              className="row5 activeParent"
            >
              <div>{activeStatus ? 'Active' : 'InActive'}</div>
              {/* {sortName === 'IsActive' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place srt-icon" />}
              {sortName === 'IsActive' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place srt-icon" />} */}
              <div className="activeCheck">
                <Checkbox
                  checked={activeStatus}
                  onChange={() => setActiveStatus(!activeStatus)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="secondary"
                />
              </div>
            </span>
            <span className="row6">{Lang.Action}</span>
            <span className="row7">&nbsp;</span>
          </div>
          {!isLoading && listData && listData.length > 0 && (
            <div
              className="pannelWrap overflowScrolls common-scroll"
              id="pannelWrap"
            >
              <div className="bs-example">
                <div className="panel-group" id="accordion1">
                  {
                    listData.map((item) => (
                      <div className="panel panel-default alarmRows" key={item.GeneralCondition_ID}>
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <div onClick={() => history.push(`/ConditionMasters/General-Conditions/View-General-Condition/${item.GeneralCondition_ID}`)}>
                              <span className="hide" />
                              <span className="row1 hide">&nbsp;</span>
                              <span className="row2">
                                {item.Description}
                                {' '}
                                GC
                                _
                                {item.GeneralCondition_ID}
                              </span>
                              <span className="row3">{item.MachineModel_Name}</span>
                              <span className="row4">{item.MachineSection_Name}</span>
                              <Tooltip
                                PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                arrow
                                title={(
                                  <div className="fia-desc">
                                    {
                                  item.IsActive === false
                                    ? <span>Click to activate</span>
                                    : <span>Click to Deactivate</span>
                                }

                                    <br />
                                  </div>
)}
                              >
                                <span
                                  className={`row5 ${item.IsActive ? 'active' : 'inactive'}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onActiveClick(item.GeneralCondition_ID, !item.IsActive);
                                  }}
                                >
                                  {item.IsActive ? 'Active' : 'Inactive'}
                                </span>
                              </Tooltip>
                              <span
                                className="row6 edit"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(AddGeneralConditionViewStatus(true));
                                  history.push(`/ConditionMasters/General-Conditions/Add-General-Condition/${item.GeneralCondition_ID}`);
                                }}
                              >
                                <Icon name="EditIcon" />
                              </span>
                              <span className="row7">&nbsp;</span>
                            </div>
                          </h4>
                        </div>
                      </div>
                    ))
}
                </div>
              </div>
            </div>
          )}

          {isLoading && (
            <div className="pannelWrap overflowScrolls" id="pannelWrap">
              <div
                className="loaderHistoryAlarms"
                style={{ marginTop: '100px' }}
              >
                <CircularIndeterminate />
              </div>
            </div>
          )}
          {!isLoading && (listData === undefined || listData.length === 0) && (
            <div className="noHistoryAlarms">{Lang.NoRecord}</div>
          )}
        </div>

        <div
          className={`
            alarm-pagination
            ${count <= defaultCardCount
            ? 'cardBelowNine'
            : 'float-container'
            }`}
        >
          <div className="panellefttext">
            <div className="shownotext">
              {` ${start} to ${end} of ${count} `}
            </div>
          </div>
          <div className="panelrighttext">
            <PaginationTab
              Previous={onPrevious}
              Next={onNext}
              page={page}
              maxPage={Math.ceil(count / size)}
            />
          </div>
        </div>
      </div>
      {/* {settingsOpen
        && <SchedulingModal showPopup={settingsOpen} setShowPopup={setSettingsOpen} />} */}
    </>
  );
};

export default GCDataTable;
